import { Market } from '../../apollo/autogenerate/schemas';

const marketKey = 'CACHED-MARKET';

export const getCachedMarket = (): Market | undefined => {
  const data = localStorage.getItem(marketKey);
  if (!data) return;
  return JSON.parse(data) as Market;
};

export const setCachedMarket = (market?: Market) =>
  market
    ? localStorage.setItem(marketKey, JSON.stringify(market))
    : localStorage.removeItem(marketKey);
