import { FC, useCallback, useState } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Typography,
  Alert,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useLocales from '../../../hooks/useLocales';
import 'yup-phone';
import { formatPhone } from '../../../utils/formatPhone';
import { useRequestTwoFactorAuthCodeMutation } from '../../../apollo/autogenerate/hooks';
import { SignUpInput } from '../../../apollo/autogenerate/schemas';
import { useErrorSnackbar } from '../../../hooks/useErrorSnackbar';

// ----------------------------------------------------------------------

const AuthRegisterForm: FC = () => {
  const { t } = useLocales();
  const { register } = useAuth();
  const isMountedRef = useIsMountedRef();
  const errorSnackbar = useErrorSnackbar();

  const [agree, setAgree] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCodeInstr, setShowCodeInstr] = useState(false);

  const [reqCode, { loading: loading2fa }] = useRequestTwoFactorAuthCodeMutation({
    // eslint-disable-next-line no-console
    onCompleted: (d) => setShowCodeInstr(true),
    onError: (err) => errorSnackbar(err),
  });

  const phoneRegExp = new RegExp(/[^\d+]/);

  const phoneValidation = Yup.string()
    .required(t('dashboard.errors.phone.required'))
    .test('valid', t('dashboard.errors.phone.valid'), (value) =>
      value ? !phoneRegExp.test(value) : false
    )
    .test('length', t('dashboard.errors.phone.valid'), (value) =>
      value ? (value[0] === '+' ? value.length === 12 : value.length === 11) : false
    )
    .phone('RU', true, t('dashboard.errors.phone.valid'));

  const RegisterSchema = Yup.object().shape({
    phoneNumber: phoneValidation,
    password: Yup.string().required(t('dashboard.errors.password')),
    firstName: Yup.string().required(t('dashboard.errors.name')),
    lastName: Yup.string().required(t('dashboard.errors.surname')),
    patronymic: Yup.string(),
    code: Yup.string().required(t('dashboard.errors.code')),
  });

  const defaultValues = {
    phoneNumber: '+7',
    password: '',
    firstName: '',
    lastName: '',
    patronymic: '',
    code: '',
  };

  const methods = useForm<
    SignUpInput & {
      afterSubmit?: string;
    }
  >({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = useCallback(
    async (data: SignUpInput) => {
      try {
        const { code, phoneNumber, firstName, lastName, password } = data;
        await register({
          phoneNumber: formatPhone(phoneNumber),
          code,
          firstName,
          lastName,
          password,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        reset();
        if (isMountedRef.current) {
          setError('afterSubmit', { ...error, message: error.message });
        }
      }
    },
    [isMountedRef, register, reset, setError]
  );

  const onRequestCode = useCallback(async () => {
    const isValid = await phoneValidation.isValid(methods.getValues('phoneNumber'));
    if (isValid) {
      clearErrors('phoneNumber');
      reqCode({ variables: { phone: methods.getValues('phoneNumber') } });
      return;
    }
    if (isMountedRef.current)
      setError('phoneNumber', { message: t('dashboard.errors.phone.valid') });
  }, [clearErrors, isMountedRef, methods, phoneValidation, reqCode, setError, t]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        <RHFTextField name="phoneNumber" label={t('general.phone')} />

        <RHFTextField
          name="password"
          label={t('general.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label={t('general.first')} />
          <RHFTextField name="lastName" label={t('general.last')} />
        </Stack>

        <RHFTextField name="patronymic" label={t('general.patronymic')} />

        <FormControlLabel
          control={<Checkbox checked={agree} onChange={(e) => setAgree(e.target.checked)} />}
          label={
            <Typography component="span">
              {t('auth.reg.agree1')}
              <Link target="_blank" rel="noopener noreferrer" href="/docs/user_agreement.pdf">
                {t('auth.reg.agree2')}
              </Link>
            </Typography>
          }
        />

        <LoadingButton
          variant="outlined"
          disabled={!agree}
          loading={loading2fa}
          onClick={onRequestCode}
        >
          {t('auth.reg.codeVerify')}
        </LoadingButton>
        {showCodeInstr && <Alert severity="info">{t('auth.reg.codeInstr')}</Alert>}
        <RHFTextField disabled={!agree} name="code" label={t('auth.reg.code')} />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!agree}
        >
          {t('auth.reg.register')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default AuthRegisterForm;
