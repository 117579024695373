import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NicheAnalysisFilter } from '../../apollo/autogenerate/schemas';
import { initialState } from './initialState';
import { PeriodDate, SetFilterAction, SetFilterPeriodAction, StoredFiltersValues } from './types';

export const nicheAnalysisFiltersSlice = createSlice({
  name: 'nicheAnalysisFilters',
  initialState,
  reducers: {
    setDateFrom: (state, action: PayloadAction<PeriodDate>) => {
      const date = action.payload;
      state.period.from = date;
    },
    setDateTo: (state, action: PayloadAction<PeriodDate>) => {
      const date = action.payload;
      state.period.to = date;
    },
    setFilterFrom: (state, action: PayloadAction<SetFilterAction>) => {
      const { filterName, value } = action.payload;
      state.filters.values[filterName].from = value;
    },
    setFilterTo: (state, action: PayloadAction<SetFilterAction>) => {
      const { filterName, value } = action.payload;
      state.filters.values[filterName].to = value;
    },
    setFilterPeriod: (state, action: PayloadAction<SetFilterPeriodAction>) => {
      const { filterName, value } = action.payload;
      const filtersValues = state.filters.values;
      if (Object.keys(filtersValues).includes(filterName))
        filtersValues[filterName as keyof StoredFiltersValues] = value;
    },
    setSavedFilters: (state, action: PayloadAction<NicheAnalysisFilter>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, id, marketId, name, ...filtersValues } = action.payload;

      const newValues: StoredFiltersValues = {
        revenueFromAllProduct: { from: undefined, to: undefined },
        numberOfProducts: { from: undefined, to: undefined },
        numberOfSellers: { from: undefined, to: undefined },
        productSaleProbability: { from: undefined, to: undefined },
        sellerWithSalesProbability: { from: undefined, to: undefined },
        numberOfProductsWithRevenueGreaterThanOneMillion: { from: undefined, to: undefined },
        purchaseProductsBudget: { from: undefined, to: undefined },
        selfPurchaseProductBudget: { from: undefined, to: undefined },
        averageRevenuePerOneProductWithSales: { from: undefined, to: undefined },
        averageCommentsAmount: { from: undefined, to: undefined },
        averageMedianCheck: { from: undefined, to: undefined },
        top3SellerAverageRevenue: { from: undefined, to: undefined },
        top10SellerAverageRevenue: { from: undefined, to: undefined },
        top30SellerAverageRevenue: { from: undefined, to: undefined },
        top3SellerAverageComments: { from: undefined, to: undefined },
        top10SellerAverageComments: { from: undefined, to: undefined },
        top30SellerAverageComments: { from: undefined, to: undefined },
      };

      Object.keys(filtersValues).forEach((filter) => {
        newValues[filter as keyof StoredFiltersValues].from =
          filtersValues[filter as keyof StoredFiltersValues]!.from ?? undefined;
        newValues[filter as keyof StoredFiltersValues].to =
          filtersValues[filter as keyof StoredFiltersValues]!.to ?? undefined;
      });

      state.filters.meta = { id, marketId, name };
      state.filters.values = newValues;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.period = initialState.period;
    },
    addChosenCategory: (state, action: PayloadAction<string>) => {
      state.chosenCategories.push(action.payload);
    },
    removeChosenCategory: (state, action: PayloadAction<string>) => {
      state.chosenCategories = state.chosenCategories.filter(
        (category) => category !== action.payload
      );
    },
  },
});

export const {
  setDateFrom,
  setDateTo,
  setFilterFrom,
  setFilterTo,
  setFilterPeriod,
  resetFilters,
  setSavedFilters,
  addChosenCategory,
  removeChosenCategory,
} = nicheAnalysisFiltersSlice.actions;
export const nicheAnalysisFiltersReducer = nicheAnalysisFiltersSlice.reducer;
