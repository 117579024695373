import { useEffect, useState } from 'react';
import { useAppSelector } from '../redux';

export const useRefetchOnMarketChanged = (refetch: () => void) => {
  const action = useAppSelector((s) => s.events.onMarketChanged);
  const [skip, setSkip] = useState(true);
  useEffect(() => {
    if (skip) {
      setSkip(false);
      return;
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);
};
