import { Helmet } from 'react-helmet-async';
import { forwardRef, PropsWithChildren, ReactNode } from 'react';
// @mui
import { Box, BoxProps, Container } from '@mui/material';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
}
const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title}${title ? ' | ' : ''}Bot Kompot`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));

export default Page;

export const SimplePage: React.FC<{ title: string } & PropsWithChildren> = ({
  title,
  children,
}) => {
  const { themeStretch } = useSettings();
  return (
    <Page title={title}>
      <Container maxWidth={themeStretch ? false : 'xl'}>{children}</Container>
    </Page>
  );
};
