import { AppLanguage } from './_lng';

export const ruLang: AppLanguage = {
  general: {
    amount: 'сумма',
    balance: 'баланс',
    privacyPolicy: 'Политика обработки персональных данных',
    serviceTerms: 'Пользовательское соглашение',
    password: 'Пароль',
    phone: 'Номер телефона',
    first: 'Имя',
    last: 'Фамилия',
    patronymic: 'Отчество',
    CUSTOMER: '',
    OPERATOR: 'Менеджер',
    SUPERADMIN: 'Администратор',
    noData: 'Нет данных',
    alert: 'Произошла ошибка. Попробуйте перезагрузить страницу',
    noMarket: 'Выберите магазин, чтобы увидеть список подписок',
  },
  page404: {
    title: 'Cтраница не найдена!',
    subtitle:
      'Приносим извинения, мы не смогли найти страницу, которую вы ищите. Возможно вы ошиблись с адресом страницы? Обязательно проверьте введенный URL-адрес',
    button: 'Домой',
  },
  settings: {
    tooltip: 'Настройки',
    title: 'Настройки',
    mode: 'Тема',
    contrast: 'Контраст',
    direction: 'Направление',
    layout: 'Вид',
    presets: 'Гамма',
    stretch: 'Растяжение',
    fullscreen: 'Полный экран',
    exitFullscreen: 'Оконный режим',
  },
  components: {
    exportCSV: 'Экспорт',
    rangePicker: {
      from: 'От',
      to: 'До',
    },
    toggleActive: {
      activate: 'Активировать',
      deactivate: 'Деактивировать',
    },
    statistics: {
      back: 'Назад',
      title: 'Статистика',
      statistic: 'Статистика',
      period: 'Период',
      filters: 'Все фильтры',
      filterNiche: 'Введите название ниши',
      brand: 'Бренды',
      search: 'Найти',
      devices: {
        label: 'Устройства',
        all: 'Все устройства',
        none: 'Не выбраны',
        IOS: 'IOS',
        Android: 'Android',
        Web: 'Веб',
        Unknown: 'Неизвестное',
      },
      export: 'Экспорт',
      perDayStats: {
        title: 'Статистика',
        views: 'Просмотры',
        uniqueUsers: 'Уникальные пользователи',
        clicks: 'Клики',
        orders: 'Заказы',
        sum: 'Сумма',
      },
      funnel: {
        title: 'Воронка',
        views: 'Просмотры',
        clicks: 'Клики',
        orders: 'Заказы',
      },
      generalStats: {
        title: 'Общая статистика',
        wbId: 'Идентификатор\u00A0WB',
        key: '#',
        views: 'Просмотры',
        frequency: 'Частота',
        clicks: 'Клики',
        ctr: 'CTR',
        cpc: 'CPC',
        sum: 'Сумма',
        allRows: 'Все',
        aggregation: 'Всего по кампании',
        emptyData: 'Данных по статистике кампании нет',
      },
      productsStats: {
        title: 'Предметы кампании',
        wbId: 'Идентификатор\u00A0WB',
        article: 'Артикул',
        views: 'Просмотры',
        frequency: 'Частота',
        clicks: 'Клики',
        ctr: 'CTR',
        cpc: 'CPC',
        cac: 'CAC',
        atbs: 'Добавления в корзину',
        orders: 'Заказы',
        sum: 'Сумма',
        aggregation: 'Всего',
        allRows: 'Все',
        emptyData: 'С выбранными фильтрами предметов не обнаружено',
      },
      metrics: {
        key: 'Метрика',
        value: 'Значение',
        views: 'Просмотры',
        atbs: 'Добавления в корзину',
        clicks: 'Клики',
        orders: 'Заказы',
        cr: 'CR',
        frequency: 'Частота',
        ctr: 'CTR',
        cpc: 'CPC',
        cac: 'CAC',
        sum: 'Сумма',
        uniqueUsers: 'Уникальные посетители',
      },
      bidPlaces: {
        title: 'История ставок',
        bid: 'Ставка',
        place: 'Место',
        allCampaigns: 'Все кампании',
        noCampaigns: 'Выберите кампании',
      },
      analyticsStats: {
        title: 'Дополнительные параметры статистики',
        noData: 'Для выбранных фильтров дополнительных параметров статистики нет',
        campaign: 'Кампания',
        wbId: 'ID\u00A0кампании',
        articles: 'Артикул\u00A0ВБ',
        date: 'Дата',
        views: 'Просмотры',
        clicks: 'Клики',
        ctrPercent: 'CTR(%)',
        cpc: 'CPC',
        advertBudget: 'Бюджет\u00A0рекламы,\u00A0руб',
        updateBudget: 'Бюджет\u00A0пополнения\u00A0РК,\u00A0руб',
        averageCheck: 'Ср.чек\u00A0товара',
        totalOrdersRub: 'Заказы\u00A0всего,\u00A0руб',
        totalOrders: 'Заказы\u00A0всего,\u00A0шт',
        advertOrdersRub: 'Заказы\u00A0по\u00A0рекламе,\u00A0руб',
        advertOrders: 'Заказы\u00A0по\u00A0рекламе,\u00A0шт',
        buyoutPercent: '%\u00A0выкупа',
        advertSalesRub: 'Продажи\u00A0по\u00A0рекламе,\u00A0руб',
        advertSales: 'Продажи\u00A0по\u00A0рекламе,\u00A0шт',
        crRub: 'CR,\u00A0руб',
        crPercent: 'CR,\u00A0%',
        acosRub: 'ACOS,\u00A0руб',
        acosPercent: 'ACOS\u00A0%',
        tacosRub: 'TACOS,\u00A0руб',
        tacosPercent: 'TACOS,\u00A0%',
        cacOrder: 'CAC\u00A0Заказ',
        cacSale: 'CAC\u00A0Продажа',
        romiOrder: 'ROMI\u00A0Заказ,\u00A0%',
        romiSale: 'ROMI\u00A0Продажа,\u00A0%',
        profitFromOneSKU: 'MPStats\u00A0Выручка,\u00A0руб.',
        wbOrdersRub: 'WB\u00A0заказы,\u00A0руб.',
        allRows: 'Все',
      },
    },
    loadingScreen: {
      loadingText1: 'готовим отчет ...',
      loadingText2: 'кладём сухофрукты ...',
      loadingText3: 'чертим таблицу ...',
      loadingText4: 'насыпаем сахар ...',
      loadingText5: 'ищем данные ...',
      loadingText6: 'кипятим компот ...',
      loadingText7: 'заполняем таблицу ...',
      loadingText8: 'перемешиваем ...',
      loadingText9: 'оформляем интерфейс ...',
      loadingText10: 'остужаем ...',
    },
  },
  landing: {
    menu: {
      about: 'О нас',
      auth: 'Аутентификация',
      contact: 'Связаться',
      faqs: 'Частые      вопросы',
      home: 'Главная',
      login: 'Войти',
      other: 'Прочее',
      pages: 'Навигация',
      reg: 'Регистрация',
      tryForFree: 'Попробовать бесплатно',
      logOut: 'Выйти',
      privateOffice: 'Личный кабинет',
      office: 'кабинет',
    },
    main: {
      header: {
        header: {
          row1: '100 ботов для',
          row2: 'автоматизации',
          row3: 'работы на',
          row4: 'Wildberries и Ozon',
        },
        descr: 'Самый большой гипермаркет ботов для маркетплейсов, работающих 24/7',
        start: 'Попробовать бесплатно',
      },
      bk: {
        opportunities: 'Возможности БотКомпот',
        try: 'Попробовать',
        h1: 'Автоматическое управление рекламой',
        h2: 'Автобронировние поставок',
        h3: 'Автоответчик на отзывы',
        h4: 'Автогенерация SEO карточки',
        h5: 'Нейросеть по подбору товарных ниш',
        h6: 'Автокалькулятор участия в акциях',
      },
      questions: {
        description1: 'У вас есть вопросы или предложения?',
        description2: 'Пожалуйста, свяжитесь с снами!',
        contact: 'Написать',
      },
      advertising: {
        heading: 'Попробуйте 3 дня бесплатно!',
      },
      video: {
        heading: 'Как увеличить продажи за счет автоматизации рекламы на WB?',
      },
      testimonials: {
        heading: 'Отзывы',
        subtitle: '',
      },
      pricing: {
        heading: 'Гибкие тарифы',
        start: 'Начать',
        day: '3 дня',
        month: 'месяц',
        free: 'Бесплатно',
        freeSubtitle:
          'Бот оценки ниш, бот реальных ставок, бот выгрузки отчетов, бот-отчет о продажах за день - 0₽',
        options: {
          op1: 'Просмотр реальных ставок',
          op2: 'Анализ популярных запросов',
          op3: 'Анализ эффективности рекламы',
          op4: 'Автопополнение бюджета рекламной кампании',
          op5: 'Ежедневный отчет по заказам и продажам',
        },
        plans: {
          test: {
            name: 'Пробный',
          },
          base: {
            name: 'Базовый',
          },
          extend: {
            name: 'Расширенный',
          },
          prof: {
            name: 'Профессиональный',
          },
        },
      },
      footer: {
        documents: 'Документы',
        agreement: 'Пользовательское соглашение',
        personalData: 'Политика обработки персональных данных',
        contacts: 'Контакты',
        companyInfo: 'ООО “Джи Юнит”, ИНН 9715418508',
        c2022: '© 2022. BotKompot',
      },
      faq: {
        heading: 'Часто задаваемые вопросы',
        form: {
          heading: 'Не нашли подходящий ответ?',
          name: 'Имя',
          email: 'Электронная почта',
          subject: 'Тема',
          massage: 'Введите текст',
          submit: 'Оправить',
        },
        q0: {
          question: 'Вопрос1',
          answer: 'Ответ на вопрос',
        },
        q1: {
          question: 'Вопрос2',
          answer: 'Ответ на вопрос',
        },
        q2: {
          question: 'Вопрос3',
          answer: 'Ответ на вопрос',
        },
        q3: {
          question: 'Вопрос4',
          answer: 'Ответ на вопрос',
        },
        q4: {
          question: 'Вопрос5',
          answer: 'Ответ на вопрос',
        },
        q5: {
          question: 'Вопрос6',
          answer: 'Ответ на вопрос',
        },
        q6: {
          question: 'Вопрос7',
          answer: 'Ответ на вопрос',
        },
        q7: {
          question: 'Вопрос8',
          answer: 'Ответ на вопрос',
        },
      },
    },
  },
  auth: {
    login: {
      back: 'С возвращением в ',
      details: 'Удачной работы',
      dont1: 'Нет аккаунта?',
      dont2: 'Зарегистрируйтесь',
      forgot: 'Забыли пароль?',
      login: 'Вход',
      remember: 'Запомнить',
      sign: 'Войти в систему',
    },
    reg: {
      left: 'Присоединяйтесь к',
      have1: 'Уже есть аккаунт?',
      have2: 'Войти',
      start1: 'Регистрация',
      start2: 'Добро пожаловать!',
      agree1: 'Я согласен с ',
      agree2: 'пользовательским соглашением ',
      getCode: 'Получить код',
      codeVerify: 'Подтвердить номер телефона',
      code: 'Введите последние 4 цифры входящего номера',
      register: 'Создать аккаунт',
      codeInstr: 'Введите последние 4 цифры, с номера, который вам позвонит',
    },
  },
  dashboard: {
    notifications: {
      notifications: 'Уведомления',
      newMsg: 'Количество  непрочитанных сообщений',
      newLabel: 'Новые сообщения',
      beforeLabel: 'История',
      markAllAsRead: 'Прочитать все',
      notificationType: {
        AutoDepositSuccessful: 'Баланс кампании пополнен',
        BidsUpdateSuccessful: 'Ставка кампании успешно изменена',
        AutoDepositFailed: 'Ошибка пополнения баланса кампании',
        BidsUpdateFailed: 'Ошибка в работе автобиддера по кампании',
      },
    },
    menu: {
      home: 'Главная',
      logout: 'Выйти',
      general: 'Общее',
      app: 'Информация',
      markets: 'Магазины',
      marketsList: 'Список',
      createMarket: 'Добавить новый',
      deposits: 'Баланс',
      createDeposit: 'Пополнение',
      toolsWB: 'Инструменты ВБ',
      wbFeedbacks: 'Автоответчик',
      wbBidder: 'Актуальные ставки',
      wbPopularRequests: 'Ставки на запросы',
      superAdmin: 'Администрирование',
      tools: 'Инструменты и подписки',
      advertising: 'Управление рекламой',
      nicheAnalysis: 'Анализ ниши',
      nicheAnalysisDeep: 'Выбор ниши',
      autobidder: 'Автобиддер ставок',
      unitEconomy: 'Юнит - экономика',
      feedbacksParser: 'Парсер отзывов',
    },
    errors: {
      phone: {
        required: 'Введите номер телефона',
        valid: 'Проверьте правильность введенного номера телефона',
      },
      password: 'Введите пароль',
      name: 'Укажите имя',
      surname: 'Укажите фамилию',
      code: 'Введите код',
    },
    marketBar: {
      markets: 'Ваши магазины',
      rub: 'руб.',
      settings: 'Управление',
      marketsSelect: {
        label: 'Выберите магазин',
        admin: 'Администратор',
        analyst: 'Аналитик',
        operator: 'Менеджер',
      },
    },
    loginToSee: 'Войдите в систему, чтобы увидеть эту страницу',
  },
  markets: {
    title: 'Магазины',
    createMarket: 'Добавить магазин',
    selectMarket: 'Выберите магазин!',
    createMarketWB: {
      title: 'Добавить магазин',
      asAdministrator: 'Администратор:',
      asOperator: 'Менеджер:',
      asAnalyst: 'Аналитик:',
    },
    marketWbForm: {
      name: 'Название',
      wbApiKey: 'Новый API',
      statsApiKey: 'API X64',
      xSupplierId: 'x-supplier-id',
      wildUpgradeToken: 'SellerToken',
      save: 'Сохранить',
      whyDisabled: 'ID поставщика не может быть указан без SellerToken',
      instruction: {
        see: 'Посмотреть',
        instruction: 'инструкцию',
      },
    },
    editMarketWB: {
      title: 'Редактировать магазин',
    },
    createRole: {
      title: 'Добавить пользователя',
      createPrompt: 'Введите номер  телефона +79091005533',
      addOperator: 'Менеджер',
      addAnalyst: 'Аналитик',
      addAdmin: 'Администратор',
    },
    manageMarkets: {
      title: 'Управление моими магазинами',
      marketRow: {
        addUser: '+ пользователь',
      },
    },
    subscribeBlock: {
      subscribed: 'Подписка оформлена',
      noMarket: 'Выберите магазин, чтобы увидеть список подписок',
      availableSubscriptions: {
        title: 'Доступные подписки',
        price: 'Цена',
        maxUseAmount: 'Максимальное количество использований',
        maxTime: 'Продолжительность подписки',
        subscribeLifetime: {
          month: '1 месяц',
          month3: '3 месяца',
          month6: '6 месяцев',
          week: 'неделя',
          year: 'год',
        },
      },
      activeSubscriptions: {
        title: 'Текущие подписки',
        subscrtiption: {
          title: 'Подписка',
          amountOfUses: 'Количество использований',
          endTime: {
            endConditionTime: 'Срок действия вашей подписки истекает',
            endDate: 'Дата окончания',
          },
        },
        subscribeLifetime: {
          month: 'в течение месяца',
          month3: 'в течение 3 месяцев',
          month6: 'в течение полугода',
          week: 'в течение недели',
          year: 'в течение года',
        },
      },
      subscribe: 'Подписаться',
      noSubscriptions: 'У вас нет подписок. Вначале подпишитесь',
    },
    marketDeposit: 'Пополнение',
  },
  createDeposit: {
    title: 'Пополнение',
    prompt: '1000',
    create: 'Пополнить',
  },
  tools: {
    wb: {
      feedbacks: {
        navigation: {
          main: 'Основное',
          denominations: 'Наименования',
          recommendations: 'Рекомендации',
        },
        title: 'Автоответ на отзывы',
        active: 'Активен',
        inactive: 'Неактивен',
        setUpFeedbacks: 'Настроить автоответ',
        tipInfo: {
          text1:
            'Сервис используется для автоматического формирования текста ответа на отзывы клиентов в зависимости от поставленных звезд. Для настройки разновидности ответов варианты перечисляются через ";". Например, Добрый день!; Здравствуйте!',
          text2: 'В тексте можно использовать следующие фразы:',
          text3: '$name - заменяется на имя клиента, оставившего отзыв',
          text4: '$articul - заменяется на артикул и название товара',
          text5:
            '$recommendation (использовать только в блоке Рекомендации) - заменяется на рекомендуемый товар',
          text6: 'Пример заполнения: Добрый день, $name!',
          text7: 'Пример ответа: Добрый день, Анна!',
          text8:
            'Блок "Рекомендации" используется только при наличии связи товар-рекомендация на вкладке "Рекомендации"',
        },
        stars: 'Ответ на отзывы с количеством звезд:',
        farewellPart: 'Прощание и пожелания',
        greetingsPart: 'Приветствие',
        mainPart: 'Основной текст',
        recommendationsPart: 'Рекомендации',
        thanksPart: 'Благодарность',
        farewellPartHolder: 'Ждем вам снова!',
        greetingsPartHolder: 'Добрый день!',
        mainPartHolder:
          'Наша команда старается сделать вашу жизнь комфортнее и интреснее. Сейчас мы работаем над увелчиением ассортимента.',
        recommendationsPartHolder:
          'Советуем вам попробовать нашу модель, которая уже успела зарекомендовать себя - [номер\\а артикула\\ов]',
        thanksPartHolder: 'Спасибо вам за отличный отзыв.',
        save: 'Сохранить',
        deletePhrase: 'Удалить ответ',
        historyLog: 'История ответов',
        productName: 'Название товара',
        feedbackAnswerLabel: 'Ответ на отзыв',
        feedbackAnswerTextLabel: 'Текст ответа',
        noAnswers: 'Ответов нет',
        search: 'Поиск',
        all: 'Все',
        feedbacksHistory: {
          date: 'Дата и время',
          name: 'Наименование',
          article: 'Артикул',
          answer: 'Текст отзыва',
        },
        denominations: {
          vendorCode: 'Артикул',
          name: 'Наименование',
          action: 'Действие',
        },
        recommendations: {
          title1: 'Создайте связи для рекомендаций:',
          title2: 'Готовые связи:',
          label1: 'Наименование (артикул)',
          label2: 'Укажите рекомендованные товары',
          create: 'Создать',
          remove: 'Удалить',
          selected: 'Выбрано',
        },
      },
      feedbacksParser: {
        title: 'Парсер отзывов',
        searchPlaceholder: 'Введите артикул товара...',
        find: 'Отобразить карточку',
        cardInfo: {
          rate: 'рейтинг',
          totalFeedbacks: 'Всего отзывов',
          totalFeedbacksWithPhoto: 'Отзывов с фото',
          photos: 'Фотографии покупателей',
        },
        analysis: {
          aim: {
            title: 'Цель',
            subtitle1:
              'Количество 5-звездочных отзывов, которое требуется для достижения рейтинга ',
            subtitle2:
              'Количество 1-звездочных отзывов, которое требуется для достижения рейтинга ',
            info: 'Передвиньте ползунок рейтинга, чтобы узнать, какое количество отзывов требуется для его достижения',
          },
          valuationDistribution: {
            title: 'Процентное соотношение звезд',
          },
          countryDistribution: {
            title: 'Отзывы из разных стран',
            RU: 'Россия',
            BY: 'Белоруссия',
            KZ: 'Казахстан',
            AM: 'Армения',
            KG: 'Киргизия',
            UZ: 'Узбекистан',
            US: 'США',
          },
        },
        cardFeedbacks: {
          title: 'Отзывы',
          table: {
            __typename: 'Парсер отзывов',
            answerText: 'Текст ответа',
            cardUrl: 'Карточка товара',
            color: 'Цвет',
            country: 'Страна',
            feedbackDate: 'Дата',
            feedbackText: 'Текст ответа',
            matchingPhoto: 'Соответствие фото',
            matchingSize: 'Соответствие размеру',
            pictureUrl: 'Фото товара',
            userName: 'Имя',
            valuation: 'Рейтинг',
            votesAgainst: 'Голоса против',
            votesFor: 'Голоса за',

            name: 'Имя',
            product: 'Товар',
            rating: 'Рейтинг',
            photo: 'Соответствие фото',
            size: 'Соответствие размеру',
            votes: 'Голоса за/против',
            date: 'Дата',
            feedbackAnswer: 'Текст отзыва',
            full: 'Полное',
            smaller: 'Маломерит',
            bigger: 'Большемерит',
            ok: 'Полное',
            undefined: 'Неизвестно',
            minimal: 'Частичное',
          },
        },
        cardQuestions: {
          title: 'Вопросы',
          table: {
            __typename: 'Вопросы',
            answerText: 'Ответ',
            country: 'Страна',
            questionDate: 'Дата',
            questionText: 'Вопрос',
            userName: 'Имя',
          },
        },
      },
      bidder: {
        title: 'Актуальные рекламные ставки',
        description: {
          part1:
            'С помощью этого инструмента можно узнать актуальные ставки по рекламе на Wildberries. Вы можете ввести',
          vendorCode: 'артикул',
          part2: 'для получения актуальных ставок рекламы карточки товара, можете ввести',
          keywords: 'ключевое слово или фразу',
          part3: 'для получения актуальных ставок рекламы в поиске, можете ввести',
          link: 'ссылку на категорию',
          part4: 'Wildberries для получения актуальных ставок рекламы в каталоге.',
        },
        region: {
          placeholder: 'Регион',
          moscow: 'Москва',
          yekaterinburg: 'Екатеринбург',
        },
        search: {
          placeholder: 'Введите артикул, ключевое слово или ссылку на категорию',
          button: 'Проверить',
        },
        table: {
          picture: 'Превью',
          bid: 'Ставка',
          sku: 'SKU',
          brand: 'Бренд',
          name: 'Название',
          price: 'Цена',
          deliveryTime: 'Время\u00A0доставки',
          category: 'Категория',
        },
      },
      popularRequests: {
        title: 'Ставки на популярные запросы',
        search: {
          placeholder: 'Введите ваш запрос',
          button: 'Найти',
        },
        table: {
          requestText: 'Запрос',
          monthlyFrequency: 'Месячная частотность',
          prioritizedCategory: 'Приоритетная категория',
          productAdvertBidPlace: 'место',
          productAdvertBid: {
            advertBid: 'Ставка',
            sku: 'SKU',
            categoryId: 'Категория (id)',
          },
        },
      },
      unitEconomy: {
        title: 'Калькулятор юнит-экономики',
        searchPlaceholder: 'Введите артикул товара...',
        find: 'Обработать',
        category: 'Категория: ',
        inputData: 'Входные данные',
        result: 'Результат',
        save: 'Сохранить',
        warehouse: {
          warehouse: 'Склад',
          chooseWarehouse: 'Выберите склад',
          width: 'Ширина, м',
          height: 'Высота, м',
          length: 'Длина, м',
          weight: 'Вес, кг',
        },
        priceBlock: {
          title: 'Рассчет цен',
          inputBlock: {
            purchasePrice: 'Закупочная цена, ₽',
            serviceFF: 'Услуги ФФ, ₽',
            targetMarkup: 'Целевая наценка, %',
            discountBeforeWB: 'Скидка от цены продажи до скидки ВБ, %',
            taxes: 'Налоги, %',
          },
          resultBlock: {
            marginRub: 'Наценка',
            marginFromPurchasePrice: 'Наценка к закупочной цене',
            wbFee: 'Комиссия ВБ',
            logistics: 'Логистика ВБ',
            storage: 'Хранение за 1 день',
            taxesRub: 'Налоги',
            ransomPercentage: 'Процент выкупа',
            marginForOneUnit: 'Маржа за 1 ед.',
            profitability: 'Рентабельность продаж',
            priceForWbPromotion: 'Цена для участия в акциях ВБ',
            targetSellingPriceWb: 'Целевая цена продажи ВБ',
            sellPriceWbWithoutDiscount: 'Цена продажи до скидок ВБ',
            sellPriceOzon: 'Целевая цена продажи Озон',
            sellPriceOzonWithoutDiscount: 'Цена продажи до скидок Озон',
          },
        },
        profitBlock: {
          title: 'Расчет прибыли',
          inputBlock: {
            purchaseSalesVolume: 'Закупка. Объем продаж, шт.',
            promotionBudget: 'Бюджет продвижения',
          },
          resultBlock: {
            purchase: 'Закупка',
            salesVolume: 'Объем продаж',
            margin: 'Маржа',
            profitToSales: 'Прибыль к продажам',
            profitToPurchase: 'Отношение прибыли к закупке',
            profit: 'Прибыль',
          },
        },
        purchaseBlock: {
          title: 'Расчет закупки',
          inputBlock: {
            desiredProfit: 'Желаемая прибыль, руб',
          },
          resultBlock: {
            salesAmount: 'Сумма продаж',
            salesNumber: 'Количество продаж',
            ProfitToSales: 'Прибыль к продажам',
            purchaseAmount: 'Сумма закупки',
            purchaseQuantity: 'Количество закупки',
          },
        },
      },
      advertising: {
        campaigns: {
          title: 'Автобиддер рекламных ставок',
          statistics: 'Статистика',
          warningStat: 'Необходимо выбрать кампании для формирования статистики',
          synchronize: {
            control: 'Синхронизировать',
            synchronized: 'Синхронизировано успешно!',
            loadingText: {
              part1: 'Синхронизация рекламных кампаний может занять',
              part2: 'до 3 минут',
            },
          },
          subscription: {
            active: 'Подписка активна',
            inactive: 'Подписка неактивна',
          },
          addCampaign: {
            add: 'Добавить',
            title: 'Добавление рекламной кампании',
            import: {
              title: 'Импорт кампании из ВБ',
              id: 'Идентификатор',
              import: 'Импортировать',
            },
            imported: 'Импортировано успешно!',
            create: 'Создать',
          },
          filters: {
            vendorCode: {
              name: 'ID/название кампании',
              tooBig: 'Слишком большое число для ID кампании',
              tooLow: 'ID кампании не может быть отрицательным',
            },
            statusWb: 'Статус ВБ',
            isActive: {
              label: 'Статус автобиддера',
              true: 'Активен',
              false: 'Неактивен',
            },
            type: 'Тип',
            inArchive: {
              label: 'В архиве',
              true: 'Да',
              false: 'Нет',
            },
          },
          table: {
            showStats: 'Показ статистики',
            id: '№',
            wbId: 'ID\u00A0кампании',
            wbStatus: 'статус\u00A0ВБ',
            isActive: 'статус автобиддера',
            name: 'название\u00A0кампании',
            products: 'артикулы',
            type: 'тип',
            budgetDailyMax: 'дневной\nлимит',
            cpm: 'CPM',
            actions: 'действия',
            active: 'Автобиддер активен',
            notActive: 'Автобиддер неактивен',
            campaign: 'Рекламный кабинет',
            archived: 'Разархивировать',
            unarchived: 'Архивировать',
            ctr: 'CTR',
            cpc: 'CPC',
            startDate: 'дата старта',
            averageBid: 'средняя\nставка',
            maxBid: 'макс. ставка',
            holdingPositions: 'позиция\nудержания',
            budgetTotal: 'бюджет',
            updated: 'Обновлено',
            editable: 'Это поле можно редактировать',
            nValues: {
              single: 'значение',
              several: 'значения',
              several2: 'значений',
            },
          },
          status: {
            ReadyToLaunch: 'готов к запуску',
            ShowingsCompleted: 'показы завершены',
            Refused: 'отказался',
            Active: 'активный',
            Suspended: 'приостановлено',
          },
          type: {
            Catalog: 'каталог',
            CarouselAuction: 'карточка',
            Search: 'поиск',
          },
          campaign: {
            title: 'Кампания',
            back: 'Назад',
            data: {
              title: 'Данные карточки',
              name: 'название',
              wbId: 'идентификатр ВБ',
              wbStatus: 'статус ВБ',
              type: 'тип',
              budgetTotal: 'бюджет',
              budgetDailyMax: 'дневной лимит',
              replenishBudget: 'пополнить бюджет',
              completed: 'Данные карточки сохранены',
              save: 'Сохранить',
            },
            autopay: {
              title: 'Автопополнение',
              limits: 'граница пополнения',
              amount: 'размер оплаты',
              save: 'Сохранить',
              completed: 'Автопополнение сохранено',
            },
            products: 'Артикулы',
            settings: {
              noPlacesError: 'Произошла ошибка, попробуйте перезагрузить страницу',
              title: 'Настройки',
              synchronization: 'Синхронизация',
              selectInterval: {
                title: 'Часы показа',
                addInterval: 'Добавить интервал',
                save: 'Сохранить',
                to: 'До',
                from: 'От',
              },
              carouselAuction: {
                kindName: 'группа',
                price: 'CPM',
                bidderPosition: 'позиция',
                bidderCpmMax: 'максимальная ставка',
                save: 'Сохранить',
                completed: 'Настройки обновлены',
              },
              search: {
                keyword: 'группа',
                price: 'CPM',
                bidderKeyword: 'ключевое слово',
                bidderPosition: 'позиция',
                bidderCpmMax: 'максимальная ставка',
                synchronize: {
                  sync: 'Синхронизировать',
                  words: 'минус-слова',
                },
                save: 'Сохранить',
                completed: 'Настройки обновлены',
              },
              catalog: {
                subjectName: 'группа',
                price: 'CPM',
                bidderPosition: 'позиция',
                bidderCpmMax: 'максимальная ставка',
                save: 'Сохранить',
                completed: 'Настройки обновлены',
              },
            },
            minusWords: {
              title: 'Поисковые запросы',
              description:
                'Для исключения нежелательных показов в поисковой выдаче Вы можете настроить «Минус фразы». Ваша реклама не будет показываться по запросам из «Минус фраз».',
              success: 'Поисковые запросы обновлены',
              save: 'Сохранить',
              keyWordsList: {
                title: 'Ключевые фразы',
                info: 'Список запросов, в которых участвовала Ваша рекламная кампания.',
                search: {
                  part1: 'Поиск среди',
                  part2: 'фраз',
                },
                toMinusWords: 'В минус-фразы',
                cancel: 'Отменить',
              },
              minusWordsList: {
                title: 'Минус-фразы',
                fromSearch: 'Из поиска',
                exactMatch: {
                  label: 'Точное соответствие',
                  info: 'Здесь будут отображаться введённые Вами фразы. Вы можете ввести, отредактировать и удалить фразы. Введите фразу с новой строки или через запятую, например "белые кроссовки". По такому запросу показов не будет. Но по запросу "белые кроссовки для бега" показы будут.',
                },
                wordsMatch: {
                  label: 'Фразовое соответствие',
                  info: 'Здесь будут отображаться введённые Вами фразы. Вы можете ввести, отредактировать и удалить фразы. Введите фразу с новой строки или через запятую, например "белые кроссовки". Все запросы, имеющие фразу "белые кроссовки" показываться не будут. Но по запросу "кроссовки для бега" показывать будут.',
                },
                inputWord: {
                  input: 'Введите минус-слово',
                  button: 'Добавить',
                },
                buttons: {
                  delete: 'Удалить',
                  cancel: 'Отменить',
                },
              },
            },
          },
          overallStats: {
            title: 'Общая статистика кампаний',
            loadingScreen: {
              part1: 'Сбор статистики по всем кампаниям может занять',
              part2: 'до 5 минут',
            },
          },
          createCampaign: {
            title: 'Создание рекламной кампании',
            next: 'Продолжить',
            back: 'Назад',
            saved: 'Кампания сохранена',
            type: {
              carousel: 'Карточка товара',
              catalog: 'Каталог',
              search: 'Поиск',
            },
            path: {
              yourCampaign: 'Ваша кампания',
              assortment: 'Ассортимент',
              confirmation: 'Подтверждение',
            },
            yourCampaign: {
              title: 'Введите название кампании',
              name: 'Название кампании',
              nameError: 'Введите название кампании',
            },
            assortment: {
              title: 'Выберите группы предметов и товары',
              subjects: {
                label: 'Группы предметов',
                selected: 'Выбрано',
                noSelected: 'Группы предметов',
              },
              products: {
                label: 'Товары',
                selected: 'Выбрано',
                noSelected: 'Товары',
              },
            },
            confirmation: {
              title: 'Информация о кампании',
              name: 'Название кампании',
              subjects: {
                label: 'Группы предметов',
                selected: 'Выбрано',
              },
              products: {
                nomenclature: 'Номенклатура',
                brand: 'Бренд',
                name: 'Наименование',
              },
              create: 'Создать',
            },
          },
        },
      },
      nicheAnalysis: {
        back: 'Назад',
        title: 'Анализ ниши',
        loadingText: {
          part1: 'Поиск ниш может занять',
          part2: 'до 5 минут',
        },
        search: {
          text: 'Введите название ниши',
          action: 'Поиск',
        },
        table: {
          category: 'Название',
          categoryPath: 'Категория',
          revenueFromAllProduct: 'Выручка',
          averageRevenuePerOneProductWithSales: 'Средняя\u00A0выручка',
          numberOfProducts: 'Объем',
          numberOfSellers: 'К\u2011во\u00A0продавцов',
          productSaleProbability: 'Товаров\u00A0с\nпродажами\u00A0%',
          sellerWithSalesProbability: 'Продавцов\u00A0с\nпродажами\u00A0%',
          averageMedianCheck: 'Средний\u00A0чек',
          salesTrendForTheLastMonth: 'Тренд\u00A0продаж',
          monopolization: {
            label: 'Продаж\u00A0у\u00A0топ\u00A05\u00A0%',
            top: 'Топ',
          },
          numberOfProductsWithRevenueGreaterThanOneMillion:
            'товары\u00A0с\u00A0выручкой\nот\u00A01\u00A0млн/мес',
          averageCommentsAmount: 'Ср.\u00A0отзывов',
          top3SellerAverageRevenue: 'Ср.\u00A0выручка\u00A0у\u00A0топ\u00A03 ',
          top10SellerAverageRevenue: 'Ср.\u00A0выручка\u00A0у\u00A0топ\u00A010',
          top30SellerAverageRevenue: 'Ср.\u00A0выручка\u00A0у\u00A0топ\u00A030',
          top3SellerAverageComments: 'Ср.\u00A0отзывов\u00A0у\u00A0топ\u00A03',
          top10SellerAverageComments: 'Ср.\u00A0отзывов\u00A0у\u00A0топ\u00A010',
          top30SellerAverageComments: 'Ср.\u00A0отзывов\u00A0у\u00A0топ\u00A030',
          purchaseProductsBudget: 'Бюджет\u00A0на\u00A0закупку',
          selfPurchaseProductBudget: 'Бюджет\u00A0на\u00A0самовыкупы',
        },
        product: {
          back: 'Назад',
          title: 'Анализ ниши',
          table: {
            preview: 'превью',
            article: 'артикул',
            name: 'название',
            color: 'цвет',
            category: 'ниша',
            position: 'позиция',
            brand: 'бренд',
            seller: 'продавец',
            stock: 'наличие',
            comments: 'комментарий',
            rating: 'рейтинг',
            price: 'цена',
            clientPrice: 'цена\u00A0ССП',
            daysInStock: 'был\u00A0в\u00A0наличии',
            sales: 'продажи',
            revenue: 'выручка',
            salesTrend: 'тренд\u00A0продаж',
          },
          nextRows: {
            next: 'Следующие',
            rows: 'товаров',
          },
        },
      },
      nicheAnalysisDeep: {
        title: 'Выбор ниши',
        savedFilters: 'Сохраненные фильтры',
        checkHeadCellsModal: {
          title: 'Выберите отображаемые столбцы',
          search: 'название столбца',
          render: 'Отобразить',
        },
        saveFilters: {
          save: 'Сохранить',
          modal: {
            title: 'Введите название фильтра',
            save: 'Сохранить',
            noNameError: 'Название фильтра не должно быть пустым',
          },
        },
        deleteFilters: {
          title: 'Вы уверены, что хотите удалить данную фильтрацию?',
          delete: 'Да',
          cancel: 'Нет',
        },
        filterCategories: 'Фильтр категорий',
        filters: {
          from: 'От',
          to: 'До',
          revenueFromAllProduct: 'Выручка',
          numberOfProducts: 'Объем',
          numberOfSellers: 'Количество продавцов',
          productSaleProbability: 'Товаров с продажами %',
          sellerWithSalesProbability: 'Продавцов с продажами %',
          monopolization: 'Продаж у топ 5 %',
          numberOfProductsWithRevenueGreaterThanOneMillion:
            'Товары с выручкой от 1 млн.руб. в мес.',
          purchaseProductsBudget: 'Бюджет на закупку',
          selfPurchaseProductBudget: 'Бюджет на самовыкупы',
          averageRevenuePerOneProductWithSales: 'Ср. выручка на 1 товар',
          averageMedianCheck: 'Средний чек',
          averageCommentsAmount: 'Ср. отзывов',
          top3SellerAverageRevenue: 'Ср. выручка у топ 3',
          top10SellerAverageRevenue: 'Ср. выручка у топ 10',
          top30SellerAverageRevenue: 'Ср. выручка у топ 30',
          top3SellerAverageComments: 'Ср. отзывов у топ 3',
          top10SellerAverageComments: 'Ср. отзывов у топ 10',
          top30SellerAverageComments: 'Ср. отзывов у топ 30',
        },
        search: 'Найти',
        reset: 'Сбросить',
        table: {
          title: 'Выбор ниши',
          back: 'Назад',
          controls: {
            filter: 'Редактировать фильтры',
            checkHeadCells: 'Скрыть столбцы',
          },
          filterChange: {
            filter: 'фильтровать',
            from: 'От',
            to: 'До',
            save: 'Сохранить',
          },
          table: {
            category: 'Название',
            categoryPath: 'Категория',
            rating: 'Оценка\u00A0ниши',
            revenueFromAllProduct: 'Выручка',
            averageRevenuePerOneProductWithSales: 'Средняя\u00A0выручка',
            numberOfProducts: 'Объем',
            numberOfSellers: 'К\u2011во\u00A0продавцов',
            productSaleProbability: 'Товаров\u00A0с\nпродажами\u00A0%',
            sellerWithSalesProbability: 'Продавцов\u00A0с\nпродажами\u00A0%',
            averageMedianCheck: 'Средний\u00A0чек',
            salesTrendForTheLastMonth: 'Тренд\u00A0продаж',
            monopolization: {
              label: 'Продаж\u00A0у\u00A0топ\u00A05\u00A0%',
              top: 'Топ',
            },
            numberOfProductsWithRevenueGreaterThanOneMillion:
              'Товары\u00A0с\u00A0выручкой\nот\u00A01\u00A0млн/мес',
            averageCommentsAmount: 'Ср.\u00A0отзывов',
            top3SellerAverageRevenue: 'Ср.\u00A0выручка\u00A0у\u00A0топ\u00A03 ',
            top10SellerAverageRevenue: 'Ср.\u00A0выручка\u00A0у\u00A0топ\u00A010',
            top30SellerAverageRevenue: 'Ср.\u00A0выручка\u00A0у\u00A0топ\u00A030',
            top3SellerAverageComments: 'Ср.\u00A0отзывов\u00A0у\u00A0топ\u00A03',
            top10SellerAverageComments: 'Ср.\u00A0отзывов\u00A0у\u00A0топ\u00A010',
            top30SellerAverageComments: 'Ср.\u00A0отзывов\u00A0у\u00A0топ\u00A030',
            purchaseProductsBudget: 'Бюджет\u00A0на\u00A0закупку',
            selfPurchaseProductBudget: 'Бюджет\u00A0на\u00A0самовыкупы',
          },
        },
        products: {
          title: 'Выбор ниши',
          rating: 'Оценка ниши',
          export: 'Экспортировать',
          checkHeadCells: {
            control: 'Выбрать столбцы',
            hiddenHeadCells: 'скрытых столбцов:',
          },
          table: {
            preview: 'превью',
            article: 'артикул',
            name: 'название',
            color: 'цвет',
            category: 'ниша',
            position: 'позиция',
            brand: 'бренд',
            seller: 'продавец',
            stock: 'наличие',
            comments: 'комментарий',
            rating: 'рейтинг',
            price: 'цена',
            clientPrice: 'цена\u00A0ССП',
            daysInStock: 'был\u00A0в\u00A0наличии',
            sales: 'продажи',
            revenue: 'выручка',
            salesTrend: 'тренд\u00A0продаж',
          },
          nextRows: {
            next: 'Следующие',
            rows: 'товаров',
          },
        },
      },
    },
  },
  manageTools: {
    title: 'Инструменты',
    beta: 'Бета',
    subscriptions: {
      title: 'Подписки',
      addToolSubscription: {
        openCreate: 'Добавить',
        cancelCreate: 'Закрыть',
        create: 'Добавить',
        price: {
          label: 'Цена',
          placeholder: '20',
        },
        type: {
          label: 'Тип подписки',
          interval: 'Интервальная',
          quantitative: 'Количественная',
        },
        interval: {
          label: 'Интервал',
          week: 'Неделя',
          month: 'Месяц',
          months: 'Месяца',
          halfYear: 'Полгода',
          year: 'Год',
        },
        quantitative: 'Количество',
      },
      availableSubscription: {
        edit: 'Редактировать',
        delete: 'Удалить',
      },
    },
  },
};

// ruLang.
