import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import { getAccessToken, setAccessToken } from '../utils/jwt';
// @types
import { ActionMap, AuthState, JWTContextType } from '../@types/auth';
import { apolloClient } from '../apollo/client/client';
import { LoginDocument, SignUpDocument, WhoamiDocument } from '../apollo/autogenerate/hooks';
import { SignUpInput, User } from '../apollo/autogenerate/schemas';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: User | null;
  };
  [Types.Login]: {
    user: User | null;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: User | null;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

const whoami = async () => {
  const resMe = await apolloClient.query({
    query: WhoamiDocument,
    fetchPolicy: 'no-cache',
  });
  const me = resMe?.data?.whoami;
  const user = me as User;
  return user;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getAccessToken();

        if (accessToken) {
          const user = await whoami();

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const doLogin = async (phone: string, password: string) => {
    const resToken = await apolloClient.mutate({
      mutation: LoginDocument,
      variables: { input: { phone, password } },
    });

    const accessToken = resToken.data?.login.jwtToken;
    setAccessToken(accessToken);
  };

  const login = async (phone: string, password: string) => {
    await doLogin(phone, password);
    const user = await whoami();

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const register = async (input: SignUpInput) => {
    await apolloClient.mutate({
      mutation: SignUpDocument,
      variables: { input },
    });
    await doLogin(input.phoneNumber, input.password);
    const user = await whoami();

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const refresh = async () => {
    const user = await whoami();

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setAccessToken(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
