import { onError } from '@apollo/client/link/error';
import { getAccessToken, setAccessToken } from '../../../utils/jwt';

export const errorLink = onError(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ graphQLErrors, networkError, response, operation, forward }) => {
    if (graphQLErrors) {
      if (
        graphQLErrors.some((error: any) => error?.message === 'Unauthorized') &&
        getAccessToken()
      ) {
        setAccessToken(null);
        window.location.reload();
      } else {
        graphQLErrors.forEach((error) => {
          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Message: ${error.message}`,
            `Location: ${error.locations?.concat().toString()}`,
            `Path: ${error.path}`
          );
        });
      }
    }

    if (networkError) {
      // @apollo/client/link/retry
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${networkError}`);
    }
  }
);
