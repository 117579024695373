// routes
export const setAccessToken = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
  }
};

export const getAccessToken = () => localStorage.getItem('accessToken');
