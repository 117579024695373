import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router';
import useLocales from '../../hooks/useLocales';
import { PATH_DASHBOARD } from '../../routes/paths';
import Iconify from '../Iconify';

export const MarketsButton = () => {
  const nav = useNavigate();
  const { t } = useLocales();

  return (
    <Tooltip title={t('dashboard.marketBar.markets')}>
      <IconButton
        onClick={() => {
          nav(PATH_DASHBOARD.market.markets);
        }}
      >
        <Iconify icon={'mdi:gear'} />
      </IconButton>
    </Tooltip>
  );
};
