import React, { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import { User_Role } from '../apollo/autogenerate/schemas';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};
const RBF =
  (roles: User_Role[]) =>
  ({ children }: { children: React.ReactNode }) =>
    <RoleBasedGuard roles={roles} hasContent children={children} />;

const RBU = RBF([User_Role.Customer]);
//const RBA = RBF([User_Role.Operator, User_Role.Superadmin]);
const RBSA = RBF([User_Role.Superadmin]);

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <AppPage /> },
        {
          path: 'markets',
          element: (
            <RBU>
              <MarketsPage />
            </RBU>
          ),
        },
        {
          path: 'market',
          children: [
            {
              path: 'edit/:id',
              element: (
                <RBU>
                  <EditMarketPage />
                </RBU>
              ),
            },
          ],
        },
        {
          path: 'deposit',
          children: [
            {
              path: '',
              element: (
                <RBU>
                  <DepositRedirectPage />
                </RBU>
              ),
            },
            {
              path: ':id',
              element: (
                <RBU>
                  <DepositPage />
                </RBU>
              ),
            },
            {
              path: 'create/:id',
              element: (
                <RBU>
                  <CreateDepositPage />
                </RBU>
              ),
            },
          ],
        },
        {
          path: 'sa',
          children: [
            { element: <Navigate to="/dashboard/sa/tools" replace />, index: true },
            {
              path: 'tools',
              element: (
                <RBSA>
                  <ToolsPage />
                </RBSA>
              ),
            },
          ],
        },
        {
          path: 'wb',
          children: [
            {
              path: 'feedbacks',
              children: [
                {
                  path: 'main',
                  element: (
                    <RBU>
                      <WbFeedbacks />
                    </RBU>
                  ),
                },
                {
                  path: 'denominations',
                  element: (
                    <RBU>
                      <FeedbackDenominations />
                    </RBU>
                  ),
                },
                {
                  path: 'recommendations',
                  element: (
                    <RBU>
                      <FeedbacksRecommendations />
                    </RBU>
                  ),
                },
              ],
              element: (
                <RBU>
                  <FeedbacksPage />
                </RBU>
              ),
            },
            {
              path: 'bidder',
              element: (
                <RBU>
                  <BidderPage />
                </RBU>
              ),
            },
            {
              path: 'popular-requests',
              element: (
                <RBU>
                  <PopularRequestsPage />
                </RBU>
              ),
            },
            {
              path: 'campaigns',
              element: (
                <RBU>
                  <CompaingsStateManager>
                    <CampaignsPage />
                  </CompaingsStateManager>
                </RBU>
              ),
            },
            {
              path: 'campaigns/create',
              element: (
                <RBU>
                  <CompaingsStateManager>
                    <CreateCampaignPage />
                  </CompaingsStateManager>
                </RBU>
              ),
            },
            {
              path: 'unit-economy',
              element: (
                <RBU>
                  <UnitEconomyPage />
                </RBU>
              ),
            },
            {
              path: 'feedbacks-parser',
              element: (
                <RBU>
                  <FeedbacksParserPage />
                </RBU>
              ),
            },
            {
              path: 'campaigns/:campaignId',
              element: (
                <RBU>
                  <CompaingsStateManager>
                    <CampaignPage />
                  </CompaingsStateManager>
                </RBU>
              ),
            },
            {
              path: 'campaigns/stats',
              element: (
                <RBU>
                  <CompaingsStateManager>
                    <CampaignsStatisticsPage />
                  </CompaingsStateManager>
                </RBU>
              ),
            },
            {
              path: 'niche-analysis',
              element: (
                <RBU>
                  <NicheAnalysisPage />
                </RBU>
              ),
            },
            {
              path: 'niche-analysis/:categoryPath',
              element: (
                <RBU>
                  <NicheProductPage />
                </RBU>
              ),
            },
            {
              path: 'niche-deep-analysis',
              element: (
                <RBU>
                  <NicheAnalysisDeepPage />
                </RBU>
              ),
            },
            {
              path: 'niche-deep-analysis/niches',
              element: (
                <RBU>
                  <NicheAnalysisDeepPageTable />
                </RBU>
              ),
            },
            {
              path: 'niche-deep-analysis/:categoryPath',
              element: (
                <RBU>
                  <NicheAnalysisDeepProductsPage />
                </RBU>
              ),
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500Page /> },
        { path: '404', element: <Page404Page /> },
        { path: '403', element: <Page403Page /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <ContactPage /> },
        { path: 'faqs', element: <FaqsPage /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword/ResetPassword')));

// DASHBOARD

// GENERAL
const AppPage = Loadable(lazy(() => import('../pages/dashboard/general/AppPage/AppPage')));

// MARKET
const EditMarketPage = Loadable(
  lazy(() => import('../pages/dashboard/market/EditMarketPage/EditMarketPage'))
);
const MarketsPage = Loadable(
  lazy(() => import('../pages/dashboard/market/MarketsPage/MarketsPage'))
);

// DEPOSIT
const CreateDepositPage = Loadable(
  lazy(() => import('../pages/dashboard/deposit/CreateDepositPage'))
);
const DepositRedirectPage = Loadable(
  lazy(() => import('../pages/dashboard/deposit/DepositRedirectPage/DepositRedirectPage'))
);
const DepositPage = Loadable(
  lazy(() => import('../pages/dashboard/deposit/DepositPage/DepositPageParam'))
);
// SuperAdmin
const ToolsPage = Loadable(lazy(() => import('../pages/dashboard/superAdmin/ToolsPage/ToolsPage')));

// TOOLS
// WB-TOOLS
const BidderPage = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/BidderPage/BidderPage'))
);
const FeedbacksPage = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/FeedbacksPage/FeedbacksPage'))
);
const FeedbackDenominations = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/FeedbacksPage/FeedbackDenominations'))
);
const FeedbacksRecommendations = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/FeedbacksPage/FeedbacksRecommendations'))
);
const WbFeedbacks = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/FeedbacksPage/Feedbacks'))
);
const PopularRequestsPage = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/PopularRequestsPage/PopularRequestsPage'))
);
const CompaingsStateManager = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/CampaignsPages/CompaingsStateManager'))
);
const CampaignsPage = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/CampaignsPages/CampaignsPage'))
);
const CampaignsStatisticsPage = Loadable(
  lazy(
    () => import('../pages/dashboard/tools/wb/CampaignsPages/statistics/CampaignsStatisticsPage')
  )
);
const CampaignPage = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/CampaignsPages/CampaignPage'))
);
const NicheAnalysisPage = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/NicheAnalysisPage/NicheAnalysisPage'))
);
const NicheProductPage = Loadable(
  lazy(
    () => import('../pages/dashboard/tools/wb/NicheAnalysisPage/NicheProductPage/NicheProductPage')
  )
);
const UnitEconomyPage = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/UnitEconomy/UnitEconomyPage'))
);
const NicheAnalysisDeepPage = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/NicheAnalysisDeepPage/NicheAnalysisDeepPage'))
);
const NicheAnalysisDeepPageTable = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/tools/wb/NicheAnalysisDeepPage/NicheAnalysisDeepTable/NicheAnalysisDeepTable'
      )
  )
);
const NicheAnalysisDeepProductsPage = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/tools/wb/NicheAnalysisDeepPage/NicheAnalysisDeepProductsPage/NicheAnalysisDeepProductsPage'
      )
  )
);
const CreateCampaignPage = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/CampaignsPages/createCampaign/CreateCampaignPage'))
);
const FeedbacksParserPage = Loadable(
  lazy(() => import('../pages/dashboard/tools/wb/FeedbacksParser/FeedbacksParserPage'))
);

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/landing/Home/Home')));
const AboutPage = Loadable(lazy(() => import('../pages/landing/About/About')));
const ContactPage = Loadable(lazy(() => import('../pages/landing/Contact/Contact')));
const FaqsPage = Loadable(lazy(() => import('../pages/landing/Faqs/Faqs')));
const MaintenancePage = Loadable(lazy(() => import('../pages/landing/Maintenance')));
const Page403Page = Loadable(lazy(() => import('../pages/landing/Page403')));
const Page404Page = Loadable(lazy(() => import('../pages/landing/Page404')));
const Page500Page = Loadable(lazy(() => import('../pages/landing/Page500')));
