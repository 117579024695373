import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { Market } from '../../apollo/autogenerate/schemas';
import useLocales from '../../hooks/useLocales';
import { useAppDispatch } from '../../redux';
import { marketChanged } from '../../redux/events/eventsSlice';
import { setMarket } from '../../redux/market/marketSlice';
import { getUserRoleMarketName } from '../../utils/localization/getUserRoleMarketName';

interface MarketsSelectProps {
  markets: Market[];
  activeMarket?: Market;
}

export const MarketsSelect: FC<MarketsSelectProps> = ({ markets, activeMarket }) => {
  const dispatch = useAppDispatch();
  const { t } = useLocales();

  return (
    <Stack direction="column">
      <TextField
        select
        variant="standard"
        size="small"
        value={activeMarket?.id}
        label={t('dashboard.marketBar.marketsSelect.label')}
        sx={{
          width: 200,
          '& > div': { fontWeight: '600' },
        }}
      >
        {markets.map((market) => (
          <MenuItem
            key={market.id}
            value={market.id}
            sx={{ fontWeight: '600' }}
            onClick={() => {
              dispatch(setMarket(market));
              dispatch(marketChanged());
            }}
          >
            {market.name}
          </MenuItem>
        ))}
      </TextField>
      {activeMarket && (
        <Typography component="span" variant="caption">
          {getUserRoleMarketName(t, activeMarket?.role)}
        </Typography>
      )}
    </Stack>
  );
};
