import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSyncIds, setSyncId, StoredId } from './syncIds';

interface AdvertCampaignsSyncIdReducerState {
  syncIds: StoredId[];
}

const initialState: AdvertCampaignsSyncIdReducerState = {
  syncIds: getSyncIds() ?? [],
};

export const advertCampaignsSyncIdSlice = createSlice({
  name: 'advertCampaignsSyncId',
  initialState,
  reducers: {
    setAdvertCampaignSyncId: (state, action: PayloadAction<StoredId>) => {
      const newId = action.payload;
      setSyncId(newId);
      state.syncIds.push(newId);
    },
    removeAdvertCampaignSyncId: (state, action: PayloadAction<StoredId>) => {
      const removeId = action.payload;
      setSyncId(removeId);
      const syncIds = state.syncIds.filter((id) => id.marketId !== removeId.marketId);
      return { syncIds };
    },
  },
});

export const { setAdvertCampaignSyncId, removeAdvertCampaignSyncId } =
  advertCampaignsSyncIdSlice.actions;
export const advertCampaignsSyncIdReducer = advertCampaignsSyncIdSlice.reducer;
