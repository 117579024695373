// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  market: {
    markets: path(ROOTS_DASHBOARD, '/markets'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/market/edit/${id}`),
  },
  deposit: {
    deposit: path(ROOTS_DASHBOARD, `/deposit`),
    create: (id: string) => path(ROOTS_DASHBOARD, `/deposit/create/${id}`),
  },
  superAdmin: {
    tools: path(ROOTS_DASHBOARD, '/sa/tools'),
  },
  tools: {
    wb: {
      niches: path(ROOTS_DASHBOARD, '/wb/niches'),
      feedbacks: path(ROOTS_DASHBOARD, '/wb/feedbacks'),
      feedbacksMain: path(ROOTS_DASHBOARD, '/wb/feedbacks/main'),
      feedbacksDenominations: path(ROOTS_DASHBOARD, '/wb/feedbacks/denominations'),
      feedbacksRecommendations: path(ROOTS_DASHBOARD, '/wb/feedbacks/recommendations'),
      bidder: path(ROOTS_DASHBOARD, '/wb/bidder'),
      popularRequests: path(ROOTS_DASHBOARD, '/wb/popular-requests'),
      campaigns: path(ROOTS_DASHBOARD, '/wb/campaigns'),
      createCampaign: path(ROOTS_DASHBOARD, '/wb/campaigns/create'),
      campaign: (campaignId: string) => path(ROOTS_DASHBOARD, `/wb/campaigns/${campaignId}`),
      campaignStats: path(ROOTS_DASHBOARD, '/wb/campaigns/stats'),
      nicheAnalysis: path(ROOTS_DASHBOARD, '/wb/niche-analysis'),
      nicheProduct: (categoryPath: string) =>
        path(ROOTS_DASHBOARD, `/wb/niche-analysis/${categoryPath}`),
      unitEconomy: path(ROOTS_DASHBOARD, '/wb/unit-economy'),
      nicheAnalysisDeep: path(ROOTS_DASHBOARD, '/wb/niche-deep-analysis'),
      nicheAnalysisDeepTable: path(ROOTS_DASHBOARD, '/wb/niche-deep-analysis/niches'),
      nicheAnalysisDeepProductsPage: (categoryPath: string) =>
        path(ROOTS_DASHBOARD, `/wb/niche-deep-analysis/${categoryPath}`),
      feedbacksParser: path(ROOTS_DASHBOARD, '/wb/feedbacks-parser'),
    },
  },
};
