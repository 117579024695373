const ID_KEY = 'SYNC-ID';

export interface StoredId {
  marketId: string;
  syncId?: string;
}

const storeIds = (newIds: StoredId[]) => {
  const lsIds = JSON.stringify(newIds);
  localStorage.setItem(ID_KEY, lsIds);
};

export const getSyncIds = () => {
  const data = localStorage.getItem(ID_KEY);
  if (!data) return undefined;
  const ids: StoredId[] = JSON.parse(data);
  return ids;
};

export const setSyncId = (newId: StoredId) => {
  const { marketId, syncId } = newId;
  const ids = getSyncIds();

  if (!ids) {
    if (!syncId) return;
    // create new syncIds storage
    const newIds: StoredId[] = [newId];
    storeIds(newIds);
    return;
  }

  // remove market's syncId
  if (!syncId) {
    const newIds = ids.filter((id) => id.marketId !== marketId);
    storeIds(newIds);
    return;
  }

  // add new syncId
  ids.push(newId);
  storeIds(ids);
};
