import { Button, Stack, Typography, Box } from '@mui/material';
import { useMarketFindAllForUserQuery } from '../../../apollo/autogenerate/hooks';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Iconify from '../../../components/Iconify';
import { MarketsButton } from '../../../components/layouts/MarketsButton';
import { MarketsSelect } from '../../../components/layouts/MarketsSelect';
import { useRefetchOnMarketChanged } from '../../../hooks/useRefetchOnMarketChanged';
import { useAppSelector } from '../../../redux';
import useResponsive from '../../../hooks/useResponsive';
import useLocales from '../../../hooks/useLocales';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useErrorSnackbar } from '../../../hooks/useErrorSnackbar';

export const MarketBar = () => {
  const { data, error, refetch } = useMarketFindAllForUserQuery({
    fetchPolicy: 'no-cache',
  });
  useRefetchOnMarketChanged(refetch);
  const isMobile = useResponsive('down', 'sm');

  const curMarket = useAppSelector((s) => s.market.currentMarket);
  const market = data?.marketFindAllForUser?.find((m) => m.id === curMarket?.id);

  const { t } = useLocales();
  const nav = useNavigate();
  const errorSnackbar = useErrorSnackbar();

  if (error) errorSnackbar(error);
  if (!data || error) return <MarketsButton />;
  return (
    <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2 }}>
      {data.marketFindAllForUser.length > 0 && (
        <MarketsSelect activeMarket={market} markets={data?.marketFindAllForUser} />
      )}
      {market && !isMobile && (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Button
            component={RouterLink}
            to={PATH_DASHBOARD.deposit.deposit}
            sx={{ minWidth: 'auto' }}
          >
            <Iconify icon="clarity:wallet-line" fontSize="24px" />
            <Typography
              component="span"
              fontWeight={600}
              sx={{ ml: 1, color: (theme) => theme.palette.text.primary }}
            >
              {market?.balance} {t('dashboard.marketBar.rub')}
            </Typography>
          </Button>
        </Stack>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!isMobile && (
          <>
            <MarketsButton />
            <Typography
              onClick={() => {
                nav(PATH_DASHBOARD.market.markets);
              }}
              fontWeight={600}
              component="span"
              sx={{
                cursor: 'pointer',
                marginLeft: '0',
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {t('dashboard.marketBar.settings')}
            </Typography>
          </>
        )}
      </Box>
    </Stack>
  );
};
