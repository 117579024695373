export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AdvertCampaign = {
  __typename?: 'AdvertCampaign';
  autoDepositAmount: Scalars['Int'];
  autoDepositLimit: Scalars['Int'];
  averageBid: Scalars['Float'];
  biddersCpmMax: Array<AdvertCampaignBidderValue>;
  budgetDailyMax: Scalars['Int'];
  budgetTotal: Scalars['Int'];
  carouselPlaces?: Maybe<Array<AdvertCampaignCarouselPlace>>;
  catalogPlaces?: Maybe<Array<AdvertCampaignCatalogPlace>>;
  cpc: Scalars['Float'];
  cpm?: Maybe<Scalars['Float']>;
  ctr: Scalars['Float'];
  holdingPositions: Array<AdvertCampaignBidderValue>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isAutoDepositEnabled: Scalars['Boolean'];
  isBudgetAllocationEnabled: Scalars['Boolean'];
  isWordsSyncEnabled: Scalars['Boolean'];
  marketId: Scalars['String'];
  maxBid: Scalars['Float'];
  name: Scalars['String'];
  products: Array<AdvertCampaignProduct>;
  searchPlaces?: Maybe<Array<AdvertCampaignSearchPlace>>;
  startDate?: Maybe<Scalars['DateTime']>;
  type: AdvertCampaignType;
  wbId: Scalars['Int'];
  wbStatus: AdvertCampaignStatus;
};

export type AdvertCampaignAnalyticsStats = {
  __typename?: 'AdvertCampaignAnalyticsStats';
  acosPercent: Scalars['Float'];
  acosRub: Scalars['Float'];
  advertBudget: Scalars['Float'];
  advertOrders: Scalars['Float'];
  advertOrdersRub: Scalars['Float'];
  advertSales: Scalars['Float'];
  advertSalesRub: Scalars['Float'];
  averageCheck: Scalars['Float'];
  buyoutPercent: Scalars['Float'];
  cacOrder: Scalars['Float'];
  cacSale: Scalars['Float'];
  clicks: Scalars['Int'];
  cpc: Scalars['Float'];
  crPercent: Scalars['Float'];
  crRub: Scalars['Float'];
  ctrPercent: Scalars['Float'];
  date: Scalars['DateTime'];
  profitFromOneSKU: Scalars['Float'];
  romiOrder: Scalars['Float'];
  romiSale: Scalars['Float'];
  tacosPercent: Scalars['Float'];
  tacosRub: Scalars['Float'];
  totalOrders: Scalars['Int'];
  totalOrdersRub: Scalars['Float'];
  updateBudget: Scalars['Float'];
  views: Scalars['Int'];
  wbOrdersRub: Scalars['Float'];
};

export type AdvertCampaignArticleStats = {
  __typename?: 'AdvertCampaignArticleStats';
  article: Scalars['Int'];
  atbs: Scalars['Int'];
  cac: Scalars['Float'];
  clicks: Scalars['Int'];
  cpc: Scalars['Float'];
  cr: Scalars['Float'];
  ctr: Scalars['Float'];
  date: Scalars['DateTime'];
  frequency: Scalars['Float'];
  key: Scalars['String'];
  orders: Scalars['Int'];
  sum: Scalars['Float'];
  uniqueUsers: Scalars['Int'];
  views: Scalars['Int'];
};

export type AdvertCampaignBidChange = {
  __typename?: 'AdvertCampaignBidChange';
  createdDate: Scalars['DateTime'];
  name: Scalars['String'];
  newBid: Scalars['Float'];
  placeId: Scalars['String'];
};

export type AdvertCampaignBidderValue = {
  __typename?: 'AdvertCampaignBidderValue';
  placeId: Scalars['String'];
  value: Scalars['Float'];
};

export type AdvertCampaignCarouselPlace = {
  __typename?: 'AdvertCampaignCarouselPlace';
  bidderCpmMax: Scalars['Int'];
  bidderPosition: Scalars['Int'];
  id: Scalars['String'];
  intervals: Array<AdvertCampaignPlacementIntervalObjectType>;
  isBidderActive: Scalars['Boolean'];
  kindName: Scalars['String'];
  price: Scalars['Float'];
  subjectName: Scalars['String'];
};

export type AdvertCampaignCatalogPlace = {
  __typename?: 'AdvertCampaignCatalogPlace';
  bidderCpmMax: Scalars['Int'];
  bidderPosition: Scalars['Int'];
  id: Scalars['String'];
  intervals: Array<AdvertCampaignPlacementIntervalObjectType>;
  isBidderActive: Scalars['Boolean'];
  menuName: Scalars['String'];
  menuPath: Scalars['String'];
  menuUrl: Scalars['String'];
  price: Scalars['Float'];
  subjectName: Scalars['String'];
};

export enum AdvertCampaignDevice {
  Android = 'Android',
  Ios = 'IOS',
  Unknown = 'Unknown',
  Web = 'Web',
}

export type AdvertCampaignFiltersOutput = {
  __typename?: 'AdvertCampaignFiltersOutput';
  brands: Array<Scalars['String']>;
  categories: Array<FlattenedMenuCategory>;
};

export type AdvertCampaignGeneralStats = {
  __typename?: 'AdvertCampaignGeneralStats';
  clicks: Scalars['Int'];
  cpc: Scalars['Float'];
  ctr: Scalars['Float'];
  duration: Scalars['Float'];
  frequency: Scalars['Float'];
  key: Scalars['String'];
  sum: Scalars['Float'];
  views: Scalars['Int'];
};

export type AdvertCampaignMetrics = {
  __typename?: 'AdvertCampaignMetrics';
  atbs: Scalars['Int'];
  cac: Scalars['Float'];
  clicks: Scalars['Int'];
  cpc: Scalars['Float'];
  cr: Scalars['Float'];
  ctr: Scalars['Float'];
  frequency: Scalars['Float'];
  orders: Scalars['Int'];
  sum: Scalars['Float'];
  uniqueUsers: Scalars['Int'];
  views: Scalars['Int'];
};

export type AdvertCampaignPerDayStats = {
  __typename?: 'AdvertCampaignPerDayStats';
  atbs: Scalars['Int'];
  cac: Scalars['Float'];
  clicks: Scalars['Int'];
  cpc: Scalars['Float'];
  cr: Scalars['Float'];
  ctr: Scalars['Float'];
  date: Scalars['DateTime'];
  frequency: Scalars['Float'];
  orders: Scalars['Int'];
  sum: Scalars['Float'];
  uniqueUsers: Scalars['Int'];
  views: Scalars['Int'];
};

export type AdvertCampaignPlacementIntervalInputType = {
  begin: Scalars['Int'];
  end: Scalars['Int'];
};

export type AdvertCampaignPlacementIntervalObjectType = {
  __typename?: 'AdvertCampaignPlacementIntervalObjectType';
  begin: Scalars['Int'];
  end: Scalars['Int'];
};

export type AdvertCampaignProduct = {
  __typename?: 'AdvertCampaignProduct';
  brandName?: Maybe<Scalars['String']>;
  cardUrl: Scalars['String'];
  categoryIds: Array<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  pictureUrl: Scalars['String'];
  vendorCode?: Maybe<Scalars['String']>;
};

export type AdvertCampaignSearchPlace = {
  __typename?: 'AdvertCampaignSearchPlace';
  bidderCpmMax: Scalars['Int'];
  bidderKeyword: Scalars['String'];
  bidderPosition: Scalars['Int'];
  id: Scalars['String'];
  intervals: Array<AdvertCampaignPlacementIntervalObjectType>;
  isBidderActive: Scalars['Boolean'];
  keyword: Scalars['String'];
  price: Scalars['Float'];
};

export enum AdvertCampaignStatus {
  Active = 'Active',
  ReadyToLaunch = 'ReadyToLaunch',
  Refused = 'Refused',
  ShowingsCompleted = 'ShowingsCompleted',
  Suspended = 'Suspended',
}

export type AdvertCampaignSubscription = {
  __typename?: 'AdvertCampaignSubscription';
  isActive: Scalars['Boolean'];
  marketId: Scalars['String'];
};

export type AdvertCampaignSummaryStats = {
  __typename?: 'AdvertCampaignSummaryStats';
  advertCampaign: AdvertCampaign;
  analyticsStats: Array<AdvertCampaignAnalyticsStats>;
  articleStats: Array<AdvertCampaignArticleStats>;
  bidChanges: Array<AdvertCampaignBidChange>;
  generalStats: Array<AdvertCampaignGeneralStats>;
  metrics: AdvertCampaignMetrics;
  overallGeneralStats?: Maybe<AdvertCampaignGeneralStats>;
};

export type AdvertCampaignSyncRequest = {
  __typename?: 'AdvertCampaignSyncRequest';
  errors: Array<DistributedRequestError>;
  id: Scalars['String'];
  marketId: Scalars['String'];
  progress: Scalars['Float'];
  status: DistributedRequestStatus;
};

export enum AdvertCampaignType {
  CarouselAuction = 'CarouselAuction',
  Catalog = 'Catalog',
  Search = 'Search',
}

export type AdvertCampaignWordKeyword = {
  __typename?: 'AdvertCampaignWordKeyword';
  count: Scalars['Int'];
  cpc: Scalars['Float'];
  ctr: Scalars['Float'];
  keyword: Scalars['String'];
  sum: Scalars['Float'];
};

export type AdvertCampaignWordsOutput = {
  __typename?: 'AdvertCampaignWordsOutput';
  excludedPhraseWords: Array<Scalars['String']>;
  excludedStrongWords: Array<Scalars['String']>;
  excludedWords: Array<Scalars['String']>;
  keywords: Array<AdvertCampaignWordKeyword>;
  plusWords: Array<Scalars['String']>;
};

export type AdvertCampaignsFilterBy = {
  campaignName?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<AdvertCampaignType>;
  wbId?: InputMaybe<Scalars['Int']>;
  wbStatus?: InputMaybe<AdvertCampaignStatus>;
};

export type AdvertCampaignsOrderBy = {
  averageBid?: InputMaybe<PaginationOrder>;
  biddersCpmMax?: InputMaybe<PaginationOrder>;
  budgetDailyMax?: InputMaybe<PaginationOrder>;
  cpc?: InputMaybe<PaginationOrder>;
  cpm?: InputMaybe<PaginationOrder>;
  ctr?: InputMaybe<PaginationOrder>;
  isActive?: InputMaybe<PaginationOrder>;
  maxBid?: InputMaybe<PaginationOrder>;
  name?: InputMaybe<PaginationOrder>;
  startDate?: InputMaybe<PaginationOrder>;
  type?: InputMaybe<PaginationOrder>;
  wbId?: InputMaybe<PaginationOrder>;
  wbStatus?: InputMaybe<PaginationOrder>;
};

export type AdvertCampaignsOverallStats = {
  __typename?: 'AdvertCampaignsOverallStats';
  overallAdvertCampaignStats: Array<AdvertCampaignSummaryStats>;
  overallGeneralStats: AdvertCampaignGeneralStats;
  overallMetrics: AdvertCampaignMetrics;
  overallPerDayStats: Array<AdvertCampaignPerDayStats>;
};

export type AdvertCampaignsStatsDistributedRequest = {
  __typename?: 'AdvertCampaignsStatsDistributedRequest';
  errors: Array<DistributedRequestError>;
  id: Scalars['String'];
  marketId: Scalars['String'];
  output?: Maybe<AdvertCampaignsOverallStats>;
  progress: Scalars['Float'];
  status: Scalars['String'];
};

export enum AvailableMarketSubscriptionStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Outdated = 'OUTDATED',
}

export type AvailableSubscription = {
  __typename?: 'AvailableSubscription';
  /** id */
  id: Scalars['String'];
  /** Type of marketplace */
  marketplace: Marketplace;
  /** Time period for subscription */
  maxTime?: Maybe<MarketSubscriptionDuration>;
  /** Max number of uses */
  maxUseAmount?: Maybe<Scalars['Int']>;
  /** Price */
  price: Scalars['String'];
  status: AvailableMarketSubscriptionStatus;
  /** Tool id */
  toolId: Scalars['String'];
  /** subscription type */
  type: MarketSubscriptionType;
};

export type Bid = {
  __typename?: 'Bid';
  bid: Scalars['Int'];
  brand: Scalars['String'];
  cardUrl: Scalars['String'];
  category: Scalars['Int'];
  deliveryTime: Scalars['Int'];
  name: Scalars['String'];
  pictureUrl: Scalars['String'];
  price: Scalars['Float'];
  sku: Scalars['Int'];
};

export type CardCountryCount = {
  __typename?: 'CardCountryCount';
  count: Scalars['Int'];
  country: Scalars['String'];
};

export type CardFeedback = {
  __typename?: 'CardFeedback';
  answerText?: Maybe<Scalars['String']>;
  cardUrl: Scalars['String'];
  color: Scalars['String'];
  country: Scalars['String'];
  feedbackDate: Scalars['DateTime'];
  feedbackText: Scalars['String'];
  id: Scalars['String'];
  matchingPhoto: Scalars['String'];
  matchingSize: Scalars['String'];
  pictureUrl: Scalars['String'];
  userName: Scalars['String'];
  valuation: Scalars['Int'];
  votesAgainst: Scalars['Int'];
  votesFor: Scalars['Int'];
};

export type CardInfo = {
  __typename?: 'CardInfo';
  cardName: Scalars['String'];
  cardPictureUrl: Scalars['String'];
  cardUrl: Scalars['String'];
  countryDistribution: Array<CardCountryCount>;
  feedbackCount: Scalars['Int'];
  feedbackWithPhotoCount: Scalars['Int'];
  photoUris: Array<CardPhotoUri>;
  rating?: Maybe<Scalars['Float']>;
  subjectName: Scalars['String'];
  subjectRootName: Scalars['String'];
  valuationDistribution: Array<CardValuation>;
  valuationSum: Scalars['Int'];
};

export type CardPhotoUri = {
  __typename?: 'CardPhotoUri';
  fullSizePhotoUrl: Scalars['String'];
  minSizePhotoUrl: Scalars['String'];
};

export type CardQuestion = {
  __typename?: 'CardQuestion';
  answerText: Scalars['String'];
  country: Scalars['String'];
  questionDate: Scalars['DateTime'];
  questionText: Scalars['String'];
  userName: Scalars['String'];
};

export type CardValuation = {
  __typename?: 'CardValuation';
  count: Scalars['Int'];
  valuation: Scalars['Int'];
};

export type Catalog = {
  __typename?: 'Catalog';
  id: Scalars['Int'];
  isPremium: Scalars['Boolean'];
  name: Scalars['String'];
  path: Scalars['String'];
  url: Scalars['String'];
};

export type CatalogsGroup = {
  __typename?: 'CatalogsGroup';
  catalogs: Array<Catalog>;
};

export type CreateAdvertCampaignCarouselAuctionGroup = {
  products: Array<SupplierProductInputType>;
  subject: IdNameInputType;
};

export type CreateAdvertCampaignCatalogGroup = {
  catalogIds: Array<Scalars['Int']>;
  products: Array<SupplierProductInputType>;
  subject: IdNameInputType;
};

export type CreateAdvertCampaignOutput = {
  __typename?: 'CreateAdvertCampaignOutput';
  id: Scalars['Int'];
  name: Scalars['String'];
  status: AdvertCampaignStatus;
  type: AdvertCampaignType;
};

export type CreateAdvertCampaignSearchGroup = {
  products: Array<SupplierProductInputType>;
  subject: IdNameInputType;
};

export type CreateAvailiableSubscriptionInput = {
  marketplace: Marketplace;
  maxTime?: InputMaybe<MarketSubscriptionDuration>;
  maxUseAmount?: InputMaybe<Scalars['Int']>;
  price: Scalars['String'];
  status: AvailableMarketSubscriptionStatus;
  toolId: Scalars['String'];
  type: MarketSubscriptionType;
};

export type CreateCarouselAuctionAdvertCampaignInput = {
  campaignName: Scalars['String'];
  groups: Array<CreateAdvertCampaignCarouselAuctionGroup>;
};

export type CreateCatalogAdvertCampaignInput = {
  campaignName: Scalars['String'];
  groups: Array<CreateAdvertCampaignCatalogGroup>;
};

export type CreateDepositInput = {
  amount: Scalars['String'];
  marketId: Scalars['String'];
};

export type CreateFeedbackStopWordInput = {
  answer: Scalars['String'];
  stopWord: Scalars['String'];
};

export type CreateMarketInput = {
  /** Marketplace */
  marketplace: Marketplace;
  name: Scalars['String'];
  payload: Scalars['String'];
};

export type CreateMarketRoleInput = {
  /** Marketplace */
  marketId: Scalars['String'];
  /** Marketplace */
  marketRole: UserRoleMarket;
  /** Marketplace */
  phone: Scalars['String'];
};

export type CreateMarketSubscription = {
  availableSubscriptionId: Scalars['String'];
  marketId: Scalars['String'];
};

export type CreateNicheAnalysisFilterInput = {
  averageCommentsAmount?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  averageMedianCheck?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  averageRevenuePerOneProductWithSales?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  name: Scalars['String'];
  numberOfProducts?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  numberOfProductsWithRevenueGreaterThanOneMillion?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  numberOfSellers?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  productSaleProbability?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  purchaseProductsBudget?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  revenueFromAllProduct?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  selfPurchaseProductBudget?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  sellerWithSalesProbability?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  top3SellerAverageComments?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  top3SellerAverageRevenue?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  top10SellerAverageComments?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  top10SellerAverageRevenue?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  top30SellerAverageComments?: InputMaybe<NicheAnalysisFilterFromToInputType>;
  top30SellerAverageRevenue?: InputMaybe<NicheAnalysisFilterFromToInputType>;
};

export type CreateProxyPageInput = {
  gtmCode?: InputMaybe<Scalars['String']>;
  productNm: Scalars['Int'];
  utmCampaign: Scalars['String'];
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium: Scalars['String'];
  utmSource: Scalars['String'];
  utmTerm?: InputMaybe<Scalars['String']>;
  yandexMetricaCode?: InputMaybe<Scalars['String']>;
};

export type CreateSearchAdvertCampaignInput = {
  campaignName: Scalars['String'];
  groups: Array<CreateAdvertCampaignSearchGroup>;
  plusWords: Array<Scalars['String']>;
};

export type CreateUpdatePhraseInput = {
  farewellPart?: InputMaybe<Scalars['String']>;
  greetingsPart?: InputMaybe<Scalars['String']>;
  mainPart?: InputMaybe<Scalars['String']>;
  productGrade: Scalars['Int'];
  recommendationsPart?: InputMaybe<Scalars['String']>;
  thanksPart?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  firstName?: InputMaybe<Scalars['String']>;
  is2faEnabled: Scalars['Boolean'];
  isBanned: Scalars['Boolean'];
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  patronymic?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  referalCode?: InputMaybe<Scalars['String']>;
  userRole: User_Role;
};

export type Deposit = {
  __typename?: 'Deposit';
  /** Amount */
  amount: Scalars['String'];
  /** Date of creation */
  createdAt: Scalars['DateTime'];
  /** id */
  id: Scalars['String'];
  /** UKassa User's confirmation URL Id */
  kassaConfirmationUrl: Scalars['String'];
  /** UKassa Payment Id */
  kassaPaymentId: Scalars['String'];
  /** Id market */
  marketId: Scalars['String'];
  status: DepositStatus;
  /** Id user */
  userId: Scalars['String'];
};

export enum DepositStatus {
  Canceled = 'Canceled',
  Pending = 'Pending',
  Succeeded = 'Succeeded',
}

export type DistributedRequestError = {
  __typename?: 'DistributedRequestError';
  createdAt: Scalars['DateTime'];
  errorCode: Scalars['Int'];
  httpErrorMessage: Scalars['String'];
  httpErrorStatus: Scalars['Int'];
  id: Scalars['String'];
  message: Scalars['String'];
};

export enum DistributedRequestStatus {
  InProgress = 'InProgress',
  Ready = 'Ready',
}

export type FeedbackAnswer = {
  __typename?: 'FeedbackAnswer';
  answerText: Scalars['String'];
  article: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  feedbackText: Scalars['String'];
  grade: Scalars['Int'];
  id: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type FeedbackArticle = {
  __typename?: 'FeedbackArticle';
  article: Scalars['Int'];
  name: Scalars['String'];
  recommendedArticles?: Maybe<Array<FeedbackArticle>>;
};

export type FeedbackStopWord = {
  __typename?: 'FeedbackStopWord';
  answer: Scalars['String'];
  id: Scalars['String'];
  stopWord: Scalars['String'];
};

export type FeedbackSubscription = {
  __typename?: 'FeedbackSubscription';
  createdAt: Scalars['DateTime'];
  feedbackAnswerStartDate: Scalars['DateTime'];
  feedbackAnswers?: Maybe<Array<FeedbackAnswer>>;
  isActive: Scalars['Boolean'];
  marketId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FlattenedMenuCategory = {
  __typename?: 'FlattenedMenuCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  parentId: Scalars['Int'];
  query: Scalars['String'];
  shard: Scalars['String'];
  url: Scalars['String'];
};

export type GetAdvertCampaignsInput = {
  filterBy?: InputMaybe<AdvertCampaignsFilterBy>;
  orderBy?: InputMaybe<AdvertCampaignsOrderBy>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type GetAdvertCampaignsOutput = {
  __typename?: 'GetAdvertCampaignsOutput';
  advertCampaigns: Array<AdvertCampaign>;
  count: Scalars['Int'];
};

export type GetAdvertCampaignsStatsFiltersInput = {
  brands: Array<Scalars['String']>;
  categoryIds: Array<Scalars['Int']>;
  devices: Array<AdvertCampaignDevice>;
};

export type GetAdvertCampaignsStatsInput = {
  advertCampaignIds: Array<Scalars['String']>;
  endDate: Scalars['DateTime'];
  filters?: InputMaybe<GetAdvertCampaignsStatsFiltersInput>;
  startDate: Scalars['DateTime'];
};

export type GetBidsInput = {
  location: LocationEnum;
  path: Scalars['String'];
};

export type GetCardFeedbacksOutput = {
  __typename?: 'GetCardFeedbacksOutput';
  cardFeedbacks: Array<CardFeedback>;
  cardInfo: CardInfo;
  cardQuestions: Array<CardQuestion>;
};

export type GetCatalogsInput = {
  groups: Array<NmIdsGroup>;
};

export type GetCatalogsOutput = {
  __typename?: 'GetCatalogsOutput';
  groups: Array<CatalogsGroup>;
};

export type GetFeedbackAnswersInput = {
  article?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type GetMarketSubscriptionsInput = {
  marketId: Scalars['String'];
  status?: InputMaybe<MarketSubscriptionStatus>;
  toolId?: InputMaybe<Scalars['String']>;
};

export type GetMarketUsersByRoleInput = {
  marketId: Scalars['String'];
  role: UserRoleMarket;
};

export type GetNicheCategoriesOutput = {
  __typename?: 'GetNicheCategoriesOutput';
  categories: Array<NicheCategory>;
  fetchCategory?: Maybe<NicheCategory>;
};

export type GetNotificationsInput = {
  isRead?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type GetNotificationsOutput = {
  __typename?: 'GetNotificationsOutput';
  count: Scalars['Int'];
  notifications: Array<NotificationUi>;
};

export type GetPopularRequestsInput = {
  excludeZeroBid?: InputMaybe<Scalars['Boolean']>;
  filterBy?: InputMaybe<PopularRequestsFilterBy>;
  orderBy?: InputMaybe<PopularRequestsOrderBy>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  withoutPrioritizedCategory?: InputMaybe<Scalars['Boolean']>;
};

export type GetPopularRequestsOutput = {
  __typename?: 'GetPopularRequestsOutput';
  count: Scalars['Int'];
  popularRequests: Array<PopularRequest>;
};

export type GetProxyPageInput = {
  fullLink?: InputMaybe<Scalars['String']>;
  shortLink?: InputMaybe<Scalars['String']>;
};

export type GetStocksInput = {
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type GetStocksOutput = {
  __typename?: 'GetStocksOutput';
  stocks?: Maybe<Array<Stock>>;
  total: Scalars['Int'];
};

export type GetSubjectsAndProductsOutput = {
  __typename?: 'GetSubjectsAndProductsOutput';
  products: Array<SupplierProductObjectType>;
  subjects: Array<Subject>;
};

export type GetTrendingSearchesInput = {
  nmId: Scalars['Int'];
};

export type GetUnitEconomyInput = {
  article: Scalars['Int'];
  date: Scalars['DateTime'];
};

export type GetUnitEconomyOutput = {
  __typename?: 'GetUnitEconomyOutput';
  /** Артикул */
  article: Scalars['Int'];
  /** Средний процент выкупа, % */
  buybackPercentage?: Maybe<Scalars['Float']>;
  /** Категория товара */
  category: Scalars['String'];
  /** Скидка от цены продажи до скидки ВБ % */
  discountBeforeDiscountWb?: Maybe<Scalars['Int']>;
  /** Средний процент выкупа редактируемый, bool */
  editableBuybackPercentage: Scalars['Boolean'];
  /** Высота */
  height?: Maybe<Scalars['Float']>;
  /** Длина */
  length?: Maybe<Scalars['Float']>;
  /** id Маркета */
  marketId: Scalars['String'];
  /** Название товара */
  name: Scalars['String'];
  /** Бюджет продвижения */
  promoBudget?: Maybe<Scalars['Float']>;
  /** Закупочная цена, руб */
  purchasePrice?: Maybe<Scalars['Float']>;
  /** Объём закупки, шт */
  purchaseVolume?: Maybe<Scalars['Int']>;
  /** Объём продаж, шт */
  saleVolume?: Maybe<Scalars['Int']>;
  /** Услуги ФФ, руб */
  servicesFF?: Maybe<Scalars['Float']>;
  /** Целевая наценка, % */
  targetMargin?: Maybe<Scalars['Int']>;
  /** Налоги, % */
  tax?: Maybe<Scalars['Int']>;
  /** Комиссия ВБ, % */
  taxWB?: Maybe<Scalars['Int']>;
  /** Желаемая прибыль, руб */
  wantedProfit?: Maybe<Scalars['Float']>;
  /** Вес в килограммах */
  weight?: Maybe<Scalars['Float']>;
  /** Ширина */
  width?: Maybe<Scalars['Float']>;
};

export type IdNameInputType = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type IdNameObjectType = {
  __typename?: 'IdNameObjectType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum LocationEnum {
  Moscow = 'Moscow',
  Yekaterinburg = 'Yekaterinburg',
}

export type LoginInput = {
  /** Password */
  password: Scalars['String'];
  /** Phone */
  phone: Scalars['String'];
};

export type Market = {
  __typename?: 'Market';
  /** balance */
  balance: Scalars['String'];
  /** id */
  id: Scalars['String'];
  /** Type of marketplace */
  marketplace: Marketplace;
  name: Scalars['String'];
  payload: Scalars['String'];
  role: UserRoleMarket;
};

export type MarketMinimal = {
  __typename?: 'MarketMinimal';
  id: Scalars['String'];
  marketplace: Marketplace;
  name: Scalars['String'];
};

export type MarketRole = {
  __typename?: 'MarketRole';
  /** market id */
  marketId: Scalars['String'];
  /** Store user roles */
  role: UserRoleMarket;
  /** user id */
  userId: Scalars['String'];
};

export type MarketSubscription = {
  __typename?: 'MarketSubscription';
  amountOfUses: Scalars['Int'];
  availableSubstriptionId: Scalars['String'];
  buyDate: Scalars['DateTime'];
  endConditionMaxUseAmount?: Maybe<Scalars['Int']>;
  endConditionTime?: Maybe<MarketSubscriptionDuration>;
  id: Scalars['String'];
  marketId: Scalars['String'];
  purchasePrice: Scalars['String'];
  status: MarketSubscriptionStatus;
  toolId: Scalars['String'];
};

export enum MarketSubscriptionDuration {
  Month = 'MONTH',
  Months3 = 'MONTHS3',
  Months6 = 'MONTHS6',
  Week = 'WEEK',
  Year = 'YEAR',
}

export enum MarketSubscriptionStatus {
  Active = 'Active',
  Closed = 'Closed',
  Expired = 'Expired',
}

export enum MarketSubscriptionType {
  Interval = 'INTERVAL',
  Quantitative = 'QUANTITATIVE',
}

export type MarketUser = {
  __typename?: 'MarketUser';
  createdAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  is2faEnabled?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  referalCode?: Maybe<Scalars['String']>;
  role: UserRoleMarket;
  updatedAt: Scalars['DateTime'];
  userRole: User_Role;
};

export enum Marketplace {
  Wildberries = 'WILDBERRIES',
}

export type Mutation = {
  __typename?: 'Mutation';
  createAvailableSubscription: AvailableSubscription;
  createDeposit: Deposit;
  createMarket: Market;
  createMarketRole: MarketRole;
  createMarketSubscription: MarketSubscription;
  createUser: User;
  login: Token;
  readAllNotifications: Scalars['Boolean'];
  readNotification: Scalars['Boolean'];
  requestTwoFactorAuthCode: Scalars['DateTime'];
  signUp: User;
  signUpAdmin: User;
  updateAvailableSubscription: AvailableSubscription;
  updateMarket: Scalars['Boolean'];
  updateMarketRole: MarketRole;
  updateUser: User;
  wbAdvertisingActivateSubscription: AdvertCampaignSubscription;
  wbAdvertisingAddAdvertCampaign: Scalars['String'];
  wbAdvertisingCreateCarouselAuctionAdvertCampaign: CreateAdvertCampaignOutput;
  wbAdvertisingCreateCatalogAdvertCampaign: CreateAdvertCampaignOutput;
  wbAdvertisingCreateSearchAdvertCampaign: CreateAdvertCampaignOutput;
  wbAdvertisingCreateSubscription: AdvertCampaignSubscription;
  wbAdvertisingDeactivateSubscription: AdvertCampaignSubscription;
  wbAdvertisingSetWords: AdvertCampaignWordsOutput;
  wbAdvertisingStartAdvertCampaignsSync: Scalars['String'];
  wbAdvertisingStartDistributedRequestToGetAdvertCampaignsStats: Scalars['String'];
  wbAdvertisingUpdateAdvertCampaign: Scalars['Boolean'];
  wbAutoFeedbacksCreateStopWords: Array<FeedbackStopWord>;
  wbAutoFeedbacksDeleteStopWords: Scalars['Boolean'];
  wbAutoFeedbacksUpdateStopWord: Scalars['Boolean'];
  wbAutofeedbacksActivateSubscription: FeedbackSubscription;
  wbAutofeedbacksCreateSubscription: FeedbackSubscription;
  wbAutofeedbacksCreateUpdatePhrase: Phrase;
  wbAutofeedbacksDeactivateSubscription: FeedbackSubscription;
  wbAutofeedbacksDeletePhrase: Scalars['Boolean'];
  wbAutofeedbacksUpdateAnswerStartDate: Scalars['Boolean'];
  wbAutofeedbacksUpdateArticle: Scalars['Boolean'];
  wbNicheAnalysisStartDistributedRequestForAnalyseCategories: Scalars['String'];
  wbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByName: Scalars['String'];
  wbNicheSelectionCreateNicheFilter: NicheAnalysisFilter;
  wbNicheSelectionDeleteNicheFilter: Scalars['Boolean'];
  wbNicheSelectionStartDistributedRequestForAnalyseCategories: Scalars['String'];
  wbNicheSelectionStartDistributedRequestForAnalyseCategoriesByName: Scalars['String'];
  wbProxyPageCreateProxyPage: ProxyPage;
  wbRenamerRenameProductCard: Scalars['Boolean'];
  wbStocksUpdateStocks: UpdateStocksOutput;
  wbUnitEconomySave: Scalars['Boolean'];
};

export type MutationCreateAvailableSubscriptionArgs = {
  input: CreateAvailiableSubscriptionInput;
};

export type MutationCreateDepositArgs = {
  input: CreateDepositInput;
};

export type MutationCreateMarketArgs = {
  input: CreateMarketInput;
};

export type MutationCreateMarketRoleArgs = {
  input: CreateMarketRoleInput;
};

export type MutationCreateMarketSubscriptionArgs = {
  input: CreateMarketSubscription;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationReadNotificationArgs = {
  notificationId: Scalars['String'];
};

export type MutationRequestTwoFactorAuthCodeArgs = {
  requestor: Scalars['String'];
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationSignUpAdminArgs = {
  input: SignUpInputAdmin;
};

export type MutationUpdateAvailableSubscriptionArgs = {
  input: UpdateAvailiableSubscriptionInput;
};

export type MutationUpdateMarketArgs = {
  input: UpdateMarketInput;
};

export type MutationUpdateMarketRoleArgs = {
  input: UpdateMarketRoleInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationWbAdvertisingAddAdvertCampaignArgs = {
  advertId: Scalars['Int'];
};

export type MutationWbAdvertisingCreateCarouselAuctionAdvertCampaignArgs = {
  input: CreateCarouselAuctionAdvertCampaignInput;
};

export type MutationWbAdvertisingCreateCatalogAdvertCampaignArgs = {
  input: CreateCatalogAdvertCampaignInput;
};

export type MutationWbAdvertisingCreateSearchAdvertCampaignArgs = {
  input: CreateSearchAdvertCampaignInput;
};

export type MutationWbAdvertisingSetWordsArgs = {
  input: SetAdvertCampaignWordsInput;
};

export type MutationWbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsArgs = {
  input: GetAdvertCampaignsStatsInput;
};

export type MutationWbAdvertisingUpdateAdvertCampaignArgs = {
  input: UpdateAdvertCampaignInput;
};

export type MutationWbAutoFeedbacksCreateStopWordsArgs = {
  input: Array<CreateFeedbackStopWordInput>;
};

export type MutationWbAutoFeedbacksDeleteStopWordsArgs = {
  feedbackStopWordIds: Array<Scalars['String']>;
};

export type MutationWbAutoFeedbacksUpdateStopWordArgs = {
  input: UpdateFeedbackStopWordInput;
};

export type MutationWbAutofeedbacksCreateSubscriptionArgs = {
  feedbackAnswerStartDate?: InputMaybe<Scalars['DateTime']>;
};

export type MutationWbAutofeedbacksCreateUpdatePhraseArgs = {
  input: CreateUpdatePhraseInput;
};

export type MutationWbAutofeedbacksDeletePhraseArgs = {
  productGrade: Scalars['Int'];
};

export type MutationWbAutofeedbacksUpdateAnswerStartDateArgs = {
  feedbackAnswerStartDate: Scalars['DateTime'];
};

export type MutationWbAutofeedbacksUpdateArticleArgs = {
  input: UpdateFeedbackArticleInput;
};

export type MutationWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesArgs = {
  input: NicheAnalysisInput;
};

export type MutationWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameArgs = {
  input: NicheAnalysisByNameInput;
};

export type MutationWbNicheSelectionCreateNicheFilterArgs = {
  input: CreateNicheAnalysisFilterInput;
};

export type MutationWbNicheSelectionDeleteNicheFilterArgs = {
  nicheFilterId: Scalars['String'];
};

export type MutationWbNicheSelectionStartDistributedRequestForAnalyseCategoriesArgs = {
  input: NicheAnalysisInput;
};

export type MutationWbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameArgs = {
  input: NicheAnalysisByNameInput;
};

export type MutationWbProxyPageCreateProxyPageArgs = {
  input: CreateProxyPageInput;
};

export type MutationWbRenamerRenameProductCardArgs = {
  input: RenameCardInput;
};

export type MutationWbStocksUpdateStocksArgs = {
  input: UpdateStocksInput;
};

export type MutationWbUnitEconomySaveArgs = {
  input: SaveUnitEconomyInput;
};

export type NicheAnalysisByNameInput = {
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type NicheAnalysisDistributedRequestOutput = {
  __typename?: 'NicheAnalysisDistributedRequestOutput';
  errors: Array<DistributedRequestError>;
  marketId: Scalars['String'];
  output?: Maybe<Array<NicheAnalysisOutput>>;
  progress: Scalars['Float'];
  requestId: Scalars['String'];
  requestStatus: DistributedRequestStatus;
};

export type NicheAnalysisFilter = {
  __typename?: 'NicheAnalysisFilter';
  averageCommentsAmount?: Maybe<NicheAnalysisFilterFromToObjectType>;
  averageMedianCheck?: Maybe<NicheAnalysisFilterFromToObjectType>;
  averageRevenuePerOneProductWithSales?: Maybe<NicheAnalysisFilterFromToObjectType>;
  id: Scalars['String'];
  marketId: Scalars['String'];
  name: Scalars['String'];
  numberOfProducts?: Maybe<NicheAnalysisFilterFromToObjectType>;
  numberOfProductsWithRevenueGreaterThanOneMillion?: Maybe<NicheAnalysisFilterFromToObjectType>;
  numberOfSellers?: Maybe<NicheAnalysisFilterFromToObjectType>;
  productSaleProbability?: Maybe<NicheAnalysisFilterFromToObjectType>;
  purchaseProductsBudget?: Maybe<NicheAnalysisFilterFromToObjectType>;
  revenueFromAllProduct?: Maybe<NicheAnalysisFilterFromToObjectType>;
  selfPurchaseProductBudget?: Maybe<NicheAnalysisFilterFromToObjectType>;
  sellerWithSalesProbability?: Maybe<NicheAnalysisFilterFromToObjectType>;
  top3SellerAverageComments?: Maybe<NicheAnalysisFilterFromToObjectType>;
  top3SellerAverageRevenue?: Maybe<NicheAnalysisFilterFromToObjectType>;
  top10SellerAverageComments?: Maybe<NicheAnalysisFilterFromToObjectType>;
  top10SellerAverageRevenue?: Maybe<NicheAnalysisFilterFromToObjectType>;
  top30SellerAverageComments?: Maybe<NicheAnalysisFilterFromToObjectType>;
  top30SellerAverageRevenue?: Maybe<NicheAnalysisFilterFromToObjectType>;
};

export type NicheAnalysisFilterFromToInputType = {
  from?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['Float']>;
};

export type NicheAnalysisFilterFromToObjectType = {
  __typename?: 'NicheAnalysisFilterFromToObjectType';
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

export type NicheAnalysisInput = {
  categoryPaths: Array<Scalars['String']>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type NicheAnalysisOutput = {
  __typename?: 'NicheAnalysisOutput';
  category: NicheCategory;
  stats: NicheStatistics;
};

export type NicheCategory = {
  __typename?: 'NicheCategory';
  name: Scalars['String'];
  path: Scalars['String'];
  url: Scalars['String'];
};

export type NicheCategoryWithParent = {
  __typename?: 'NicheCategoryWithParent';
  name: Scalars['String'];
  parentPath?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  url: Scalars['String'];
};

export type NicheProductStats = {
  __typename?: 'NicheProductStats';
  article: Scalars['Int'];
  brand: Scalars['String'];
  cardUrl: Scalars['String'];
  category: Scalars['String'];
  clientPrice: Scalars['Float'];
  color: Scalars['String'];
  comments: Scalars['Int'];
  daysInStock: Scalars['Int'];
  name: Scalars['String'];
  pictureUrl: Scalars['String'];
  position: Scalars['Int'];
  price: Scalars['Float'];
  rating: Scalars['Int'];
  revenue: Scalars['Float'];
  sales: Scalars['Int'];
  salesTrend: Array<Scalars['Int']>;
  seller: Scalars['String'];
  stock: Scalars['Int'];
};

export type NicheProductsAnalysisInput = {
  categoryPath: Scalars['String'];
  endDate: Scalars['DateTime'];
  orderBy?: InputMaybe<NicheProductsAnalysisOrderBy>;
  skip: Scalars['Int'];
  startDate: Scalars['DateTime'];
  take: Scalars['Int'];
};

export type NicheProductsAnalysisOrderBy = {
  article?: InputMaybe<PaginationOrder>;
  brand?: InputMaybe<PaginationOrder>;
  cardUrl?: InputMaybe<PaginationOrder>;
  category?: InputMaybe<PaginationOrder>;
  clientPrice?: InputMaybe<PaginationOrder>;
  color?: InputMaybe<PaginationOrder>;
  comments?: InputMaybe<PaginationOrder>;
  daysInStock?: InputMaybe<PaginationOrder>;
  name?: InputMaybe<PaginationOrder>;
  position?: InputMaybe<PaginationOrder>;
  price?: InputMaybe<PaginationOrder>;
  rating?: InputMaybe<PaginationOrder>;
  revenue?: InputMaybe<PaginationOrder>;
  sales?: InputMaybe<PaginationOrder>;
  seller?: InputMaybe<PaginationOrder>;
  stock?: InputMaybe<PaginationOrder>;
};

export type NicheProductsAnalysisOutput = {
  __typename?: 'NicheProductsAnalysisOutput';
  category: NicheCategory;
  count: Scalars['Int'];
  productStats: Array<NicheProductStats>;
};

export type NicheStatValueArray = {
  __typename?: 'NicheStatValueArray';
  rating: Scalars['Float'];
  value?: Maybe<Array<Scalars['Float']>>;
};

export type NicheStatValueNumber = {
  __typename?: 'NicheStatValueNumber';
  rating: Scalars['Float'];
  value?: Maybe<Scalars['Float']>;
};

export type NicheStatistics = {
  __typename?: 'NicheStatistics';
  averageCommentsAmount: NicheStatValueNumber;
  averageMedianCheck: NicheStatValueNumber;
  averageRevenuePerOneProductWithSales: NicheStatValueNumber;
  monopolization: NicheStatValueArray;
  numberOfProducts: NicheStatValueNumber;
  numberOfProductsWithRevenueGreaterThanOneMillion: NicheStatValueNumber;
  numberOfSellers: NicheStatValueNumber;
  productSaleProbability: NicheStatValueNumber;
  purchaseProductsBudget: NicheStatValueNumber;
  revenueFromAllProduct: NicheStatValueNumber;
  salesTrendForTheLastMonth: NicheStatValueArray;
  selfPurchaseProductBudget: NicheStatValueNumber;
  sellerWithSalesProbability: NicheStatValueNumber;
  top3SellerAverageComments: NicheStatValueNumber;
  top3SellerAverageRevenue: NicheStatValueNumber;
  top10SellerAverageComments: NicheStatValueNumber;
  top10SellerAverageRevenue: NicheStatValueNumber;
  top30SellerAverageComments: NicheStatValueNumber;
  top30SellerAverageRevenue: NicheStatValueNumber;
};

export type NmIdsGroup = {
  nmIds: Array<Scalars['Int']>;
};

export enum NotificationImportance {
  Info = 'Info',
  Serious = 'Serious',
  Warning = 'Warning',
}

export enum NotificationInitiatorType {
  WbTool = 'WBTool',
}

export enum NotificationType {
  AutoDepositFailed = 'AutoDepositFailed',
  AutoDepositSuccessful = 'AutoDepositSuccessful',
  BidsUpdateFailed = 'BidsUpdateFailed',
  BidsUpdateSuccessful = 'BidsUpdateSuccessful',
  General = 'General',
}

export type NotificationUi = {
  __typename?: 'NotificationUI';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  importance: NotificationImportance;
  initiatorId: Scalars['String'];
  initiatorType: NotificationInitiatorType;
  isRead: Scalars['Boolean'];
  market?: Maybe<MarketMinimal>;
  notificationType: NotificationType;
  params: Scalars['String'];
  targetId?: Maybe<Scalars['String']>;
  targetType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum PaginationOrder {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type Phrase = {
  __typename?: 'Phrase';
  farewellPart?: Maybe<Scalars['String']>;
  greetingsPart?: Maybe<Scalars['String']>;
  mainPart?: Maybe<Scalars['String']>;
  marketId: Scalars['String'];
  productGrade: Scalars['Int'];
  recommendationsPart?: Maybe<Scalars['String']>;
  thanksPart?: Maybe<Scalars['String']>;
};

export type PopularRequest = {
  __typename?: 'PopularRequest';
  monthlyFrequency: Scalars['Int'];
  prioritizedCategory?: Maybe<Scalars['Int']>;
  productAdvertBids: Array<ProductAdvertBid>;
  requestText: Scalars['String'];
};

export type PopularRequestsFilterBy = {
  requestText?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['Int']>;
};

export type PopularRequestsOrderBy = {
  monthlyFrequency?: InputMaybe<PaginationOrder>;
  place1?: InputMaybe<PaginationOrder>;
  place2?: InputMaybe<PaginationOrder>;
  place3?: InputMaybe<PaginationOrder>;
  place4?: InputMaybe<PaginationOrder>;
  place5?: InputMaybe<PaginationOrder>;
  prioritizedCategory?: InputMaybe<PaginationOrder>;
  requestText?: InputMaybe<PaginationOrder>;
};

export type ProductAdvertBid = {
  __typename?: 'ProductAdvertBid';
  advertBid: Scalars['Float'];
  cardUrl: Scalars['String'];
  categoryId: Scalars['Int'];
  pictureUrl: Scalars['String'];
  sku: Scalars['Int'];
};

export type ProductCard = {
  __typename?: 'ProductCard';
  cardUrl: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  vendorCode: Scalars['String'];
};

export type ProxyPage = {
  __typename?: 'ProxyPage';
  createdAt: Scalars['DateTime'];
  fullLink: Scalars['String'];
  gtmCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  marketId: Scalars['String'];
  productNm: Scalars['Int'];
  shortLink: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  utmCampaign: Scalars['String'];
  utmContent?: Maybe<Scalars['String']>;
  utmMedium: Scalars['String'];
  utmSource: Scalars['String'];
  utmTerm?: Maybe<Scalars['String']>;
  views: Scalars['Int'];
  yandexMetricaCode?: Maybe<Scalars['String']>;
};

export type ProxyPageBrand = {
  __typename?: 'ProxyPageBrand';
  id: Scalars['Int'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type ProxyPageFeedback = {
  __typename?: 'ProxyPageFeedback';
  createdDate: Scalars['DateTime'];
  productValuation: Scalars['Int'];
  text: Scalars['String'];
  userName: Scalars['String'];
  votesMinuses?: Maybe<Scalars['Int']>;
  votesPluses?: Maybe<Scalars['Int']>;
};

export type ProxyPageFeedbacksInfo = {
  __typename?: 'ProxyPageFeedbacksInfo';
  feedbacks: Array<ProxyPageFeedback>;
  feedbacksCount: Scalars['Int'];
  photoCount: Scalars['Int'];
  valuation: Scalars['Float'];
};

export type ProxyPageProductComb = {
  __typename?: 'ProxyPageProductComb';
  name: Scalars['String'];
  pageUrl: Scalars['String'];
};

export type ProxyPageProductInfo = {
  __typename?: 'ProxyPageProductInfo';
  brand: ProxyPageBrand;
  brandName: Scalars['String'];
  cardPhotos: Array<Scalars['String']>;
  chrtIds: Array<Scalars['Int']>;
  combsSitePath: Array<ProxyPageProductComb>;
  description: Scalars['String'];
  feedbackPhotos: Array<Scalars['String']>;
  feedbacksCount: Scalars['Int'];
  feedbacksInfo: ProxyPageFeedbacksInfo;
  imtId: Scalars['Int'];
  nmId: Scalars['Int'];
  options: Array<ProxyPageProductOption>;
  ordersCount: Scalars['Int'];
  priceU: Scalars['Int'];
  productName: Scalars['String'];
  questionsCount: Scalars['Int'];
  rating: Scalars['Int'];
  salePriceU: Scalars['Int'];
  seller: ProxyPageSeller;
};

export type ProxyPageProductOption = {
  __typename?: 'ProxyPageProductOption';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ProxyPageSeller = {
  __typename?: 'ProxyPageSeller';
  feedbacksCount?: Maybe<Scalars['Int']>;
  supplierId: Scalars['Int'];
  trademark: Scalars['String'];
  valuation?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  availableSubscriptions: Array<AvailableSubscription>;
  depositFindbyId: Deposit;
  getMarketSubscriptions: Array<MarketSubscription>;
  getNotifications: GetNotificationsOutput;
  getUser: User;
  marketFindAllForUser: Array<Market>;
  marketFindbyId: Market;
  marketUsersByRole: Array<MarketUser>;
  tools: Array<Tool>;
  wbAdvertisingGetAdvertCampaign: AdvertCampaign;
  wbAdvertisingGetAdvertCampaignStatsFilters: AdvertCampaignFiltersOutput;
  wbAdvertisingGetAdvertCampaigns: GetAdvertCampaignsOutput;
  wbAdvertisingGetBids: Array<Bid>;
  wbAdvertisingGetCatalogs: GetCatalogsOutput;
  wbAdvertisingGetDistributedRequestForAdvertCampaignsStats: AdvertCampaignsStatsDistributedRequest;
  wbAdvertisingGetPopularRequests: GetPopularRequestsOutput;
  wbAdvertisingGetSubjectsAndProducts: GetSubjectsAndProductsOutput;
  wbAdvertisingGetSubscription: AdvertCampaignSubscription;
  wbAdvertisingGetSyncRequest: AdvertCampaignSyncRequest;
  wbAdvertisingGetTrendingSearches: Array<TrendingSearch>;
  wbAdvertisingGetWords: AdvertCampaignWordsOutput;
  wbAutoFeedbacksGetArticle: FeedbackArticle;
  wbAutoFeedbacksGetStopWords: Array<FeedbackStopWord>;
  wbAutofeedbacksGetAnswers: Array<FeedbackAnswer>;
  wbAutofeedbacksGetArticles: Array<FeedbackArticle>;
  wbAutofeedbacksGetPhrases: Array<Phrase>;
  wbAutofeedbacksGetSubscription: FeedbackSubscription;
  wbFeedbackParserGetCardFeedbacks: GetCardFeedbacksOutput;
  wbNicheAnalysisAnalyseProducts: NicheProductsAnalysisOutput;
  wbNicheAnalysisFindCategories: Array<NicheCategory>;
  wbNicheAnalysisGetAllCategories: Array<NicheCategoryWithParent>;
  wbNicheAnalysisGetCategories: Array<NicheCategory>;
  wbNicheAnalysisGetCategoriesLowest: GetNicheCategoriesOutput;
  wbNicheAnalysisGetCategoriesOneLevelLower: GetNicheCategoriesOutput;
  wbNicheAnalysisGetDistributedRequest: NicheAnalysisDistributedRequestOutput;
  wbNicheAnalysisGetTopLevelCategories: Array<NicheCategory>;
  wbNicheSelectionAnalyseProducts: NicheProductsAnalysisOutput;
  wbNicheSelectionGetDistributedRequest: NicheAnalysisDistributedRequestOutput;
  wbNicheSelectionGetNicheFilters: Array<NicheAnalysisFilter>;
  wbProxyPageGetProductInfoByLink: ProxyPageProductInfo;
  wbProxyPageGetProxyPageByLink: ProxyPage;
  wbProxyPageGetProxyPages: Array<ProxyPage>;
  wbRenamerGetProductCard: ProductCard;
  wbStocksGetStocks: GetStocksOutput;
  wbUnitEconomyGet: GetUnitEconomyOutput;
  wbUnitEconomyGetSavedArticles: Array<UnitEconomySavedArticle>;
  wbWarehousesGet: Array<WarehouseOutput>;
  whoami: User;
};

export type QueryAvailableSubscriptionsArgs = {
  toolId: Scalars['String'];
};

export type QueryDepositFindbyIdArgs = {
  id: Scalars['String'];
};

export type QueryGetMarketSubscriptionsArgs = {
  input: GetMarketSubscriptionsInput;
};

export type QueryGetNotificationsArgs = {
  input?: InputMaybe<GetNotificationsInput>;
};

export type QueryGetUserArgs = {
  id: Scalars['String'];
};

export type QueryMarketFindAllForUserArgs = {
  role?: InputMaybe<UserRoleMarket>;
};

export type QueryMarketFindbyIdArgs = {
  id: Scalars['String'];
};

export type QueryMarketUsersByRoleArgs = {
  input: GetMarketUsersByRoleInput;
};

export type QueryWbAdvertisingGetAdvertCampaignArgs = {
  advertCampaignId: Scalars['String'];
};

export type QueryWbAdvertisingGetAdvertCampaignStatsFiltersArgs = {
  advertCampaignIds: Array<Scalars['String']>;
};

export type QueryWbAdvertisingGetAdvertCampaignsArgs = {
  input: GetAdvertCampaignsInput;
};

export type QueryWbAdvertisingGetBidsArgs = {
  input: GetBidsInput;
};

export type QueryWbAdvertisingGetCatalogsArgs = {
  input: GetCatalogsInput;
};

export type QueryWbAdvertisingGetDistributedRequestForAdvertCampaignsStatsArgs = {
  distributedRequestId: Scalars['String'];
};

export type QueryWbAdvertisingGetPopularRequestsArgs = {
  input: GetPopularRequestsInput;
};

export type QueryWbAdvertisingGetSyncRequestArgs = {
  syncRequestId: Scalars['String'];
};

export type QueryWbAdvertisingGetTrendingSearchesArgs = {
  input: GetTrendingSearchesInput;
};

export type QueryWbAdvertisingGetWordsArgs = {
  advertCampaignId: Scalars['String'];
};

export type QueryWbAutoFeedbacksGetArticleArgs = {
  article: Scalars['Int'];
};

export type QueryWbAutofeedbacksGetAnswersArgs = {
  input?: InputMaybe<GetFeedbackAnswersInput>;
};

export type QueryWbFeedbackParserGetCardFeedbacksArgs = {
  article: Scalars['Int'];
};

export type QueryWbNicheAnalysisAnalyseProductsArgs = {
  input: NicheProductsAnalysisInput;
};

export type QueryWbNicheAnalysisFindCategoriesArgs = {
  name: Scalars['String'];
};

export type QueryWbNicheAnalysisGetCategoriesLowestArgs = {
  categoryPath: Scalars['String'];
};

export type QueryWbNicheAnalysisGetCategoriesOneLevelLowerArgs = {
  categoryPath: Scalars['String'];
};

export type QueryWbNicheAnalysisGetDistributedRequestArgs = {
  distributedRequestId: Scalars['String'];
};

export type QueryWbNicheSelectionAnalyseProductsArgs = {
  input: NicheProductsAnalysisInput;
};

export type QueryWbNicheSelectionGetDistributedRequestArgs = {
  distributedRequestId: Scalars['String'];
};

export type QueryWbProxyPageGetProductInfoByLinkArgs = {
  input: GetProxyPageInput;
};

export type QueryWbProxyPageGetProxyPageByLinkArgs = {
  input: GetProxyPageInput;
};

export type QueryWbRenamerGetProductCardArgs = {
  vendorCode: Scalars['String'];
};

export type QueryWbStocksGetStocksArgs = {
  input: GetStocksInput;
};

export type QueryWbUnitEconomyGetArgs = {
  input: GetUnitEconomyInput;
};

export type QueryWbWarehousesGetArgs = {
  date: Scalars['DateTime'];
};

export type RenameCardInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  vendorCode: Scalars['String'];
};

export type SaveUnitEconomyInput = {
  article: Scalars['Int'];
  /** Средний процент выкупа, % */
  buybackPercentage?: InputMaybe<Scalars['Float']>;
  /** Скидка от цены продажи до скидки ВБ % */
  discountBeforeDiscountWb?: InputMaybe<Scalars['Int']>;
  /** Высота */
  height?: InputMaybe<Scalars['Float']>;
  /** Длина */
  length?: InputMaybe<Scalars['Float']>;
  /** Бюджет продвижения */
  promoBudget?: InputMaybe<Scalars['Float']>;
  /** Закупочная цена, руб */
  purchasePrice?: InputMaybe<Scalars['Float']>;
  /** Объём закупки, шт */
  purchaseVolume?: InputMaybe<Scalars['Int']>;
  /** Объём продаж, шт */
  saleVolume?: InputMaybe<Scalars['Int']>;
  /** Услуги ФФ, руб */
  servicesFF?: InputMaybe<Scalars['Float']>;
  /** Целевая наценка, % */
  targetMargin?: InputMaybe<Scalars['Int']>;
  /** Налоги, % */
  tax?: InputMaybe<Scalars['Int']>;
  /** Желаемая прибыль, руб */
  wantedProfit?: InputMaybe<Scalars['Float']>;
  /** Вес в килограммах */
  weight?: InputMaybe<Scalars['Float']>;
  /** Ширина */
  width?: InputMaybe<Scalars['Float']>;
};

export type SetAdvertCampaignWordsInput = {
  advertCampaignId: Scalars['String'];
  excludedPhraseWords?: InputMaybe<Array<Scalars['String']>>;
  excludedStrongWords?: InputMaybe<Array<Scalars['String']>>;
  excludedWords?: InputMaybe<Array<Scalars['String']>>;
  plusWords?: InputMaybe<Array<Scalars['String']>>;
};

export type SignUpInput = {
  /** otp code */
  code: Scalars['String'];
  /** First Name */
  firstName?: InputMaybe<Scalars['String']>;
  /** Last Name */
  lastName?: InputMaybe<Scalars['String']>;
  /** Password */
  password: Scalars['String'];
  /** Patronymic */
  patronymic?: InputMaybe<Scalars['String']>;
  /** Phone number */
  phoneNumber: Scalars['String'];
};

export type SignUpInputAdmin = {
  /** First Name */
  firstName?: InputMaybe<Scalars['String']>;
  /** Last Name */
  lastName?: InputMaybe<Scalars['String']>;
  /** Password */
  password: Scalars['String'];
  /** Patronymic */
  patronymic?: InputMaybe<Scalars['String']>;
  /** Phone number */
  phoneNumber: Scalars['String'];
};

export type Stock = {
  __typename?: 'Stock';
  article: Scalars['String'];
  barcode: Scalars['String'];
  barcodes: Array<Scalars['String']>;
  brand: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['String'];
  stock: Scalars['Int'];
  subject: Scalars['String'];
  warehouseId: Scalars['Int'];
  warehouseName: Scalars['String'];
};

export type Subject = {
  __typename?: 'Subject';
  id: Scalars['Int'];
  name: Scalars['String'];
  nmsCount: Scalars['Int'];
};

export type SupplierProductInputType = {
  brand: IdNameInputType;
  kind: IdNameInputType;
  name: Scalars['String'];
  nmId: Scalars['Int'];
  subject: IdNameInputType;
};

export type SupplierProductObjectType = {
  __typename?: 'SupplierProductObjectType';
  brand: IdNameObjectType;
  kind: IdNameObjectType;
  name: Scalars['String'];
  nmId: Scalars['Int'];
  pictureUrl: Scalars['String'];
  subject: IdNameObjectType;
};

export type Token = {
  __typename?: 'Token';
  jwtToken: Scalars['String'];
};

export type Tool = {
  __typename?: 'Tool';
  /** Instrument description */
  description: Scalars['String'];
  /** id */
  id: Scalars['String'];
  isBeta: Scalars['Boolean'];
  /** Instrument name */
  name: Scalars['String'];
};

export type TrendingSearch = {
  __typename?: 'TrendingSearch';
  requestCount: Scalars['Int'];
  text: Scalars['String'];
};

export enum User_Role {
  Customer = 'CUSTOMER',
  Operator = 'OPERATOR',
  Superadmin = 'SUPERADMIN',
}

export type UnitEconomySavedArticle = {
  __typename?: 'UnitEconomySavedArticle';
  article: Scalars['Float'];
  name: Scalars['String'];
};

export type UpdateAdvertCampaignInput = {
  advertCampaignId: Scalars['String'];
  autoDepositAmount?: InputMaybe<Scalars['Int']>;
  autoDepositLimit?: InputMaybe<Scalars['Int']>;
  budgetDailyMax?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isAutoDepositEnabled?: InputMaybe<Scalars['Boolean']>;
  isBudgetAllocationEnabled?: InputMaybe<Scalars['Boolean']>;
  isWordsSyncEnabled?: InputMaybe<Scalars['Boolean']>;
  places?: InputMaybe<Array<UpdateAdvertCampaignPlaceInput>>;
};

export type UpdateAdvertCampaignPlaceInput = {
  bidderCpmMax?: InputMaybe<Scalars['Int']>;
  bidderKeyword?: InputMaybe<Scalars['String']>;
  bidderPosition?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  intervals?: InputMaybe<Array<AdvertCampaignPlacementIntervalInputType>>;
  isBidderActive?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateAvailiableSubscriptionInput = {
  id: Scalars['String'];
  marketplace: Marketplace;
  maxTime?: InputMaybe<MarketSubscriptionDuration>;
  maxUseAmount?: InputMaybe<Scalars['Int']>;
  price: Scalars['String'];
  status: AvailableMarketSubscriptionStatus;
  toolId: Scalars['String'];
  type: MarketSubscriptionType;
};

export type UpdateFeedbackArticleInput = {
  article: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  recommendedArticles?: InputMaybe<Array<Scalars['Int']>>;
};

export type UpdateFeedbackStopWordInput = {
  answer?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  stopWord?: InputMaybe<Scalars['String']>;
};

export type UpdateMarketInput = {
  balance?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['String']>;
};

export type UpdateMarketRoleInput = {
  /** Market id */
  marketId: Scalars['String'];
  /** User id */
  phone: Scalars['String'];
  /** Market user role */
  role: UserRoleMarket;
};

export type UpdateStocksError = {
  __typename?: 'UpdateStocksError';
  barcode: Scalars['String'];
  err: Scalars['String'];
};

export type UpdateStocksInput = {
  lessThanValue: Scalars['Int'];
  newStock: Scalars['Int'];
};

export type UpdateStocksOutput = {
  __typename?: 'UpdateStocksOutput';
  errors?: Maybe<Array<UpdateStocksError>>;
  stocks?: Maybe<Array<Stock>>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  is2faEnabled?: InputMaybe<Scalars['Boolean']>;
  isBanned?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  patronymic?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  referalCode?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<User_Role>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  is2faEnabled?: Maybe<Scalars['Boolean']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  referalCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userRole: User_Role;
};

export enum UserRoleMarket {
  Admin = 'ADMIN',
  Analyst = 'ANALYST',
  Operator = 'OPERATOR',
}

export type WarehouseOutput = {
  __typename?: 'WarehouseOutput';
  /** Добавочная цена доставки при привышении размеров товара */
  additDelivery: Scalars['Float'];
  /** Дополнительная цена содержания на складе при привышении размеров. 0 - бесплатно. -1 - недоступно */
  additStorage: Scalars['Float'];
  /** Базовая цена доставки, не доставляют */
  defaultDelivery: Scalars['Float'];
  /** Базовая цена содержания на складе. 0 - бесплатно. -1 - недоступно */
  defaultStorage: Scalars['Float'];
  /** Название товара */
  name: Scalars['String'];
};
