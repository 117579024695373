// @mui
import { Divider, Container, Typography, Box, Link, Grid, Stack } from '@mui/material';
import Logo from '../../components/Logo';
import useLocales from '../../hooks/useLocales';
// routes

// ----------------------------------------------------------------------

interface FooterItem {
  name: string;
  href?: string;
}

interface FooterItems {
  headline: string;
  children: FooterItem[];
}

export default function MainFooter() {
  const { t } = useLocales();

  const ITEMS: FooterItems[] = [
    {
      headline: t('landing.main.footer.documents'),
      children: [
        { name: t('landing.main.footer.agreement'), href: '/docs/user_agreement.pdf' },
        { name: t('landing.main.footer.personalData'), href: '/docs/personal_policy.pdf' },
      ],
    },
    {
      headline: t('landing.main.footer.contacts'),
      children: [
        { name: 'info.gunit@mail.ru', href: 'mailto:info.gunit@mail.ru' },
        { name: t('landing.main.footer.companyInfo') },
      ],
    },
  ];

  return (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 5 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={12} md={7} sx={{ mt: 2 }}>
            <Stack spacing={12} direction={{ xs: 'column', md: 'row' }}>
              {ITEMS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => {
                    if (link.href)
                      return (
                        <Link
                          key={link.name}
                          href={link.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          color="inherit"
                          variant="body2"
                        >
                          {link.name}
                        </Link>
                      );
                    return (
                      <Typography key={link.name} color="inherit" variant="body2">
                        {link.name}
                      </Typography>
                    );
                  })}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 10,
            pb: 5,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {t('landing.main.footer.c2022')}
        </Typography>
      </Container>
    </Box>
  );
}
