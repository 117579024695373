import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const CatalogFragmentFragmentDoc = gql`
  fragment CatalogFragment on Catalog {
    id
    name
    path
    isPremium
    url
  }
`;
export const CatalogsGroupFragmentFragmentDoc = gql`
  fragment CatalogsGroupFragment on CatalogsGroup {
    catalogs {
      ...CatalogFragment
    }
  }
  ${CatalogFragmentFragmentDoc}
`;
export const CreateAdvertCampaignOutputFragmentFragmentDoc = gql`
  fragment CreateAdvertCampaignOutputFragment on CreateAdvertCampaignOutput {
    id
    name
    type
    status
  }
`;
export const SubjectFragmentFragmentDoc = gql`
  fragment SubjectFragment on Subject {
    id
    name
    nmsCount
  }
`;
export const IdNameObjectTypeFragmentFragmentDoc = gql`
  fragment IdNameObjectTypeFragment on IdNameObjectType {
    id
    name
  }
`;
export const SupplierProductObjectTypeFragmentFragmentDoc = gql`
  fragment SupplierProductObjectTypeFragment on SupplierProductObjectType {
    nmId
    name
    subject {
      ...IdNameObjectTypeFragment
    }
    brand {
      ...IdNameObjectTypeFragment
    }
    kind {
      ...IdNameObjectTypeFragment
    }
    pictureUrl
  }
  ${IdNameObjectTypeFragmentFragmentDoc}
`;
export const GetSubjectsAndProductsOutputFramentFragmentDoc = gql`
  fragment GetSubjectsAndProductsOutputFrament on GetSubjectsAndProductsOutput {
    subjects {
      ...SubjectFragment
    }
    products {
      ...SupplierProductObjectTypeFragment
    }
  }
  ${SubjectFragmentFragmentDoc}
  ${SupplierProductObjectTypeFragmentFragmentDoc}
`;
export const TrendingSearchFragmentFragmentDoc = gql`
  fragment TrendingSearchFragment on TrendingSearch {
    text
    requestCount
  }
`;
export const AdvertCampaignBidderValueFragmentFragmentDoc = gql`
  fragment AdvertCampaignBidderValueFragment on AdvertCampaignBidderValue {
    placeId
    value
  }
`;
export const AdvertCampaignProductFragmentFragmentDoc = gql`
  fragment AdvertCampaignProductFragment on AdvertCampaignProduct {
    id
    name
    brandName
    vendorCode
    categoryIds
    cardUrl
    pictureUrl
  }
`;
export const AdvertCampaignCarouselPlaceFragmentFragmentDoc = gql`
  fragment AdvertCampaignCarouselPlaceFragment on AdvertCampaignCarouselPlace {
    id
    price
    isBidderActive
    bidderPosition
    bidderCpmMax
    subjectName
    kindName
    intervals {
      begin
      end
    }
  }
`;
export const AdvertCampaignCatalogPlaceFragmentFragmentDoc = gql`
  fragment AdvertCampaignCatalogPlaceFragment on AdvertCampaignCatalogPlace {
    id
    price
    isBidderActive
    bidderPosition
    bidderCpmMax
    menuName
    menuPath
    menuUrl
    subjectName
    intervals {
      begin
      end
    }
  }
`;
export const AdvertCampaignSearchPlaceFragmentFragmentDoc = gql`
  fragment AdvertCampaignSearchPlaceFragment on AdvertCampaignSearchPlace {
    id
    price
    isBidderActive
    bidderPosition
    bidderCpmMax
    keyword
    bidderKeyword
    intervals {
      begin
      end
    }
  }
`;
export const AdvertCampaignFragmentFragmentDoc = gql`
  fragment AdvertCampaignFragment on AdvertCampaign {
    id
    wbId
    wbStatus
    isActive
    marketId
    name
    type
    budgetDailyMax
    budgetTotal
    isArchived
    cpm
    ctr
    cpc
    startDate
    averageBid
    maxBid
    isBudgetAllocationEnabled
    biddersCpmMax {
      ...AdvertCampaignBidderValueFragment
    }
    holdingPositions {
      ...AdvertCampaignBidderValueFragment
    }
    isAutoDepositEnabled
    autoDepositAmount
    autoDepositLimit
    isWordsSyncEnabled
    products {
      ...AdvertCampaignProductFragment
    }
    carouselPlaces {
      ...AdvertCampaignCarouselPlaceFragment
    }
    catalogPlaces {
      ...AdvertCampaignCatalogPlaceFragment
    }
    searchPlaces {
      ...AdvertCampaignSearchPlaceFragment
    }
  }
  ${AdvertCampaignBidderValueFragmentFragmentDoc}
  ${AdvertCampaignProductFragmentFragmentDoc}
  ${AdvertCampaignCarouselPlaceFragmentFragmentDoc}
  ${AdvertCampaignCatalogPlaceFragmentFragmentDoc}
  ${AdvertCampaignSearchPlaceFragmentFragmentDoc}
`;
export const AdvertCampaignArticleStatsFragmentFragmentDoc = gql`
  fragment AdvertCampaignArticleStatsFragment on AdvertCampaignArticleStats {
    views
    clicks
    frequency
    uniqueUsers
    ctr
    cpc
    sum
    atbs
    orders
    cr
    cac
    date
    key
    article
  }
`;
export const AdvertCampaignGeneralStatsFragmentFragmentDoc = gql`
  fragment AdvertCampaignGeneralStatsFragment on AdvertCampaignGeneralStats {
    key
    views
    clicks
    frequency
    ctr
    cpc
    duration
    sum
  }
`;
export const AdvertCampaignBidChangeFragmentFragmentDoc = gql`
  fragment AdvertCampaignBidChangeFragment on AdvertCampaignBidChange {
    createdDate
    placeId
    name
    newBid
  }
`;
export const AdvertCampaignMetricsFragmentFragmentDoc = gql`
  fragment AdvertCampaignMetricsFragment on AdvertCampaignMetrics {
    views
    clicks
    frequency
    uniqueUsers
    ctr
    cpc
    sum
    atbs
    orders
    cr
    cac
  }
`;
export const AdvertCampaignAnalyticsStatsFragmentFragmentDoc = gql`
  fragment AdvertCampaignAnalyticsStatsFragment on AdvertCampaignAnalyticsStats {
    date
    views
    clicks
    ctrPercent
    cpc
    advertBudget
    updateBudget
    averageCheck
    totalOrders
    totalOrdersRub
    advertOrders
    advertOrdersRub
    buyoutPercent
    advertSales
    advertSalesRub
    crRub
    crPercent
    acosRub
    acosPercent
    tacosRub
    tacosPercent
    cacOrder
    cacSale
    profitFromOneSKU
    romiOrder
    romiSale
    wbOrdersRub
  }
`;
export const AdvertCampaignSummaryStatsFragmentFragmentDoc = gql`
  fragment AdvertCampaignSummaryStatsFragment on AdvertCampaignSummaryStats {
    advertCampaign {
      ...AdvertCampaignFragment
    }
    articleStats {
      ...AdvertCampaignArticleStatsFragment
    }
    generalStats {
      ...AdvertCampaignGeneralStatsFragment
    }
    bidChanges {
      ...AdvertCampaignBidChangeFragment
    }
    metrics {
      ...AdvertCampaignMetricsFragment
    }
    analyticsStats {
      ...AdvertCampaignAnalyticsStatsFragment
    }
    overallGeneralStats {
      ...AdvertCampaignGeneralStatsFragment
    }
  }
  ${AdvertCampaignFragmentFragmentDoc}
  ${AdvertCampaignArticleStatsFragmentFragmentDoc}
  ${AdvertCampaignGeneralStatsFragmentFragmentDoc}
  ${AdvertCampaignBidChangeFragmentFragmentDoc}
  ${AdvertCampaignMetricsFragmentFragmentDoc}
  ${AdvertCampaignAnalyticsStatsFragmentFragmentDoc}
`;
export const AdvertCampaignPerDayStatsFragmentFragmentDoc = gql`
  fragment AdvertCampaignPerDayStatsFragment on AdvertCampaignPerDayStats {
    views
    clicks
    frequency
    uniqueUsers
    ctr
    cpc
    sum
    atbs
    orders
    cr
    cac
    date
  }
`;
export const AdvertCampaignsOverallStatsFragmentFragmentDoc = gql`
  fragment AdvertCampaignsOverallStatsFragment on AdvertCampaignsOverallStats {
    overallAdvertCampaignStats {
      ...AdvertCampaignSummaryStatsFragment
    }
    overallMetrics {
      ...AdvertCampaignMetricsFragment
    }
    overallPerDayStats {
      ...AdvertCampaignPerDayStatsFragment
    }
    overallGeneralStats {
      ...AdvertCampaignGeneralStatsFragment
    }
  }
  ${AdvertCampaignSummaryStatsFragmentFragmentDoc}
  ${AdvertCampaignMetricsFragmentFragmentDoc}
  ${AdvertCampaignPerDayStatsFragmentFragmentDoc}
  ${AdvertCampaignGeneralStatsFragmentFragmentDoc}
`;
export const AdvertCampaignsStatsDistributedRequestFragmentFragmentDoc = gql`
  fragment AdvertCampaignsStatsDistributedRequestFragment on AdvertCampaignsStatsDistributedRequest {
    id
    marketId
    status
    progress
    output {
      ...AdvertCampaignsOverallStatsFragment
    }
  }
  ${AdvertCampaignsOverallStatsFragmentFragmentDoc}
`;
export const AdvertCampaignWordKeywordFragmentFragmentDoc = gql`
  fragment AdvertCampaignWordKeywordFragment on AdvertCampaignWordKeyword {
    keyword
    count
    ctr
    cpc
    sum
  }
`;
export const AdvertCampaignWordsOutputFragmentFragmentDoc = gql`
  fragment AdvertCampaignWordsOutputFragment on AdvertCampaignWordsOutput {
    excludedWords
    excludedPhraseWords
    excludedStrongWords
    plusWords
    keywords {
      ...AdvertCampaignWordKeywordFragment
    }
  }
  ${AdvertCampaignWordKeywordFragmentFragmentDoc}
`;
export const BidFragmentFragmentDoc = gql`
  fragment BidFragment on Bid {
    bid
    sku
    cardUrl
    pictureUrl
    brand
    name
    price
    deliveryTime
    category
  }
`;
export const FeedbackArticleFragmentFragmentDoc = gql`
  fragment FeedbackArticleFragment on FeedbackArticle {
    article
    name
    recommendedArticles {
      article
      name
    }
  }
`;
export const FeedbackAnswerFragmentFragmentDoc = gql`
  fragment feedbackAnswerFragment on FeedbackAnswer {
    id
    feedbackText
    answerText
    grade
    article
    createdAt
    updatedAt
    productName
  }
`;
export const FeedbackSubscriptionFragmentFragmentDoc = gql`
  fragment FeedbackSubscriptionFragment on FeedbackSubscription {
    marketId
    isActive
    createdAt
    updatedAt
    feedbackAnswers {
      ...feedbackAnswerFragment
    }
  }
  ${FeedbackAnswerFragmentFragmentDoc}
`;
export const PhraseFragmentFragmentDoc = gql`
  fragment PhraseFragment on Phrase {
    marketId
    productGrade
    greetingsPart
    thanksPart
    mainPart
    recommendationsPart
    farewellPart
  }
`;
export const CardFeedbacksFragmentFragmentDoc = gql`
  fragment cardFeedbacksFragment on CardFeedback {
    id
    userName
    feedbackText
    answerText
    cardUrl
    pictureUrl
    country
    valuation
    color
    matchingPhoto
    matchingSize
    votesFor
    votesAgainst
    feedbackDate
  }
`;
export const ValuationDistributionFragmentFragmentDoc = gql`
  fragment valuationDistributionFragment on CardValuation {
    valuation
    count
  }
`;
export const CountryDistributionFragmentFragmentDoc = gql`
  fragment countryDistributionFragment on CardCountryCount {
    country
    count
  }
`;
export const PhotoUrisFragmentFragmentDoc = gql`
  fragment photoUrisFragment on CardPhotoUri {
    minSizePhotoUrl
    fullSizePhotoUrl
  }
`;
export const CardInfoFragmentFragmentDoc = gql`
  fragment cardInfoFragment on CardInfo {
    cardName
    cardUrl
    cardPictureUrl
    subjectRootName
    subjectName
    feedbackCount
    feedbackWithPhotoCount
    rating
    valuationSum
    valuationDistribution {
      ...valuationDistributionFragment
    }
    countryDistribution {
      ...countryDistributionFragment
    }
    photoUris {
      ...photoUrisFragment
    }
  }
  ${ValuationDistributionFragmentFragmentDoc}
  ${CountryDistributionFragmentFragmentDoc}
  ${PhotoUrisFragmentFragmentDoc}
`;
export const CardQuesionsFragmentFragmentDoc = gql`
  fragment cardQuesionsFragment on CardQuestion {
    userName
    country
    questionText
    answerText
    questionDate
  }
`;
export const NicheAnalysisFilterFromToObjectTypeFragmentFragmentDoc = gql`
  fragment NicheAnalysisFilterFromToObjectTypeFragment on NicheAnalysisFilterFromToObjectType {
    from
    to
  }
`;
export const NicheAnalysisFilterFragmentFragmentDoc = gql`
  fragment NicheAnalysisFilterFragment on NicheAnalysisFilter {
    id
    marketId
    name
    revenueFromAllProduct {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    numberOfProducts {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    numberOfSellers {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    productSaleProbability {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    sellerWithSalesProbability {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    averageRevenuePerOneProductWithSales {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    averageMedianCheck {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    numberOfProductsWithRevenueGreaterThanOneMillion {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    averageCommentsAmount {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    top3SellerAverageRevenue {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    top10SellerAverageRevenue {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    top30SellerAverageRevenue {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    top3SellerAverageComments {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    top10SellerAverageComments {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    top30SellerAverageComments {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    purchaseProductsBudget {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
    selfPurchaseProductBudget {
      ...NicheAnalysisFilterFromToObjectTypeFragment
    }
  }
  ${NicheAnalysisFilterFromToObjectTypeFragmentFragmentDoc}
`;
export const NicheCategoryFragmentFragmentDoc = gql`
  fragment NicheCategoryFragment on NicheCategory {
    url
    name
    path
  }
`;
export const NicheStatValueNumberFragmentFragmentDoc = gql`
  fragment NicheStatValueNumberFragment on NicheStatValueNumber {
    value
    rating
  }
`;
export const NicheStatValueArrayFragmentFragmentDoc = gql`
  fragment NicheStatValueArrayFragment on NicheStatValueArray {
    value
    rating
  }
`;
export const NicheStatisticsFragmentFragmentDoc = gql`
  fragment NicheStatisticsFragment on NicheStatistics {
    revenueFromAllProduct {
      ...NicheStatValueNumberFragment
    }
    numberOfProducts {
      ...NicheStatValueNumberFragment
    }
    numberOfSellers {
      ...NicheStatValueNumberFragment
    }
    productSaleProbability {
      ...NicheStatValueNumberFragment
    }
    sellerWithSalesProbability {
      ...NicheStatValueNumberFragment
    }
    averageRevenuePerOneProductWithSales {
      ...NicheStatValueNumberFragment
    }
    averageMedianCheck {
      ...NicheStatValueNumberFragment
    }
    salesTrendForTheLastMonth {
      ...NicheStatValueArrayFragment
    }
    monopolization {
      ...NicheStatValueArrayFragment
    }
    numberOfProductsWithRevenueGreaterThanOneMillion {
      ...NicheStatValueNumberFragment
    }
    averageCommentsAmount {
      ...NicheStatValueNumberFragment
    }
    top3SellerAverageRevenue {
      ...NicheStatValueNumberFragment
    }
    top10SellerAverageRevenue {
      ...NicheStatValueNumberFragment
    }
    top30SellerAverageRevenue {
      ...NicheStatValueNumberFragment
    }
    top3SellerAverageComments {
      ...NicheStatValueNumberFragment
    }
    top10SellerAverageComments {
      ...NicheStatValueNumberFragment
    }
    top30SellerAverageComments {
      ...NicheStatValueNumberFragment
    }
    purchaseProductsBudget {
      ...NicheStatValueNumberFragment
    }
    selfPurchaseProductBudget {
      ...NicheStatValueNumberFragment
    }
  }
  ${NicheStatValueNumberFragmentFragmentDoc}
  ${NicheStatValueArrayFragmentFragmentDoc}
`;
export const NicheAnalysisOutputFragmentFragmentDoc = gql`
  fragment NicheAnalysisOutputFragment on NicheAnalysisOutput {
    category {
      ...NicheCategoryFragment
    }
    stats {
      ...NicheStatisticsFragment
    }
  }
  ${NicheCategoryFragmentFragmentDoc}
  ${NicheStatisticsFragmentFragmentDoc}
`;
export const NicheAnalysisDistributedRequestOutputFragmentFragmentDoc = gql`
  fragment NicheAnalysisDistributedRequestOutputFragment on NicheAnalysisDistributedRequestOutput {
    requestId
    marketId
    requestStatus
    progress
    output {
      ...NicheAnalysisOutputFragment
    }
  }
  ${NicheAnalysisOutputFragmentFragmentDoc}
`;
export const NicheProductStatsFragmentFragmentDoc = gql`
  fragment NicheProductStatsFragment on NicheProductStats {
    cardUrl
    pictureUrl
    article
    name
    color
    category
    position
    brand
    seller
    stock
    comments
    rating
    price
    clientPrice
    daysInStock
    sales
    revenue
    salesTrend
  }
`;
export const PopularRequestFragmentFragmentDoc = gql`
  fragment PopularRequestFragment on PopularRequest {
    requestText
    monthlyFrequency
    prioritizedCategory
    productAdvertBids {
      advertBid
      sku
      categoryId
      pictureUrl
      cardUrl
    }
  }
`;
export const StockFragmentFragmentDoc = gql`
  fragment StockFragment on Stock {
    subject
    brand
    name
    size
    barcode
    barcodes
    article
    stock
    warehouseId
    warehouseName
  }
`;
export const GetUnitEconomyOutputFragmentFragmentDoc = gql`
  fragment getUnitEconomyOutputFragment on GetUnitEconomyOutput {
    article
    name
    category
    taxWB
    editableBuybackPercentage
    width
    buybackPercentage
    height
    length
    weight
    purchasePrice
    servicesFF
    targetMargin
    discountBeforeDiscountWb
    tax
    purchaseVolume
    saleVolume
    promoBudget
    wantedProfit
  }
`;
export const WarehouseOutputFragmentFragmentDoc = gql`
  fragment warehouseOutputFragment on WarehouseOutput {
    name
    defaultDelivery
    additDelivery
    defaultStorage
    additStorage
  }
`;
export const DepositFragmentFragmentDoc = gql`
  fragment DepositFragment on Deposit {
    id
    userId
    marketId
    amount
    createdAt
    kassaConfirmationUrl
    kassaPaymentId
    status
  }
`;
export const MarketFragmentFragmentDoc = gql`
  fragment MarketFragment on Market {
    id
    balance
    marketplace
    name
    payload
    role
  }
`;
export const MarketRoleFragmentFragmentDoc = gql`
  fragment MarketRoleFragment on MarketRole {
    userId
    marketId
    role
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    createdAt
    updatedAt
    id
    firstName
    lastName
    phoneNumber
    userRole
    isBanned
    is2faEnabled
  }
`;
export const MarketMinimalFragmentFragmentDoc = gql`
  fragment MarketMinimalFragment on MarketMinimal {
    id
    name
    marketplace
  }
`;
export const NotificationFragmentFragmentDoc = gql`
  fragment NotificationFragment on NotificationUI {
    createdAt
    updatedAt
    id
    isRead
    user {
      ...UserFragment
    }
    initiatorId
    initiatorType
    notificationType
    importance
    params
    targetType
    market {
      ...MarketMinimalFragment
    }
  }
  ${UserFragmentFragmentDoc}
  ${MarketMinimalFragmentFragmentDoc}
`;
export const GetNotificationsOutputFragmentFragmentDoc = gql`
  fragment GetNotificationsOutputFragment on GetNotificationsOutput {
    notifications {
      ...NotificationFragment
    }
    count
  }
  ${NotificationFragmentFragmentDoc}
`;
export const AvailableSubscriptionFragmentFragmentDoc = gql`
  fragment AvailableSubscriptionFragment on AvailableSubscription {
    id
    toolId
    type
    marketplace
    price
    maxTime
    maxUseAmount
    status
  }
`;
export const MarketSubscriptionFragmentFragmentDoc = gql`
  fragment MarketSubscriptionFragment on MarketSubscription {
    id
    toolId
    marketId
    availableSubstriptionId
    buyDate
    amountOfUses
    purchasePrice
    endConditionTime
    endConditionMaxUseAmount
    status
  }
`;
export const ToolFragmentFragmentDoc = gql`
  fragment ToolFragment on Tool {
    id
    name
    description
    isBeta
  }
`;
export const WbAdvertisingAddAdvertCampaignDocument = gql`
  mutation wbAdvertisingAddAdvertCampaign($advertId: Int!) {
    wbAdvertisingAddAdvertCampaign(advertId: $advertId)
  }
`;
export type WbAdvertisingAddAdvertCampaignMutationFn = Apollo.MutationFunction<
  Types.WbAdvertisingAddAdvertCampaignMutation,
  Types.WbAdvertisingAddAdvertCampaignMutationVariables
>;

/**
 * __useWbAdvertisingAddAdvertCampaignMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingAddAdvertCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingAddAdvertCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingAddAdvertCampaignMutation, { data, loading, error }] = useWbAdvertisingAddAdvertCampaignMutation({
 *   variables: {
 *      advertId: // value for 'advertId'
 *   },
 * });
 */
export function useWbAdvertisingAddAdvertCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingAddAdvertCampaignMutation,
    Types.WbAdvertisingAddAdvertCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingAddAdvertCampaignMutation,
    Types.WbAdvertisingAddAdvertCampaignMutationVariables
  >(WbAdvertisingAddAdvertCampaignDocument, options);
}
export type WbAdvertisingAddAdvertCampaignMutationHookResult = ReturnType<
  typeof useWbAdvertisingAddAdvertCampaignMutation
>;
export type WbAdvertisingAddAdvertCampaignMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingAddAdvertCampaignMutation>;
export type WbAdvertisingAddAdvertCampaignMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAdvertisingAddAdvertCampaignMutation,
  Types.WbAdvertisingAddAdvertCampaignMutationVariables
>;
export const WbAdvertisingCreateCarouselAuctionAdvertCampaignDocument = gql`
  mutation wbAdvertisingCreateCarouselAuctionAdvertCampaign(
    $input: CreateCarouselAuctionAdvertCampaignInput!
  ) {
    wbAdvertisingCreateCarouselAuctionAdvertCampaign(input: $input) {
      ...CreateAdvertCampaignOutputFragment
    }
  }
  ${CreateAdvertCampaignOutputFragmentFragmentDoc}
`;
export type WbAdvertisingCreateCarouselAuctionAdvertCampaignMutationFn = Apollo.MutationFunction<
  Types.WbAdvertisingCreateCarouselAuctionAdvertCampaignMutation,
  Types.WbAdvertisingCreateCarouselAuctionAdvertCampaignMutationVariables
>;

/**
 * __useWbAdvertisingCreateCarouselAuctionAdvertCampaignMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingCreateCarouselAuctionAdvertCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingCreateCarouselAuctionAdvertCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingCreateCarouselAuctionAdvertCampaignMutation, { data, loading, error }] = useWbAdvertisingCreateCarouselAuctionAdvertCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingCreateCarouselAuctionAdvertCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingCreateCarouselAuctionAdvertCampaignMutation,
    Types.WbAdvertisingCreateCarouselAuctionAdvertCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingCreateCarouselAuctionAdvertCampaignMutation,
    Types.WbAdvertisingCreateCarouselAuctionAdvertCampaignMutationVariables
  >(WbAdvertisingCreateCarouselAuctionAdvertCampaignDocument, options);
}
export type WbAdvertisingCreateCarouselAuctionAdvertCampaignMutationHookResult = ReturnType<
  typeof useWbAdvertisingCreateCarouselAuctionAdvertCampaignMutation
>;
export type WbAdvertisingCreateCarouselAuctionAdvertCampaignMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingCreateCarouselAuctionAdvertCampaignMutation>;
export type WbAdvertisingCreateCarouselAuctionAdvertCampaignMutationOptions =
  Apollo.BaseMutationOptions<
    Types.WbAdvertisingCreateCarouselAuctionAdvertCampaignMutation,
    Types.WbAdvertisingCreateCarouselAuctionAdvertCampaignMutationVariables
  >;
export const WbAdvertisingCreateCatalogAdvertCampaignDocument = gql`
  mutation wbAdvertisingCreateCatalogAdvertCampaign($input: CreateCatalogAdvertCampaignInput!) {
    wbAdvertisingCreateCatalogAdvertCampaign(input: $input) {
      ...CreateAdvertCampaignOutputFragment
    }
  }
  ${CreateAdvertCampaignOutputFragmentFragmentDoc}
`;
export type WbAdvertisingCreateCatalogAdvertCampaignMutationFn = Apollo.MutationFunction<
  Types.WbAdvertisingCreateCatalogAdvertCampaignMutation,
  Types.WbAdvertisingCreateCatalogAdvertCampaignMutationVariables
>;

/**
 * __useWbAdvertisingCreateCatalogAdvertCampaignMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingCreateCatalogAdvertCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingCreateCatalogAdvertCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingCreateCatalogAdvertCampaignMutation, { data, loading, error }] = useWbAdvertisingCreateCatalogAdvertCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingCreateCatalogAdvertCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingCreateCatalogAdvertCampaignMutation,
    Types.WbAdvertisingCreateCatalogAdvertCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingCreateCatalogAdvertCampaignMutation,
    Types.WbAdvertisingCreateCatalogAdvertCampaignMutationVariables
  >(WbAdvertisingCreateCatalogAdvertCampaignDocument, options);
}
export type WbAdvertisingCreateCatalogAdvertCampaignMutationHookResult = ReturnType<
  typeof useWbAdvertisingCreateCatalogAdvertCampaignMutation
>;
export type WbAdvertisingCreateCatalogAdvertCampaignMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingCreateCatalogAdvertCampaignMutation>;
export type WbAdvertisingCreateCatalogAdvertCampaignMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAdvertisingCreateCatalogAdvertCampaignMutation,
  Types.WbAdvertisingCreateCatalogAdvertCampaignMutationVariables
>;
export const WbAdvertisingCreateSearchAdvertCampaignDocument = gql`
  mutation wbAdvertisingCreateSearchAdvertCampaign($input: CreateSearchAdvertCampaignInput!) {
    wbAdvertisingCreateSearchAdvertCampaign(input: $input) {
      ...CreateAdvertCampaignOutputFragment
    }
  }
  ${CreateAdvertCampaignOutputFragmentFragmentDoc}
`;
export type WbAdvertisingCreateSearchAdvertCampaignMutationFn = Apollo.MutationFunction<
  Types.WbAdvertisingCreateSearchAdvertCampaignMutation,
  Types.WbAdvertisingCreateSearchAdvertCampaignMutationVariables
>;

/**
 * __useWbAdvertisingCreateSearchAdvertCampaignMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingCreateSearchAdvertCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingCreateSearchAdvertCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingCreateSearchAdvertCampaignMutation, { data, loading, error }] = useWbAdvertisingCreateSearchAdvertCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingCreateSearchAdvertCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingCreateSearchAdvertCampaignMutation,
    Types.WbAdvertisingCreateSearchAdvertCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingCreateSearchAdvertCampaignMutation,
    Types.WbAdvertisingCreateSearchAdvertCampaignMutationVariables
  >(WbAdvertisingCreateSearchAdvertCampaignDocument, options);
}
export type WbAdvertisingCreateSearchAdvertCampaignMutationHookResult = ReturnType<
  typeof useWbAdvertisingCreateSearchAdvertCampaignMutation
>;
export type WbAdvertisingCreateSearchAdvertCampaignMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingCreateSearchAdvertCampaignMutation>;
export type WbAdvertisingCreateSearchAdvertCampaignMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAdvertisingCreateSearchAdvertCampaignMutation,
  Types.WbAdvertisingCreateSearchAdvertCampaignMutationVariables
>;
export const WbAdvertisingGetCatalogsDocument = gql`
  query wbAdvertisingGetCatalogs($input: GetCatalogsInput!) {
    wbAdvertisingGetCatalogs(input: $input) {
      groups {
        ...CatalogsGroupFragment
      }
    }
  }
  ${CatalogsGroupFragmentFragmentDoc}
`;

/**
 * __useWbAdvertisingGetCatalogsQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetCatalogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetCatalogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetCatalogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingGetCatalogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetCatalogsQuery,
    Types.WbAdvertisingGetCatalogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetCatalogsQuery,
    Types.WbAdvertisingGetCatalogsQueryVariables
  >(WbAdvertisingGetCatalogsDocument, options);
}
export function useWbAdvertisingGetCatalogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetCatalogsQuery,
    Types.WbAdvertisingGetCatalogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetCatalogsQuery,
    Types.WbAdvertisingGetCatalogsQueryVariables
  >(WbAdvertisingGetCatalogsDocument, options);
}
export type WbAdvertisingGetCatalogsQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetCatalogsQuery
>;
export type WbAdvertisingGetCatalogsLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetCatalogsLazyQuery
>;
export type WbAdvertisingGetCatalogsQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetCatalogsQuery,
  Types.WbAdvertisingGetCatalogsQueryVariables
>;
export const WbAdvertisingGetSubjectsAndProductsDocument = gql`
  query wbAdvertisingGetSubjectsAndProducts {
    wbAdvertisingGetSubjectsAndProducts {
      ...GetSubjectsAndProductsOutputFrament
    }
  }
  ${GetSubjectsAndProductsOutputFramentFragmentDoc}
`;

/**
 * __useWbAdvertisingGetSubjectsAndProductsQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetSubjectsAndProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetSubjectsAndProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetSubjectsAndProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWbAdvertisingGetSubjectsAndProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetSubjectsAndProductsQuery,
    Types.WbAdvertisingGetSubjectsAndProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetSubjectsAndProductsQuery,
    Types.WbAdvertisingGetSubjectsAndProductsQueryVariables
  >(WbAdvertisingGetSubjectsAndProductsDocument, options);
}
export function useWbAdvertisingGetSubjectsAndProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetSubjectsAndProductsQuery,
    Types.WbAdvertisingGetSubjectsAndProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetSubjectsAndProductsQuery,
    Types.WbAdvertisingGetSubjectsAndProductsQueryVariables
  >(WbAdvertisingGetSubjectsAndProductsDocument, options);
}
export type WbAdvertisingGetSubjectsAndProductsQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetSubjectsAndProductsQuery
>;
export type WbAdvertisingGetSubjectsAndProductsLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetSubjectsAndProductsLazyQuery
>;
export type WbAdvertisingGetSubjectsAndProductsQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetSubjectsAndProductsQuery,
  Types.WbAdvertisingGetSubjectsAndProductsQueryVariables
>;
export const WbAdvertisingGetTrendingSearchesDocument = gql`
  query wbAdvertisingGetTrendingSearches($input: GetTrendingSearchesInput!) {
    wbAdvertisingGetTrendingSearches(input: $input) {
      ...TrendingSearchFragment
    }
  }
  ${TrendingSearchFragmentFragmentDoc}
`;

/**
 * __useWbAdvertisingGetTrendingSearchesQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetTrendingSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetTrendingSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetTrendingSearchesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingGetTrendingSearchesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetTrendingSearchesQuery,
    Types.WbAdvertisingGetTrendingSearchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetTrendingSearchesQuery,
    Types.WbAdvertisingGetTrendingSearchesQueryVariables
  >(WbAdvertisingGetTrendingSearchesDocument, options);
}
export function useWbAdvertisingGetTrendingSearchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetTrendingSearchesQuery,
    Types.WbAdvertisingGetTrendingSearchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetTrendingSearchesQuery,
    Types.WbAdvertisingGetTrendingSearchesQueryVariables
  >(WbAdvertisingGetTrendingSearchesDocument, options);
}
export type WbAdvertisingGetTrendingSearchesQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetTrendingSearchesQuery
>;
export type WbAdvertisingGetTrendingSearchesLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetTrendingSearchesLazyQuery
>;
export type WbAdvertisingGetTrendingSearchesQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetTrendingSearchesQuery,
  Types.WbAdvertisingGetTrendingSearchesQueryVariables
>;
export const WbAdvertisingGetAdvertCampaignDocument = gql`
  query wbAdvertisingGetAdvertCampaign($advertCampaignId: String!) {
    wbAdvertisingGetAdvertCampaign(advertCampaignId: $advertCampaignId) {
      ...AdvertCampaignFragment
    }
  }
  ${AdvertCampaignFragmentFragmentDoc}
`;

/**
 * __useWbAdvertisingGetAdvertCampaignQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetAdvertCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetAdvertCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetAdvertCampaignQuery({
 *   variables: {
 *      advertCampaignId: // value for 'advertCampaignId'
 *   },
 * });
 */
export function useWbAdvertisingGetAdvertCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetAdvertCampaignQuery,
    Types.WbAdvertisingGetAdvertCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetAdvertCampaignQuery,
    Types.WbAdvertisingGetAdvertCampaignQueryVariables
  >(WbAdvertisingGetAdvertCampaignDocument, options);
}
export function useWbAdvertisingGetAdvertCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetAdvertCampaignQuery,
    Types.WbAdvertisingGetAdvertCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetAdvertCampaignQuery,
    Types.WbAdvertisingGetAdvertCampaignQueryVariables
  >(WbAdvertisingGetAdvertCampaignDocument, options);
}
export type WbAdvertisingGetAdvertCampaignQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetAdvertCampaignQuery
>;
export type WbAdvertisingGetAdvertCampaignLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetAdvertCampaignLazyQuery
>;
export type WbAdvertisingGetAdvertCampaignQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetAdvertCampaignQuery,
  Types.WbAdvertisingGetAdvertCampaignQueryVariables
>;
export const WbAdvertisingGetAdvertCampaignsDocument = gql`
  query wbAdvertisingGetAdvertCampaigns($input: GetAdvertCampaignsInput!) {
    wbAdvertisingGetAdvertCampaigns(input: $input) {
      advertCampaigns {
        ...AdvertCampaignFragment
      }
      count
    }
  }
  ${AdvertCampaignFragmentFragmentDoc}
`;

/**
 * __useWbAdvertisingGetAdvertCampaignsQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetAdvertCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetAdvertCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetAdvertCampaignsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingGetAdvertCampaignsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetAdvertCampaignsQuery,
    Types.WbAdvertisingGetAdvertCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetAdvertCampaignsQuery,
    Types.WbAdvertisingGetAdvertCampaignsQueryVariables
  >(WbAdvertisingGetAdvertCampaignsDocument, options);
}
export function useWbAdvertisingGetAdvertCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetAdvertCampaignsQuery,
    Types.WbAdvertisingGetAdvertCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetAdvertCampaignsQuery,
    Types.WbAdvertisingGetAdvertCampaignsQueryVariables
  >(WbAdvertisingGetAdvertCampaignsDocument, options);
}
export type WbAdvertisingGetAdvertCampaignsQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetAdvertCampaignsQuery
>;
export type WbAdvertisingGetAdvertCampaignsLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetAdvertCampaignsLazyQuery
>;
export type WbAdvertisingGetAdvertCampaignsQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetAdvertCampaignsQuery,
  Types.WbAdvertisingGetAdvertCampaignsQueryVariables
>;
export const WbAdvertisingGetSyncRequestDocument = gql`
  query wbAdvertisingGetSyncRequest($syncRequestId: String!) {
    wbAdvertisingGetSyncRequest(syncRequestId: $syncRequestId) {
      id
      marketId
      progress
      status
    }
  }
`;

/**
 * __useWbAdvertisingGetSyncRequestQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetSyncRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetSyncRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetSyncRequestQuery({
 *   variables: {
 *      syncRequestId: // value for 'syncRequestId'
 *   },
 * });
 */
export function useWbAdvertisingGetSyncRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetSyncRequestQuery,
    Types.WbAdvertisingGetSyncRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetSyncRequestQuery,
    Types.WbAdvertisingGetSyncRequestQueryVariables
  >(WbAdvertisingGetSyncRequestDocument, options);
}
export function useWbAdvertisingGetSyncRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetSyncRequestQuery,
    Types.WbAdvertisingGetSyncRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetSyncRequestQuery,
    Types.WbAdvertisingGetSyncRequestQueryVariables
  >(WbAdvertisingGetSyncRequestDocument, options);
}
export type WbAdvertisingGetSyncRequestQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetSyncRequestQuery
>;
export type WbAdvertisingGetSyncRequestLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetSyncRequestLazyQuery
>;
export type WbAdvertisingGetSyncRequestQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetSyncRequestQuery,
  Types.WbAdvertisingGetSyncRequestQueryVariables
>;
export const WbAdvertisingStartAdvertCampaignsSyncDocument = gql`
  mutation wbAdvertisingStartAdvertCampaignsSync {
    wbAdvertisingStartAdvertCampaignsSync
  }
`;
export type WbAdvertisingStartAdvertCampaignsSyncMutationFn = Apollo.MutationFunction<
  Types.WbAdvertisingStartAdvertCampaignsSyncMutation,
  Types.WbAdvertisingStartAdvertCampaignsSyncMutationVariables
>;

/**
 * __useWbAdvertisingStartAdvertCampaignsSyncMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingStartAdvertCampaignsSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingStartAdvertCampaignsSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingStartAdvertCampaignsSyncMutation, { data, loading, error }] = useWbAdvertisingStartAdvertCampaignsSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useWbAdvertisingStartAdvertCampaignsSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingStartAdvertCampaignsSyncMutation,
    Types.WbAdvertisingStartAdvertCampaignsSyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingStartAdvertCampaignsSyncMutation,
    Types.WbAdvertisingStartAdvertCampaignsSyncMutationVariables
  >(WbAdvertisingStartAdvertCampaignsSyncDocument, options);
}
export type WbAdvertisingStartAdvertCampaignsSyncMutationHookResult = ReturnType<
  typeof useWbAdvertisingStartAdvertCampaignsSyncMutation
>;
export type WbAdvertisingStartAdvertCampaignsSyncMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingStartAdvertCampaignsSyncMutation>;
export type WbAdvertisingStartAdvertCampaignsSyncMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAdvertisingStartAdvertCampaignsSyncMutation,
  Types.WbAdvertisingStartAdvertCampaignsSyncMutationVariables
>;
export const WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsDocument = gql`
  query wbAdvertisingGetDistributedRequestForAdvertCampaignsStats($distributedRequestId: String!) {
    wbAdvertisingGetDistributedRequestForAdvertCampaignsStats(
      distributedRequestId: $distributedRequestId
    ) {
      ...AdvertCampaignsStatsDistributedRequestFragment
    }
  }
  ${AdvertCampaignsStatsDistributedRequestFragmentFragmentDoc}
`;

/**
 * __useWbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery({
 *   variables: {
 *      distributedRequestId: // value for 'distributedRequestId'
 *   },
 * });
 */
export function useWbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery,
    Types.WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery,
    Types.WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQueryVariables
  >(WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsDocument, options);
}
export function useWbAdvertisingGetDistributedRequestForAdvertCampaignsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery,
    Types.WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery,
    Types.WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQueryVariables
  >(WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsDocument, options);
}
export type WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery
>;
export type WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsLazyQueryHookResult =
  ReturnType<typeof useWbAdvertisingGetDistributedRequestForAdvertCampaignsStatsLazyQuery>;
export type WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQueryResult =
  Apollo.QueryResult<
    Types.WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQuery,
    Types.WbAdvertisingGetDistributedRequestForAdvertCampaignsStatsQueryVariables
  >;
export const WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsDocument = gql`
  mutation wbAdvertisingStartDistributedRequestToGetAdvertCampaignsStats(
    $input: GetAdvertCampaignsStatsInput!
  ) {
    wbAdvertisingStartDistributedRequestToGetAdvertCampaignsStats(input: $input)
  }
`;
export type WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutationFn =
  Apollo.MutationFunction<
    Types.WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation,
    Types.WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutationVariables
  >;

/**
 * __useWbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation, { data, loading, error }] = useWbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation,
    Types.WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation,
    Types.WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutationVariables
  >(WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsDocument, options);
}
export type WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutationHookResult =
  ReturnType<typeof useWbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation>;
export type WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation>;
export type WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutationOptions =
  Apollo.BaseMutationOptions<
    Types.WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutation,
    Types.WbAdvertisingStartDistributedRequestToGetAdvertCampaignsStatsMutationVariables
  >;
export const WbAdvertisingActivateSubscriptionDocument = gql`
  mutation wbAdvertisingActivateSubscription {
    wbAdvertisingActivateSubscription {
      marketId
      isActive
    }
  }
`;
export type WbAdvertisingActivateSubscriptionMutationFn = Apollo.MutationFunction<
  Types.WbAdvertisingActivateSubscriptionMutation,
  Types.WbAdvertisingActivateSubscriptionMutationVariables
>;

/**
 * __useWbAdvertisingActivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingActivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingActivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingActivateSubscriptionMutation, { data, loading, error }] = useWbAdvertisingActivateSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useWbAdvertisingActivateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingActivateSubscriptionMutation,
    Types.WbAdvertisingActivateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingActivateSubscriptionMutation,
    Types.WbAdvertisingActivateSubscriptionMutationVariables
  >(WbAdvertisingActivateSubscriptionDocument, options);
}
export type WbAdvertisingActivateSubscriptionMutationHookResult = ReturnType<
  typeof useWbAdvertisingActivateSubscriptionMutation
>;
export type WbAdvertisingActivateSubscriptionMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingActivateSubscriptionMutation>;
export type WbAdvertisingActivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAdvertisingActivateSubscriptionMutation,
  Types.WbAdvertisingActivateSubscriptionMutationVariables
>;
export const WbAdvertisingCreateSubscriptionDocument = gql`
  mutation wbAdvertisingCreateSubscription {
    wbAdvertisingCreateSubscription {
      marketId
      isActive
    }
  }
`;
export type WbAdvertisingCreateSubscriptionMutationFn = Apollo.MutationFunction<
  Types.WbAdvertisingCreateSubscriptionMutation,
  Types.WbAdvertisingCreateSubscriptionMutationVariables
>;

/**
 * __useWbAdvertisingCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingCreateSubscriptionMutation, { data, loading, error }] = useWbAdvertisingCreateSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useWbAdvertisingCreateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingCreateSubscriptionMutation,
    Types.WbAdvertisingCreateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingCreateSubscriptionMutation,
    Types.WbAdvertisingCreateSubscriptionMutationVariables
  >(WbAdvertisingCreateSubscriptionDocument, options);
}
export type WbAdvertisingCreateSubscriptionMutationHookResult = ReturnType<
  typeof useWbAdvertisingCreateSubscriptionMutation
>;
export type WbAdvertisingCreateSubscriptionMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingCreateSubscriptionMutation>;
export type WbAdvertisingCreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAdvertisingCreateSubscriptionMutation,
  Types.WbAdvertisingCreateSubscriptionMutationVariables
>;
export const WbAdvertisingDeactivateSubscriptionDocument = gql`
  mutation wbAdvertisingDeactivateSubscription {
    wbAdvertisingDeactivateSubscription {
      marketId
      isActive
    }
  }
`;
export type WbAdvertisingDeactivateSubscriptionMutationFn = Apollo.MutationFunction<
  Types.WbAdvertisingDeactivateSubscriptionMutation,
  Types.WbAdvertisingDeactivateSubscriptionMutationVariables
>;

/**
 * __useWbAdvertisingDeactivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingDeactivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingDeactivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingDeactivateSubscriptionMutation, { data, loading, error }] = useWbAdvertisingDeactivateSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useWbAdvertisingDeactivateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingDeactivateSubscriptionMutation,
    Types.WbAdvertisingDeactivateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingDeactivateSubscriptionMutation,
    Types.WbAdvertisingDeactivateSubscriptionMutationVariables
  >(WbAdvertisingDeactivateSubscriptionDocument, options);
}
export type WbAdvertisingDeactivateSubscriptionMutationHookResult = ReturnType<
  typeof useWbAdvertisingDeactivateSubscriptionMutation
>;
export type WbAdvertisingDeactivateSubscriptionMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingDeactivateSubscriptionMutation>;
export type WbAdvertisingDeactivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAdvertisingDeactivateSubscriptionMutation,
  Types.WbAdvertisingDeactivateSubscriptionMutationVariables
>;
export const WbAdvertisingGetSubscriptionDocument = gql`
  query wbAdvertisingGetSubscription {
    wbAdvertisingGetSubscription {
      marketId
      isActive
    }
  }
`;

/**
 * __useWbAdvertisingGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useWbAdvertisingGetSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetSubscriptionQuery,
    Types.WbAdvertisingGetSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetSubscriptionQuery,
    Types.WbAdvertisingGetSubscriptionQueryVariables
  >(WbAdvertisingGetSubscriptionDocument, options);
}
export function useWbAdvertisingGetSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetSubscriptionQuery,
    Types.WbAdvertisingGetSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetSubscriptionQuery,
    Types.WbAdvertisingGetSubscriptionQueryVariables
  >(WbAdvertisingGetSubscriptionDocument, options);
}
export type WbAdvertisingGetSubscriptionQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetSubscriptionQuery
>;
export type WbAdvertisingGetSubscriptionLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetSubscriptionLazyQuery
>;
export type WbAdvertisingGetSubscriptionQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetSubscriptionQuery,
  Types.WbAdvertisingGetSubscriptionQueryVariables
>;
export const WbAdvertisingUpdateAdvertCampaignDocument = gql`
  mutation wbAdvertisingUpdateAdvertCampaign($input: UpdateAdvertCampaignInput!) {
    wbAdvertisingUpdateAdvertCampaign(input: $input)
  }
`;
export type WbAdvertisingUpdateAdvertCampaignMutationFn = Apollo.MutationFunction<
  Types.WbAdvertisingUpdateAdvertCampaignMutation,
  Types.WbAdvertisingUpdateAdvertCampaignMutationVariables
>;

/**
 * __useWbAdvertisingUpdateAdvertCampaignMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingUpdateAdvertCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingUpdateAdvertCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingUpdateAdvertCampaignMutation, { data, loading, error }] = useWbAdvertisingUpdateAdvertCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingUpdateAdvertCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingUpdateAdvertCampaignMutation,
    Types.WbAdvertisingUpdateAdvertCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingUpdateAdvertCampaignMutation,
    Types.WbAdvertisingUpdateAdvertCampaignMutationVariables
  >(WbAdvertisingUpdateAdvertCampaignDocument, options);
}
export type WbAdvertisingUpdateAdvertCampaignMutationHookResult = ReturnType<
  typeof useWbAdvertisingUpdateAdvertCampaignMutation
>;
export type WbAdvertisingUpdateAdvertCampaignMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingUpdateAdvertCampaignMutation>;
export type WbAdvertisingUpdateAdvertCampaignMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAdvertisingUpdateAdvertCampaignMutation,
  Types.WbAdvertisingUpdateAdvertCampaignMutationVariables
>;
export const WbAdvertisingGetAdvertCampaignStatsFiltersDocument = gql`
  query wbAdvertisingGetAdvertCampaignStatsFilters($advertCampaignIds: [String!]!) {
    wbAdvertisingGetAdvertCampaignStatsFilters(advertCampaignIds: $advertCampaignIds) {
      brands
      categories {
        id
        parentId
        name
        url
        shard
        query
      }
    }
  }
`;

/**
 * __useWbAdvertisingGetAdvertCampaignStatsFiltersQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetAdvertCampaignStatsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetAdvertCampaignStatsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetAdvertCampaignStatsFiltersQuery({
 *   variables: {
 *      advertCampaignIds: // value for 'advertCampaignIds'
 *   },
 * });
 */
export function useWbAdvertisingGetAdvertCampaignStatsFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetAdvertCampaignStatsFiltersQuery,
    Types.WbAdvertisingGetAdvertCampaignStatsFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetAdvertCampaignStatsFiltersQuery,
    Types.WbAdvertisingGetAdvertCampaignStatsFiltersQueryVariables
  >(WbAdvertisingGetAdvertCampaignStatsFiltersDocument, options);
}
export function useWbAdvertisingGetAdvertCampaignStatsFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetAdvertCampaignStatsFiltersQuery,
    Types.WbAdvertisingGetAdvertCampaignStatsFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetAdvertCampaignStatsFiltersQuery,
    Types.WbAdvertisingGetAdvertCampaignStatsFiltersQueryVariables
  >(WbAdvertisingGetAdvertCampaignStatsFiltersDocument, options);
}
export type WbAdvertisingGetAdvertCampaignStatsFiltersQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetAdvertCampaignStatsFiltersQuery
>;
export type WbAdvertisingGetAdvertCampaignStatsFiltersLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetAdvertCampaignStatsFiltersLazyQuery
>;
export type WbAdvertisingGetAdvertCampaignStatsFiltersQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetAdvertCampaignStatsFiltersQuery,
  Types.WbAdvertisingGetAdvertCampaignStatsFiltersQueryVariables
>;
export const WbAdvertisingGetWordsDocument = gql`
  query wbAdvertisingGetWords($advertCampaignId: String!) {
    wbAdvertisingGetWords(advertCampaignId: $advertCampaignId) {
      ...AdvertCampaignWordsOutputFragment
    }
  }
  ${AdvertCampaignWordsOutputFragmentFragmentDoc}
`;

/**
 * __useWbAdvertisingGetWordsQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetWordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetWordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetWordsQuery({
 *   variables: {
 *      advertCampaignId: // value for 'advertCampaignId'
 *   },
 * });
 */
export function useWbAdvertisingGetWordsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetWordsQuery,
    Types.WbAdvertisingGetWordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetWordsQuery,
    Types.WbAdvertisingGetWordsQueryVariables
  >(WbAdvertisingGetWordsDocument, options);
}
export function useWbAdvertisingGetWordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetWordsQuery,
    Types.WbAdvertisingGetWordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetWordsQuery,
    Types.WbAdvertisingGetWordsQueryVariables
  >(WbAdvertisingGetWordsDocument, options);
}
export type WbAdvertisingGetWordsQueryHookResult = ReturnType<typeof useWbAdvertisingGetWordsQuery>;
export type WbAdvertisingGetWordsLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetWordsLazyQuery
>;
export type WbAdvertisingGetWordsQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetWordsQuery,
  Types.WbAdvertisingGetWordsQueryVariables
>;
export const WbAdvertisingSetWordsDocument = gql`
  mutation wbAdvertisingSetWords($input: SetAdvertCampaignWordsInput!) {
    wbAdvertisingSetWords(input: $input) {
      ...AdvertCampaignWordsOutputFragment
    }
  }
  ${AdvertCampaignWordsOutputFragmentFragmentDoc}
`;
export type WbAdvertisingSetWordsMutationFn = Apollo.MutationFunction<
  Types.WbAdvertisingSetWordsMutation,
  Types.WbAdvertisingSetWordsMutationVariables
>;

/**
 * __useWbAdvertisingSetWordsMutation__
 *
 * To run a mutation, you first call `useWbAdvertisingSetWordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingSetWordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAdvertisingSetWordsMutation, { data, loading, error }] = useWbAdvertisingSetWordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingSetWordsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAdvertisingSetWordsMutation,
    Types.WbAdvertisingSetWordsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAdvertisingSetWordsMutation,
    Types.WbAdvertisingSetWordsMutationVariables
  >(WbAdvertisingSetWordsDocument, options);
}
export type WbAdvertisingSetWordsMutationHookResult = ReturnType<
  typeof useWbAdvertisingSetWordsMutation
>;
export type WbAdvertisingSetWordsMutationResult =
  Apollo.MutationResult<Types.WbAdvertisingSetWordsMutation>;
export type WbAdvertisingSetWordsMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAdvertisingSetWordsMutation,
  Types.WbAdvertisingSetWordsMutationVariables
>;
export const WbAdvertisingGetBidsDocument = gql`
  query wbAdvertisingGetBids($input: GetBidsInput!) {
    wbAdvertisingGetBids(input: $input) {
      ...BidFragment
    }
  }
  ${BidFragmentFragmentDoc}
`;

/**
 * __useWbAdvertisingGetBidsQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetBidsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingGetBidsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetBidsQuery,
    Types.WbAdvertisingGetBidsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.WbAdvertisingGetBidsQuery, Types.WbAdvertisingGetBidsQueryVariables>(
    WbAdvertisingGetBidsDocument,
    options
  );
}
export function useWbAdvertisingGetBidsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetBidsQuery,
    Types.WbAdvertisingGetBidsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetBidsQuery,
    Types.WbAdvertisingGetBidsQueryVariables
  >(WbAdvertisingGetBidsDocument, options);
}
export type WbAdvertisingGetBidsQueryHookResult = ReturnType<typeof useWbAdvertisingGetBidsQuery>;
export type WbAdvertisingGetBidsLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetBidsLazyQuery
>;
export type WbAdvertisingGetBidsQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetBidsQuery,
  Types.WbAdvertisingGetBidsQueryVariables
>;
export const WbAutofeedbacksActivateSubscriptionDocument = gql`
  mutation wbAutofeedbacksActivateSubscription {
    wbAutofeedbacksActivateSubscription {
      ...FeedbackSubscriptionFragment
    }
  }
  ${FeedbackSubscriptionFragmentFragmentDoc}
`;
export type WbAutofeedbacksActivateSubscriptionMutationFn = Apollo.MutationFunction<
  Types.WbAutofeedbacksActivateSubscriptionMutation,
  Types.WbAutofeedbacksActivateSubscriptionMutationVariables
>;

/**
 * __useWbAutofeedbacksActivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useWbAutofeedbacksActivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAutofeedbacksActivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAutofeedbacksActivateSubscriptionMutation, { data, loading, error }] = useWbAutofeedbacksActivateSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useWbAutofeedbacksActivateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAutofeedbacksActivateSubscriptionMutation,
    Types.WbAutofeedbacksActivateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAutofeedbacksActivateSubscriptionMutation,
    Types.WbAutofeedbacksActivateSubscriptionMutationVariables
  >(WbAutofeedbacksActivateSubscriptionDocument, options);
}
export type WbAutofeedbacksActivateSubscriptionMutationHookResult = ReturnType<
  typeof useWbAutofeedbacksActivateSubscriptionMutation
>;
export type WbAutofeedbacksActivateSubscriptionMutationResult =
  Apollo.MutationResult<Types.WbAutofeedbacksActivateSubscriptionMutation>;
export type WbAutofeedbacksActivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAutofeedbacksActivateSubscriptionMutation,
  Types.WbAutofeedbacksActivateSubscriptionMutationVariables
>;
export const WbAutofeedbacksCreateSubscriptionDocument = gql`
  mutation wbAutofeedbacksCreateSubscription {
    wbAutofeedbacksCreateSubscription {
      ...FeedbackSubscriptionFragment
    }
  }
  ${FeedbackSubscriptionFragmentFragmentDoc}
`;
export type WbAutofeedbacksCreateSubscriptionMutationFn = Apollo.MutationFunction<
  Types.WbAutofeedbacksCreateSubscriptionMutation,
  Types.WbAutofeedbacksCreateSubscriptionMutationVariables
>;

/**
 * __useWbAutofeedbacksCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useWbAutofeedbacksCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAutofeedbacksCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAutofeedbacksCreateSubscriptionMutation, { data, loading, error }] = useWbAutofeedbacksCreateSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useWbAutofeedbacksCreateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAutofeedbacksCreateSubscriptionMutation,
    Types.WbAutofeedbacksCreateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAutofeedbacksCreateSubscriptionMutation,
    Types.WbAutofeedbacksCreateSubscriptionMutationVariables
  >(WbAutofeedbacksCreateSubscriptionDocument, options);
}
export type WbAutofeedbacksCreateSubscriptionMutationHookResult = ReturnType<
  typeof useWbAutofeedbacksCreateSubscriptionMutation
>;
export type WbAutofeedbacksCreateSubscriptionMutationResult =
  Apollo.MutationResult<Types.WbAutofeedbacksCreateSubscriptionMutation>;
export type WbAutofeedbacksCreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAutofeedbacksCreateSubscriptionMutation,
  Types.WbAutofeedbacksCreateSubscriptionMutationVariables
>;
export const WbAutofeedbacksCreateUpdatePhraseDocument = gql`
  mutation wbAutofeedbacksCreateUpdatePhrase($input: CreateUpdatePhraseInput!) {
    wbAutofeedbacksCreateUpdatePhrase(input: $input) {
      ...PhraseFragment
    }
  }
  ${PhraseFragmentFragmentDoc}
`;
export type WbAutofeedbacksCreateUpdatePhraseMutationFn = Apollo.MutationFunction<
  Types.WbAutofeedbacksCreateUpdatePhraseMutation,
  Types.WbAutofeedbacksCreateUpdatePhraseMutationVariables
>;

/**
 * __useWbAutofeedbacksCreateUpdatePhraseMutation__
 *
 * To run a mutation, you first call `useWbAutofeedbacksCreateUpdatePhraseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAutofeedbacksCreateUpdatePhraseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAutofeedbacksCreateUpdatePhraseMutation, { data, loading, error }] = useWbAutofeedbacksCreateUpdatePhraseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAutofeedbacksCreateUpdatePhraseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAutofeedbacksCreateUpdatePhraseMutation,
    Types.WbAutofeedbacksCreateUpdatePhraseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAutofeedbacksCreateUpdatePhraseMutation,
    Types.WbAutofeedbacksCreateUpdatePhraseMutationVariables
  >(WbAutofeedbacksCreateUpdatePhraseDocument, options);
}
export type WbAutofeedbacksCreateUpdatePhraseMutationHookResult = ReturnType<
  typeof useWbAutofeedbacksCreateUpdatePhraseMutation
>;
export type WbAutofeedbacksCreateUpdatePhraseMutationResult =
  Apollo.MutationResult<Types.WbAutofeedbacksCreateUpdatePhraseMutation>;
export type WbAutofeedbacksCreateUpdatePhraseMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAutofeedbacksCreateUpdatePhraseMutation,
  Types.WbAutofeedbacksCreateUpdatePhraseMutationVariables
>;
export const WbAutofeedbacksDeactivateSubscriptionDocument = gql`
  mutation wbAutofeedbacksDeactivateSubscription {
    wbAutofeedbacksDeactivateSubscription {
      ...FeedbackSubscriptionFragment
    }
  }
  ${FeedbackSubscriptionFragmentFragmentDoc}
`;
export type WbAutofeedbacksDeactivateSubscriptionMutationFn = Apollo.MutationFunction<
  Types.WbAutofeedbacksDeactivateSubscriptionMutation,
  Types.WbAutofeedbacksDeactivateSubscriptionMutationVariables
>;

/**
 * __useWbAutofeedbacksDeactivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useWbAutofeedbacksDeactivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAutofeedbacksDeactivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAutofeedbacksDeactivateSubscriptionMutation, { data, loading, error }] = useWbAutofeedbacksDeactivateSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useWbAutofeedbacksDeactivateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAutofeedbacksDeactivateSubscriptionMutation,
    Types.WbAutofeedbacksDeactivateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAutofeedbacksDeactivateSubscriptionMutation,
    Types.WbAutofeedbacksDeactivateSubscriptionMutationVariables
  >(WbAutofeedbacksDeactivateSubscriptionDocument, options);
}
export type WbAutofeedbacksDeactivateSubscriptionMutationHookResult = ReturnType<
  typeof useWbAutofeedbacksDeactivateSubscriptionMutation
>;
export type WbAutofeedbacksDeactivateSubscriptionMutationResult =
  Apollo.MutationResult<Types.WbAutofeedbacksDeactivateSubscriptionMutation>;
export type WbAutofeedbacksDeactivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAutofeedbacksDeactivateSubscriptionMutation,
  Types.WbAutofeedbacksDeactivateSubscriptionMutationVariables
>;
export const WbAutofeedbacksDeletePhraseDocument = gql`
  mutation wbAutofeedbacksDeletePhrase($productGrade: Int!) {
    wbAutofeedbacksDeletePhrase(productGrade: $productGrade)
  }
`;
export type WbAutofeedbacksDeletePhraseMutationFn = Apollo.MutationFunction<
  Types.WbAutofeedbacksDeletePhraseMutation,
  Types.WbAutofeedbacksDeletePhraseMutationVariables
>;

/**
 * __useWbAutofeedbacksDeletePhraseMutation__
 *
 * To run a mutation, you first call `useWbAutofeedbacksDeletePhraseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAutofeedbacksDeletePhraseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAutofeedbacksDeletePhraseMutation, { data, loading, error }] = useWbAutofeedbacksDeletePhraseMutation({
 *   variables: {
 *      productGrade: // value for 'productGrade'
 *   },
 * });
 */
export function useWbAutofeedbacksDeletePhraseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAutofeedbacksDeletePhraseMutation,
    Types.WbAutofeedbacksDeletePhraseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAutofeedbacksDeletePhraseMutation,
    Types.WbAutofeedbacksDeletePhraseMutationVariables
  >(WbAutofeedbacksDeletePhraseDocument, options);
}
export type WbAutofeedbacksDeletePhraseMutationHookResult = ReturnType<
  typeof useWbAutofeedbacksDeletePhraseMutation
>;
export type WbAutofeedbacksDeletePhraseMutationResult =
  Apollo.MutationResult<Types.WbAutofeedbacksDeletePhraseMutation>;
export type WbAutofeedbacksDeletePhraseMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAutofeedbacksDeletePhraseMutation,
  Types.WbAutofeedbacksDeletePhraseMutationVariables
>;
export const WbAutofeedbacksGetArticlesDocument = gql`
  query wbAutofeedbacksGetArticles {
    wbAutofeedbacksGetArticles {
      ...FeedbackArticleFragment
    }
  }
  ${FeedbackArticleFragmentFragmentDoc}
`;

/**
 * __useWbAutofeedbacksGetArticlesQuery__
 *
 * To run a query within a React component, call `useWbAutofeedbacksGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAutofeedbacksGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAutofeedbacksGetArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWbAutofeedbacksGetArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.WbAutofeedbacksGetArticlesQuery,
    Types.WbAutofeedbacksGetArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAutofeedbacksGetArticlesQuery,
    Types.WbAutofeedbacksGetArticlesQueryVariables
  >(WbAutofeedbacksGetArticlesDocument, options);
}
export function useWbAutofeedbacksGetArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAutofeedbacksGetArticlesQuery,
    Types.WbAutofeedbacksGetArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAutofeedbacksGetArticlesQuery,
    Types.WbAutofeedbacksGetArticlesQueryVariables
  >(WbAutofeedbacksGetArticlesDocument, options);
}
export type WbAutofeedbacksGetArticlesQueryHookResult = ReturnType<
  typeof useWbAutofeedbacksGetArticlesQuery
>;
export type WbAutofeedbacksGetArticlesLazyQueryHookResult = ReturnType<
  typeof useWbAutofeedbacksGetArticlesLazyQuery
>;
export type WbAutofeedbacksGetArticlesQueryResult = Apollo.QueryResult<
  Types.WbAutofeedbacksGetArticlesQuery,
  Types.WbAutofeedbacksGetArticlesQueryVariables
>;
export const WbAutofeedbacksGetAnswersDocument = gql`
  query wbAutofeedbacksGetAnswers($input: GetFeedbackAnswersInput) {
    wbAutofeedbacksGetAnswers(input: $input) {
      ...feedbackAnswerFragment
    }
  }
  ${FeedbackAnswerFragmentFragmentDoc}
`;

/**
 * __useWbAutofeedbacksGetAnswersQuery__
 *
 * To run a query within a React component, call `useWbAutofeedbacksGetAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAutofeedbacksGetAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAutofeedbacksGetAnswersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAutofeedbacksGetAnswersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.WbAutofeedbacksGetAnswersQuery,
    Types.WbAutofeedbacksGetAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAutofeedbacksGetAnswersQuery,
    Types.WbAutofeedbacksGetAnswersQueryVariables
  >(WbAutofeedbacksGetAnswersDocument, options);
}
export function useWbAutofeedbacksGetAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAutofeedbacksGetAnswersQuery,
    Types.WbAutofeedbacksGetAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAutofeedbacksGetAnswersQuery,
    Types.WbAutofeedbacksGetAnswersQueryVariables
  >(WbAutofeedbacksGetAnswersDocument, options);
}
export type WbAutofeedbacksGetAnswersQueryHookResult = ReturnType<
  typeof useWbAutofeedbacksGetAnswersQuery
>;
export type WbAutofeedbacksGetAnswersLazyQueryHookResult = ReturnType<
  typeof useWbAutofeedbacksGetAnswersLazyQuery
>;
export type WbAutofeedbacksGetAnswersQueryResult = Apollo.QueryResult<
  Types.WbAutofeedbacksGetAnswersQuery,
  Types.WbAutofeedbacksGetAnswersQueryVariables
>;
export const WbAutofeedbacksGetPhrasesDocument = gql`
  query wbAutofeedbacksGetPhrases {
    wbAutofeedbacksGetPhrases {
      ...PhraseFragment
    }
  }
  ${PhraseFragmentFragmentDoc}
`;

/**
 * __useWbAutofeedbacksGetPhrasesQuery__
 *
 * To run a query within a React component, call `useWbAutofeedbacksGetPhrasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAutofeedbacksGetPhrasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAutofeedbacksGetPhrasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWbAutofeedbacksGetPhrasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.WbAutofeedbacksGetPhrasesQuery,
    Types.WbAutofeedbacksGetPhrasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAutofeedbacksGetPhrasesQuery,
    Types.WbAutofeedbacksGetPhrasesQueryVariables
  >(WbAutofeedbacksGetPhrasesDocument, options);
}
export function useWbAutofeedbacksGetPhrasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAutofeedbacksGetPhrasesQuery,
    Types.WbAutofeedbacksGetPhrasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAutofeedbacksGetPhrasesQuery,
    Types.WbAutofeedbacksGetPhrasesQueryVariables
  >(WbAutofeedbacksGetPhrasesDocument, options);
}
export type WbAutofeedbacksGetPhrasesQueryHookResult = ReturnType<
  typeof useWbAutofeedbacksGetPhrasesQuery
>;
export type WbAutofeedbacksGetPhrasesLazyQueryHookResult = ReturnType<
  typeof useWbAutofeedbacksGetPhrasesLazyQuery
>;
export type WbAutofeedbacksGetPhrasesQueryResult = Apollo.QueryResult<
  Types.WbAutofeedbacksGetPhrasesQuery,
  Types.WbAutofeedbacksGetPhrasesQueryVariables
>;
export const WbAutofeedbacksGetSubscriptionDocument = gql`
  query wbAutofeedbacksGetSubscription {
    wbAutofeedbacksGetSubscription {
      ...FeedbackSubscriptionFragment
    }
  }
  ${FeedbackSubscriptionFragmentFragmentDoc}
`;

/**
 * __useWbAutofeedbacksGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useWbAutofeedbacksGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAutofeedbacksGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAutofeedbacksGetSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useWbAutofeedbacksGetSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.WbAutofeedbacksGetSubscriptionQuery,
    Types.WbAutofeedbacksGetSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAutofeedbacksGetSubscriptionQuery,
    Types.WbAutofeedbacksGetSubscriptionQueryVariables
  >(WbAutofeedbacksGetSubscriptionDocument, options);
}
export function useWbAutofeedbacksGetSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAutofeedbacksGetSubscriptionQuery,
    Types.WbAutofeedbacksGetSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAutofeedbacksGetSubscriptionQuery,
    Types.WbAutofeedbacksGetSubscriptionQueryVariables
  >(WbAutofeedbacksGetSubscriptionDocument, options);
}
export type WbAutofeedbacksGetSubscriptionQueryHookResult = ReturnType<
  typeof useWbAutofeedbacksGetSubscriptionQuery
>;
export type WbAutofeedbacksGetSubscriptionLazyQueryHookResult = ReturnType<
  typeof useWbAutofeedbacksGetSubscriptionLazyQuery
>;
export type WbAutofeedbacksGetSubscriptionQueryResult = Apollo.QueryResult<
  Types.WbAutofeedbacksGetSubscriptionQuery,
  Types.WbAutofeedbacksGetSubscriptionQueryVariables
>;
export const WbAutofeedbacksUpdateArticleDocument = gql`
  mutation wbAutofeedbacksUpdateArticle($input: UpdateFeedbackArticleInput!) {
    wbAutofeedbacksUpdateArticle(input: $input)
  }
`;
export type WbAutofeedbacksUpdateArticleMutationFn = Apollo.MutationFunction<
  Types.WbAutofeedbacksUpdateArticleMutation,
  Types.WbAutofeedbacksUpdateArticleMutationVariables
>;

/**
 * __useWbAutofeedbacksUpdateArticleMutation__
 *
 * To run a mutation, you first call `useWbAutofeedbacksUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbAutofeedbacksUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbAutofeedbacksUpdateArticleMutation, { data, loading, error }] = useWbAutofeedbacksUpdateArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAutofeedbacksUpdateArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbAutofeedbacksUpdateArticleMutation,
    Types.WbAutofeedbacksUpdateArticleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbAutofeedbacksUpdateArticleMutation,
    Types.WbAutofeedbacksUpdateArticleMutationVariables
  >(WbAutofeedbacksUpdateArticleDocument, options);
}
export type WbAutofeedbacksUpdateArticleMutationHookResult = ReturnType<
  typeof useWbAutofeedbacksUpdateArticleMutation
>;
export type WbAutofeedbacksUpdateArticleMutationResult =
  Apollo.MutationResult<Types.WbAutofeedbacksUpdateArticleMutation>;
export type WbAutofeedbacksUpdateArticleMutationOptions = Apollo.BaseMutationOptions<
  Types.WbAutofeedbacksUpdateArticleMutation,
  Types.WbAutofeedbacksUpdateArticleMutationVariables
>;
export const WbFeedbackParserGetCardFeedbacksDocument = gql`
  query wbFeedbackParserGetCardFeedbacks($article: Int!) {
    wbFeedbackParserGetCardFeedbacks(article: $article) {
      cardFeedbacks {
        ...cardFeedbacksFragment
      }
      cardQuestions {
        ...cardQuesionsFragment
      }
      cardInfo {
        ...cardInfoFragment
      }
    }
  }
  ${CardFeedbacksFragmentFragmentDoc}
  ${CardQuesionsFragmentFragmentDoc}
  ${CardInfoFragmentFragmentDoc}
`;

/**
 * __useWbFeedbackParserGetCardFeedbacksQuery__
 *
 * To run a query within a React component, call `useWbFeedbackParserGetCardFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbFeedbackParserGetCardFeedbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbFeedbackParserGetCardFeedbacksQuery({
 *   variables: {
 *      article: // value for 'article'
 *   },
 * });
 */
export function useWbFeedbackParserGetCardFeedbacksQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbFeedbackParserGetCardFeedbacksQuery,
    Types.WbFeedbackParserGetCardFeedbacksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbFeedbackParserGetCardFeedbacksQuery,
    Types.WbFeedbackParserGetCardFeedbacksQueryVariables
  >(WbFeedbackParserGetCardFeedbacksDocument, options);
}
export function useWbFeedbackParserGetCardFeedbacksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbFeedbackParserGetCardFeedbacksQuery,
    Types.WbFeedbackParserGetCardFeedbacksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbFeedbackParserGetCardFeedbacksQuery,
    Types.WbFeedbackParserGetCardFeedbacksQueryVariables
  >(WbFeedbackParserGetCardFeedbacksDocument, options);
}
export type WbFeedbackParserGetCardFeedbacksQueryHookResult = ReturnType<
  typeof useWbFeedbackParserGetCardFeedbacksQuery
>;
export type WbFeedbackParserGetCardFeedbacksLazyQueryHookResult = ReturnType<
  typeof useWbFeedbackParserGetCardFeedbacksLazyQuery
>;
export type WbFeedbackParserGetCardFeedbacksQueryResult = Apollo.QueryResult<
  Types.WbFeedbackParserGetCardFeedbacksQuery,
  Types.WbFeedbackParserGetCardFeedbacksQueryVariables
>;
export const WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesDocument = gql`
  mutation wbNicheAnalysisStartDistributedRequestForAnalyseCategories($input: NicheAnalysisInput!) {
    wbNicheAnalysisStartDistributedRequestForAnalyseCategories(input: $input)
  }
`;
export type WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutationFn =
  Apollo.MutationFunction<
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation,
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutationVariables
  >;

/**
 * __useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation__
 *
 * To run a mutation, you first call `useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation, { data, loading, error }] = useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation,
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation,
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutationVariables
  >(WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesDocument, options);
}
export type WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutationHookResult =
  ReturnType<typeof useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation>;
export type WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutationResult =
  Apollo.MutationResult<Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation>;
export type WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutationOptions =
  Apollo.BaseMutationOptions<
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutation,
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesMutationVariables
  >;
export const WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameDocument = gql`
  mutation wbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByName(
    $input: NicheAnalysisByNameInput!
  ) {
    wbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByName(input: $input)
  }
`;
export type WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutationFn =
  Apollo.MutationFunction<
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation,
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutationVariables
  >;

/**
 * __useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation__
 *
 * To run a mutation, you first call `useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation, { data, loading, error }] = useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation,
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation,
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutationVariables
  >(WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameDocument, options);
}
export type WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutationHookResult =
  ReturnType<typeof useWbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation>;
export type WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutationResult =
  Apollo.MutationResult<Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation>;
export type WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutationOptions =
  Apollo.BaseMutationOptions<
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutation,
    Types.WbNicheAnalysisStartDistributedRequestForAnalyseCategoriesByNameMutationVariables
  >;
export const WbNicheAnalysisGetDistributedRequestDocument = gql`
  query wbNicheAnalysisGetDistributedRequest($input: String!) {
    wbNicheAnalysisGetDistributedRequest(distributedRequestId: $input) {
      ...NicheAnalysisDistributedRequestOutputFragment
    }
  }
  ${NicheAnalysisDistributedRequestOutputFragmentFragmentDoc}
`;

/**
 * __useWbNicheAnalysisGetDistributedRequestQuery__
 *
 * To run a query within a React component, call `useWbNicheAnalysisGetDistributedRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbNicheAnalysisGetDistributedRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbNicheAnalysisGetDistributedRequestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbNicheAnalysisGetDistributedRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbNicheAnalysisGetDistributedRequestQuery,
    Types.WbNicheAnalysisGetDistributedRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbNicheAnalysisGetDistributedRequestQuery,
    Types.WbNicheAnalysisGetDistributedRequestQueryVariables
  >(WbNicheAnalysisGetDistributedRequestDocument, options);
}
export function useWbNicheAnalysisGetDistributedRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbNicheAnalysisGetDistributedRequestQuery,
    Types.WbNicheAnalysisGetDistributedRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbNicheAnalysisGetDistributedRequestQuery,
    Types.WbNicheAnalysisGetDistributedRequestQueryVariables
  >(WbNicheAnalysisGetDistributedRequestDocument, options);
}
export type WbNicheAnalysisGetDistributedRequestQueryHookResult = ReturnType<
  typeof useWbNicheAnalysisGetDistributedRequestQuery
>;
export type WbNicheAnalysisGetDistributedRequestLazyQueryHookResult = ReturnType<
  typeof useWbNicheAnalysisGetDistributedRequestLazyQuery
>;
export type WbNicheAnalysisGetDistributedRequestQueryResult = Apollo.QueryResult<
  Types.WbNicheAnalysisGetDistributedRequestQuery,
  Types.WbNicheAnalysisGetDistributedRequestQueryVariables
>;
export const WbNicheSelectionCreateNicheFilterDocument = gql`
  mutation wbNicheSelectionCreateNicheFilter($input: CreateNicheAnalysisFilterInput!) {
    wbNicheSelectionCreateNicheFilter(input: $input) {
      ...NicheAnalysisFilterFragment
    }
  }
  ${NicheAnalysisFilterFragmentFragmentDoc}
`;
export type WbNicheSelectionCreateNicheFilterMutationFn = Apollo.MutationFunction<
  Types.WbNicheSelectionCreateNicheFilterMutation,
  Types.WbNicheSelectionCreateNicheFilterMutationVariables
>;

/**
 * __useWbNicheSelectionCreateNicheFilterMutation__
 *
 * To run a mutation, you first call `useWbNicheSelectionCreateNicheFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbNicheSelectionCreateNicheFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbNicheSelectionCreateNicheFilterMutation, { data, loading, error }] = useWbNicheSelectionCreateNicheFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbNicheSelectionCreateNicheFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbNicheSelectionCreateNicheFilterMutation,
    Types.WbNicheSelectionCreateNicheFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbNicheSelectionCreateNicheFilterMutation,
    Types.WbNicheSelectionCreateNicheFilterMutationVariables
  >(WbNicheSelectionCreateNicheFilterDocument, options);
}
export type WbNicheSelectionCreateNicheFilterMutationHookResult = ReturnType<
  typeof useWbNicheSelectionCreateNicheFilterMutation
>;
export type WbNicheSelectionCreateNicheFilterMutationResult =
  Apollo.MutationResult<Types.WbNicheSelectionCreateNicheFilterMutation>;
export type WbNicheSelectionCreateNicheFilterMutationOptions = Apollo.BaseMutationOptions<
  Types.WbNicheSelectionCreateNicheFilterMutation,
  Types.WbNicheSelectionCreateNicheFilterMutationVariables
>;
export const WbNicheSelectionDeleteNicheFilterDocument = gql`
  mutation wbNicheSelectionDeleteNicheFilter($nicheFilterId: String!) {
    wbNicheSelectionDeleteNicheFilter(nicheFilterId: $nicheFilterId)
  }
`;
export type WbNicheSelectionDeleteNicheFilterMutationFn = Apollo.MutationFunction<
  Types.WbNicheSelectionDeleteNicheFilterMutation,
  Types.WbNicheSelectionDeleteNicheFilterMutationVariables
>;

/**
 * __useWbNicheSelectionDeleteNicheFilterMutation__
 *
 * To run a mutation, you first call `useWbNicheSelectionDeleteNicheFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbNicheSelectionDeleteNicheFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbNicheSelectionDeleteNicheFilterMutation, { data, loading, error }] = useWbNicheSelectionDeleteNicheFilterMutation({
 *   variables: {
 *      nicheFilterId: // value for 'nicheFilterId'
 *   },
 * });
 */
export function useWbNicheSelectionDeleteNicheFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbNicheSelectionDeleteNicheFilterMutation,
    Types.WbNicheSelectionDeleteNicheFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbNicheSelectionDeleteNicheFilterMutation,
    Types.WbNicheSelectionDeleteNicheFilterMutationVariables
  >(WbNicheSelectionDeleteNicheFilterDocument, options);
}
export type WbNicheSelectionDeleteNicheFilterMutationHookResult = ReturnType<
  typeof useWbNicheSelectionDeleteNicheFilterMutation
>;
export type WbNicheSelectionDeleteNicheFilterMutationResult =
  Apollo.MutationResult<Types.WbNicheSelectionDeleteNicheFilterMutation>;
export type WbNicheSelectionDeleteNicheFilterMutationOptions = Apollo.BaseMutationOptions<
  Types.WbNicheSelectionDeleteNicheFilterMutation,
  Types.WbNicheSelectionDeleteNicheFilterMutationVariables
>;
export const WbNicheAnalysisGetAllCategoriesDocument = gql`
  query wbNicheAnalysisGetAllCategories {
    wbNicheAnalysisGetAllCategories {
      url
      name
      path
      parentPath
    }
  }
`;

/**
 * __useWbNicheAnalysisGetAllCategoriesQuery__
 *
 * To run a query within a React component, call `useWbNicheAnalysisGetAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbNicheAnalysisGetAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbNicheAnalysisGetAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWbNicheAnalysisGetAllCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.WbNicheAnalysisGetAllCategoriesQuery,
    Types.WbNicheAnalysisGetAllCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbNicheAnalysisGetAllCategoriesQuery,
    Types.WbNicheAnalysisGetAllCategoriesQueryVariables
  >(WbNicheAnalysisGetAllCategoriesDocument, options);
}
export function useWbNicheAnalysisGetAllCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbNicheAnalysisGetAllCategoriesQuery,
    Types.WbNicheAnalysisGetAllCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbNicheAnalysisGetAllCategoriesQuery,
    Types.WbNicheAnalysisGetAllCategoriesQueryVariables
  >(WbNicheAnalysisGetAllCategoriesDocument, options);
}
export type WbNicheAnalysisGetAllCategoriesQueryHookResult = ReturnType<
  typeof useWbNicheAnalysisGetAllCategoriesQuery
>;
export type WbNicheAnalysisGetAllCategoriesLazyQueryHookResult = ReturnType<
  typeof useWbNicheAnalysisGetAllCategoriesLazyQuery
>;
export type WbNicheAnalysisGetAllCategoriesQueryResult = Apollo.QueryResult<
  Types.WbNicheAnalysisGetAllCategoriesQuery,
  Types.WbNicheAnalysisGetAllCategoriesQueryVariables
>;
export const WbNicheSelectionGetNicheFiltersDocument = gql`
  query wbNicheSelectionGetNicheFilters {
    wbNicheSelectionGetNicheFilters {
      ...NicheAnalysisFilterFragment
    }
  }
  ${NicheAnalysisFilterFragmentFragmentDoc}
`;

/**
 * __useWbNicheSelectionGetNicheFiltersQuery__
 *
 * To run a query within a React component, call `useWbNicheSelectionGetNicheFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbNicheSelectionGetNicheFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbNicheSelectionGetNicheFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useWbNicheSelectionGetNicheFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.WbNicheSelectionGetNicheFiltersQuery,
    Types.WbNicheSelectionGetNicheFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbNicheSelectionGetNicheFiltersQuery,
    Types.WbNicheSelectionGetNicheFiltersQueryVariables
  >(WbNicheSelectionGetNicheFiltersDocument, options);
}
export function useWbNicheSelectionGetNicheFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbNicheSelectionGetNicheFiltersQuery,
    Types.WbNicheSelectionGetNicheFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbNicheSelectionGetNicheFiltersQuery,
    Types.WbNicheSelectionGetNicheFiltersQueryVariables
  >(WbNicheSelectionGetNicheFiltersDocument, options);
}
export type WbNicheSelectionGetNicheFiltersQueryHookResult = ReturnType<
  typeof useWbNicheSelectionGetNicheFiltersQuery
>;
export type WbNicheSelectionGetNicheFiltersLazyQueryHookResult = ReturnType<
  typeof useWbNicheSelectionGetNicheFiltersLazyQuery
>;
export type WbNicheSelectionGetNicheFiltersQueryResult = Apollo.QueryResult<
  Types.WbNicheSelectionGetNicheFiltersQuery,
  Types.WbNicheSelectionGetNicheFiltersQueryVariables
>;
export const WbNicheAnalysisGetCategoriesOneLevelLowerDocument = gql`
  query wbNicheAnalysisGetCategoriesOneLevelLower($categoryPath: String!) {
    wbNicheAnalysisGetCategoriesOneLevelLower(categoryPath: $categoryPath) {
      categories {
        ...NicheCategoryFragment
      }
      fetchCategory {
        ...NicheCategoryFragment
      }
    }
  }
  ${NicheCategoryFragmentFragmentDoc}
`;

/**
 * __useWbNicheAnalysisGetCategoriesOneLevelLowerQuery__
 *
 * To run a query within a React component, call `useWbNicheAnalysisGetCategoriesOneLevelLowerQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbNicheAnalysisGetCategoriesOneLevelLowerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbNicheAnalysisGetCategoriesOneLevelLowerQuery({
 *   variables: {
 *      categoryPath: // value for 'categoryPath'
 *   },
 * });
 */
export function useWbNicheAnalysisGetCategoriesOneLevelLowerQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbNicheAnalysisGetCategoriesOneLevelLowerQuery,
    Types.WbNicheAnalysisGetCategoriesOneLevelLowerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbNicheAnalysisGetCategoriesOneLevelLowerQuery,
    Types.WbNicheAnalysisGetCategoriesOneLevelLowerQueryVariables
  >(WbNicheAnalysisGetCategoriesOneLevelLowerDocument, options);
}
export function useWbNicheAnalysisGetCategoriesOneLevelLowerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbNicheAnalysisGetCategoriesOneLevelLowerQuery,
    Types.WbNicheAnalysisGetCategoriesOneLevelLowerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbNicheAnalysisGetCategoriesOneLevelLowerQuery,
    Types.WbNicheAnalysisGetCategoriesOneLevelLowerQueryVariables
  >(WbNicheAnalysisGetCategoriesOneLevelLowerDocument, options);
}
export type WbNicheAnalysisGetCategoriesOneLevelLowerQueryHookResult = ReturnType<
  typeof useWbNicheAnalysisGetCategoriesOneLevelLowerQuery
>;
export type WbNicheAnalysisGetCategoriesOneLevelLowerLazyQueryHookResult = ReturnType<
  typeof useWbNicheAnalysisGetCategoriesOneLevelLowerLazyQuery
>;
export type WbNicheAnalysisGetCategoriesOneLevelLowerQueryResult = Apollo.QueryResult<
  Types.WbNicheAnalysisGetCategoriesOneLevelLowerQuery,
  Types.WbNicheAnalysisGetCategoriesOneLevelLowerQueryVariables
>;
export const WbNicheAnalysisGetTopLevelCategoriesDocument = gql`
  query wbNicheAnalysisGetTopLevelCategories {
    wbNicheAnalysisGetTopLevelCategories {
      ...NicheCategoryFragment
    }
  }
  ${NicheCategoryFragmentFragmentDoc}
`;

/**
 * __useWbNicheAnalysisGetTopLevelCategoriesQuery__
 *
 * To run a query within a React component, call `useWbNicheAnalysisGetTopLevelCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbNicheAnalysisGetTopLevelCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbNicheAnalysisGetTopLevelCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWbNicheAnalysisGetTopLevelCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.WbNicheAnalysisGetTopLevelCategoriesQuery,
    Types.WbNicheAnalysisGetTopLevelCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbNicheAnalysisGetTopLevelCategoriesQuery,
    Types.WbNicheAnalysisGetTopLevelCategoriesQueryVariables
  >(WbNicheAnalysisGetTopLevelCategoriesDocument, options);
}
export function useWbNicheAnalysisGetTopLevelCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbNicheAnalysisGetTopLevelCategoriesQuery,
    Types.WbNicheAnalysisGetTopLevelCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbNicheAnalysisGetTopLevelCategoriesQuery,
    Types.WbNicheAnalysisGetTopLevelCategoriesQueryVariables
  >(WbNicheAnalysisGetTopLevelCategoriesDocument, options);
}
export type WbNicheAnalysisGetTopLevelCategoriesQueryHookResult = ReturnType<
  typeof useWbNicheAnalysisGetTopLevelCategoriesQuery
>;
export type WbNicheAnalysisGetTopLevelCategoriesLazyQueryHookResult = ReturnType<
  typeof useWbNicheAnalysisGetTopLevelCategoriesLazyQuery
>;
export type WbNicheAnalysisGetTopLevelCategoriesQueryResult = Apollo.QueryResult<
  Types.WbNicheAnalysisGetTopLevelCategoriesQuery,
  Types.WbNicheAnalysisGetTopLevelCategoriesQueryVariables
>;
export const WbNicheSelectionAnalyseProductsDocument = gql`
  query wbNicheSelectionAnalyseProducts($input: NicheProductsAnalysisInput!) {
    wbNicheSelectionAnalyseProducts(input: $input) {
      count
      productStats {
        ...NicheProductStatsFragment
      }
      category {
        ...NicheCategoryFragment
      }
    }
  }
  ${NicheProductStatsFragmentFragmentDoc}
  ${NicheCategoryFragmentFragmentDoc}
`;

/**
 * __useWbNicheSelectionAnalyseProductsQuery__
 *
 * To run a query within a React component, call `useWbNicheSelectionAnalyseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbNicheSelectionAnalyseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbNicheSelectionAnalyseProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbNicheSelectionAnalyseProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbNicheSelectionAnalyseProductsQuery,
    Types.WbNicheSelectionAnalyseProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbNicheSelectionAnalyseProductsQuery,
    Types.WbNicheSelectionAnalyseProductsQueryVariables
  >(WbNicheSelectionAnalyseProductsDocument, options);
}
export function useWbNicheSelectionAnalyseProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbNicheSelectionAnalyseProductsQuery,
    Types.WbNicheSelectionAnalyseProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbNicheSelectionAnalyseProductsQuery,
    Types.WbNicheSelectionAnalyseProductsQueryVariables
  >(WbNicheSelectionAnalyseProductsDocument, options);
}
export type WbNicheSelectionAnalyseProductsQueryHookResult = ReturnType<
  typeof useWbNicheSelectionAnalyseProductsQuery
>;
export type WbNicheSelectionAnalyseProductsLazyQueryHookResult = ReturnType<
  typeof useWbNicheSelectionAnalyseProductsLazyQuery
>;
export type WbNicheSelectionAnalyseProductsQueryResult = Apollo.QueryResult<
  Types.WbNicheSelectionAnalyseProductsQuery,
  Types.WbNicheSelectionAnalyseProductsQueryVariables
>;
export const WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameDocument = gql`
  mutation wbNicheSelectionStartDistributedRequestForAnalyseCategoriesByName(
    $input: NicheAnalysisByNameInput!
  ) {
    wbNicheSelectionStartDistributedRequestForAnalyseCategoriesByName(input: $input)
  }
`;
export type WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutationFn =
  Apollo.MutationFunction<
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation,
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutationVariables
  >;

/**
 * __useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation__
 *
 * To run a mutation, you first call `useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation, { data, loading, error }] = useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation,
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation,
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutationVariables
  >(WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameDocument, options);
}
export type WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutationHookResult =
  ReturnType<typeof useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation>;
export type WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutationResult =
  Apollo.MutationResult<Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation>;
export type WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutationOptions =
  Apollo.BaseMutationOptions<
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutation,
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesByNameMutationVariables
  >;
export const WbNicheSelectionStartDistributedRequestForAnalyseCategoriesDocument = gql`
  mutation wbNicheSelectionStartDistributedRequestForAnalyseCategories(
    $input: NicheAnalysisInput!
  ) {
    wbNicheSelectionStartDistributedRequestForAnalyseCategories(input: $input)
  }
`;
export type WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutationFn =
  Apollo.MutationFunction<
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation,
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutationVariables
  >;

/**
 * __useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation__
 *
 * To run a mutation, you first call `useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation, { data, loading, error }] = useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation,
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation,
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutationVariables
  >(WbNicheSelectionStartDistributedRequestForAnalyseCategoriesDocument, options);
}
export type WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutationHookResult =
  ReturnType<typeof useWbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation>;
export type WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutationResult =
  Apollo.MutationResult<Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation>;
export type WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutationOptions =
  Apollo.BaseMutationOptions<
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutation,
    Types.WbNicheSelectionStartDistributedRequestForAnalyseCategoriesMutationVariables
  >;
export const WbNicheSelectionGetDistributedRequestDocument = gql`
  query wbNicheSelectionGetDistributedRequest($input: String!) {
    wbNicheSelectionGetDistributedRequest(distributedRequestId: $input) {
      ...NicheAnalysisDistributedRequestOutputFragment
    }
  }
  ${NicheAnalysisDistributedRequestOutputFragmentFragmentDoc}
`;

/**
 * __useWbNicheSelectionGetDistributedRequestQuery__
 *
 * To run a query within a React component, call `useWbNicheSelectionGetDistributedRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbNicheSelectionGetDistributedRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbNicheSelectionGetDistributedRequestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbNicheSelectionGetDistributedRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbNicheSelectionGetDistributedRequestQuery,
    Types.WbNicheSelectionGetDistributedRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbNicheSelectionGetDistributedRequestQuery,
    Types.WbNicheSelectionGetDistributedRequestQueryVariables
  >(WbNicheSelectionGetDistributedRequestDocument, options);
}
export function useWbNicheSelectionGetDistributedRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbNicheSelectionGetDistributedRequestQuery,
    Types.WbNicheSelectionGetDistributedRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbNicheSelectionGetDistributedRequestQuery,
    Types.WbNicheSelectionGetDistributedRequestQueryVariables
  >(WbNicheSelectionGetDistributedRequestDocument, options);
}
export type WbNicheSelectionGetDistributedRequestQueryHookResult = ReturnType<
  typeof useWbNicheSelectionGetDistributedRequestQuery
>;
export type WbNicheSelectionGetDistributedRequestLazyQueryHookResult = ReturnType<
  typeof useWbNicheSelectionGetDistributedRequestLazyQuery
>;
export type WbNicheSelectionGetDistributedRequestQueryResult = Apollo.QueryResult<
  Types.WbNicheSelectionGetDistributedRequestQuery,
  Types.WbNicheSelectionGetDistributedRequestQueryVariables
>;
export const WbAdvertisingGetPopularRequestsDocument = gql`
  query wbAdvertisingGetPopularRequests($input: GetPopularRequestsInput!) {
    wbAdvertisingGetPopularRequests(input: $input) {
      popularRequests {
        ...PopularRequestFragment
      }
      count
    }
  }
  ${PopularRequestFragmentFragmentDoc}
`;

/**
 * __useWbAdvertisingGetPopularRequestsQuery__
 *
 * To run a query within a React component, call `useWbAdvertisingGetPopularRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAdvertisingGetPopularRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAdvertisingGetPopularRequestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbAdvertisingGetPopularRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbAdvertisingGetPopularRequestsQuery,
    Types.WbAdvertisingGetPopularRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.WbAdvertisingGetPopularRequestsQuery,
    Types.WbAdvertisingGetPopularRequestsQueryVariables
  >(WbAdvertisingGetPopularRequestsDocument, options);
}
export function useWbAdvertisingGetPopularRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbAdvertisingGetPopularRequestsQuery,
    Types.WbAdvertisingGetPopularRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.WbAdvertisingGetPopularRequestsQuery,
    Types.WbAdvertisingGetPopularRequestsQueryVariables
  >(WbAdvertisingGetPopularRequestsDocument, options);
}
export type WbAdvertisingGetPopularRequestsQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetPopularRequestsQuery
>;
export type WbAdvertisingGetPopularRequestsLazyQueryHookResult = ReturnType<
  typeof useWbAdvertisingGetPopularRequestsLazyQuery
>;
export type WbAdvertisingGetPopularRequestsQueryResult = Apollo.QueryResult<
  Types.WbAdvertisingGetPopularRequestsQuery,
  Types.WbAdvertisingGetPopularRequestsQueryVariables
>;
export const WbStocksGetStocksDocument = gql`
  query wbStocksGetStocks {
    wbStocksGetStocks(input: { take: 100, skip: 0 }) {
      stocks {
        ...StockFragment
      }
    }
  }
  ${StockFragmentFragmentDoc}
`;

/**
 * __useWbStocksGetStocksQuery__
 *
 * To run a query within a React component, call `useWbStocksGetStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbStocksGetStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbStocksGetStocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useWbStocksGetStocksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.WbStocksGetStocksQuery,
    Types.WbStocksGetStocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.WbStocksGetStocksQuery, Types.WbStocksGetStocksQueryVariables>(
    WbStocksGetStocksDocument,
    options
  );
}
export function useWbStocksGetStocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbStocksGetStocksQuery,
    Types.WbStocksGetStocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.WbStocksGetStocksQuery, Types.WbStocksGetStocksQueryVariables>(
    WbStocksGetStocksDocument,
    options
  );
}
export type WbStocksGetStocksQueryHookResult = ReturnType<typeof useWbStocksGetStocksQuery>;
export type WbStocksGetStocksLazyQueryHookResult = ReturnType<typeof useWbStocksGetStocksLazyQuery>;
export type WbStocksGetStocksQueryResult = Apollo.QueryResult<
  Types.WbStocksGetStocksQuery,
  Types.WbStocksGetStocksQueryVariables
>;
export const WbStocksUpdateStocksDocument = gql`
  mutation wbStocksUpdateStocks($input: UpdateStocksInput!) {
    wbStocksUpdateStocks(input: $input) {
      errors {
        err
      }
    }
  }
`;
export type WbStocksUpdateStocksMutationFn = Apollo.MutationFunction<
  Types.WbStocksUpdateStocksMutation,
  Types.WbStocksUpdateStocksMutationVariables
>;

/**
 * __useWbStocksUpdateStocksMutation__
 *
 * To run a mutation, you first call `useWbStocksUpdateStocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbStocksUpdateStocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbStocksUpdateStocksMutation, { data, loading, error }] = useWbStocksUpdateStocksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbStocksUpdateStocksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbStocksUpdateStocksMutation,
    Types.WbStocksUpdateStocksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbStocksUpdateStocksMutation,
    Types.WbStocksUpdateStocksMutationVariables
  >(WbStocksUpdateStocksDocument, options);
}
export type WbStocksUpdateStocksMutationHookResult = ReturnType<
  typeof useWbStocksUpdateStocksMutation
>;
export type WbStocksUpdateStocksMutationResult =
  Apollo.MutationResult<Types.WbStocksUpdateStocksMutation>;
export type WbStocksUpdateStocksMutationOptions = Apollo.BaseMutationOptions<
  Types.WbStocksUpdateStocksMutation,
  Types.WbStocksUpdateStocksMutationVariables
>;
export const WbWarehousesGetDocument = gql`
  query wbWarehousesGet($date: DateTime!) {
    wbWarehousesGet(date: $date) {
      ...warehouseOutputFragment
    }
  }
  ${WarehouseOutputFragmentFragmentDoc}
`;

/**
 * __useWbWarehousesGetQuery__
 *
 * To run a query within a React component, call `useWbWarehousesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbWarehousesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbWarehousesGetQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useWbWarehousesGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbWarehousesGetQuery,
    Types.WbWarehousesGetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.WbWarehousesGetQuery, Types.WbWarehousesGetQueryVariables>(
    WbWarehousesGetDocument,
    options
  );
}
export function useWbWarehousesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbWarehousesGetQuery,
    Types.WbWarehousesGetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.WbWarehousesGetQuery, Types.WbWarehousesGetQueryVariables>(
    WbWarehousesGetDocument,
    options
  );
}
export type WbWarehousesGetQueryHookResult = ReturnType<typeof useWbWarehousesGetQuery>;
export type WbWarehousesGetLazyQueryHookResult = ReturnType<typeof useWbWarehousesGetLazyQuery>;
export type WbWarehousesGetQueryResult = Apollo.QueryResult<
  Types.WbWarehousesGetQuery,
  Types.WbWarehousesGetQueryVariables
>;
export const WbUnitEconomyGetDocument = gql`
  query wbUnitEconomyGet($input: GetUnitEconomyInput!) {
    wbUnitEconomyGet(input: $input) {
      ...getUnitEconomyOutputFragment
    }
  }
  ${GetUnitEconomyOutputFragmentFragmentDoc}
`;

/**
 * __useWbUnitEconomyGetQuery__
 *
 * To run a query within a React component, call `useWbUnitEconomyGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbUnitEconomyGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbUnitEconomyGetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbUnitEconomyGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.WbUnitEconomyGetQuery,
    Types.WbUnitEconomyGetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.WbUnitEconomyGetQuery, Types.WbUnitEconomyGetQueryVariables>(
    WbUnitEconomyGetDocument,
    options
  );
}
export function useWbUnitEconomyGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.WbUnitEconomyGetQuery,
    Types.WbUnitEconomyGetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.WbUnitEconomyGetQuery, Types.WbUnitEconomyGetQueryVariables>(
    WbUnitEconomyGetDocument,
    options
  );
}
export type WbUnitEconomyGetQueryHookResult = ReturnType<typeof useWbUnitEconomyGetQuery>;
export type WbUnitEconomyGetLazyQueryHookResult = ReturnType<typeof useWbUnitEconomyGetLazyQuery>;
export type WbUnitEconomyGetQueryResult = Apollo.QueryResult<
  Types.WbUnitEconomyGetQuery,
  Types.WbUnitEconomyGetQueryVariables
>;
export const UnitEconomyGetSavedArticlesDocument = gql`
  query unitEconomyGetSavedArticles {
    wbUnitEconomyGetSavedArticles {
      article
      name
    }
  }
`;

/**
 * __useUnitEconomyGetSavedArticlesQuery__
 *
 * To run a query within a React component, call `useUnitEconomyGetSavedArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitEconomyGetSavedArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitEconomyGetSavedArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitEconomyGetSavedArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.UnitEconomyGetSavedArticlesQuery,
    Types.UnitEconomyGetSavedArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.UnitEconomyGetSavedArticlesQuery,
    Types.UnitEconomyGetSavedArticlesQueryVariables
  >(UnitEconomyGetSavedArticlesDocument, options);
}
export function useUnitEconomyGetSavedArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UnitEconomyGetSavedArticlesQuery,
    Types.UnitEconomyGetSavedArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.UnitEconomyGetSavedArticlesQuery,
    Types.UnitEconomyGetSavedArticlesQueryVariables
  >(UnitEconomyGetSavedArticlesDocument, options);
}
export type UnitEconomyGetSavedArticlesQueryHookResult = ReturnType<
  typeof useUnitEconomyGetSavedArticlesQuery
>;
export type UnitEconomyGetSavedArticlesLazyQueryHookResult = ReturnType<
  typeof useUnitEconomyGetSavedArticlesLazyQuery
>;
export type UnitEconomyGetSavedArticlesQueryResult = Apollo.QueryResult<
  Types.UnitEconomyGetSavedArticlesQuery,
  Types.UnitEconomyGetSavedArticlesQueryVariables
>;
export const WbUnitEconomySaveDocument = gql`
  mutation wbUnitEconomySave($input: SaveUnitEconomyInput!) {
    wbUnitEconomySave(input: $input)
  }
`;
export type WbUnitEconomySaveMutationFn = Apollo.MutationFunction<
  Types.WbUnitEconomySaveMutation,
  Types.WbUnitEconomySaveMutationVariables
>;

/**
 * __useWbUnitEconomySaveMutation__
 *
 * To run a mutation, you first call `useWbUnitEconomySaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWbUnitEconomySaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wbUnitEconomySaveMutation, { data, loading, error }] = useWbUnitEconomySaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWbUnitEconomySaveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WbUnitEconomySaveMutation,
    Types.WbUnitEconomySaveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.WbUnitEconomySaveMutation,
    Types.WbUnitEconomySaveMutationVariables
  >(WbUnitEconomySaveDocument, options);
}
export type WbUnitEconomySaveMutationHookResult = ReturnType<typeof useWbUnitEconomySaveMutation>;
export type WbUnitEconomySaveMutationResult =
  Apollo.MutationResult<Types.WbUnitEconomySaveMutation>;
export type WbUnitEconomySaveMutationOptions = Apollo.BaseMutationOptions<
  Types.WbUnitEconomySaveMutation,
  Types.WbUnitEconomySaveMutationVariables
>;
export const LoginDocument = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      jwtToken
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  Types.LoginMutation,
  Types.LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  Types.LoginMutation,
  Types.LoginMutationVariables
>;
export const RequestTwoFactorAuthCodeDocument = gql`
  mutation requestTwoFactorAuthCode($phone: String!) {
    requestTwoFactorAuthCode(requestor: $phone)
  }
`;
export type RequestTwoFactorAuthCodeMutationFn = Apollo.MutationFunction<
  Types.RequestTwoFactorAuthCodeMutation,
  Types.RequestTwoFactorAuthCodeMutationVariables
>;

/**
 * __useRequestTwoFactorAuthCodeMutation__
 *
 * To run a mutation, you first call `useRequestTwoFactorAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTwoFactorAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTwoFactorAuthCodeMutation, { data, loading, error }] = useRequestTwoFactorAuthCodeMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useRequestTwoFactorAuthCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RequestTwoFactorAuthCodeMutation,
    Types.RequestTwoFactorAuthCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RequestTwoFactorAuthCodeMutation,
    Types.RequestTwoFactorAuthCodeMutationVariables
  >(RequestTwoFactorAuthCodeDocument, options);
}
export type RequestTwoFactorAuthCodeMutationHookResult = ReturnType<
  typeof useRequestTwoFactorAuthCodeMutation
>;
export type RequestTwoFactorAuthCodeMutationResult =
  Apollo.MutationResult<Types.RequestTwoFactorAuthCodeMutation>;
export type RequestTwoFactorAuthCodeMutationOptions = Apollo.BaseMutationOptions<
  Types.RequestTwoFactorAuthCodeMutation,
  Types.RequestTwoFactorAuthCodeMutationVariables
>;
export const SignUpDocument = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  Types.SignUpMutation,
  Types.SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SignUpMutation, Types.SignUpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SignUpMutation, Types.SignUpMutationVariables>(
    SignUpDocument,
    options
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<Types.SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  Types.SignUpMutation,
  Types.SignUpMutationVariables
>;
export const CreateDepositDocument = gql`
  mutation createDeposit($input: CreateDepositInput!) {
    createDeposit(input: $input) {
      ...DepositFragment
    }
  }
  ${DepositFragmentFragmentDoc}
`;
export type CreateDepositMutationFn = Apollo.MutationFunction<
  Types.CreateDepositMutation,
  Types.CreateDepositMutationVariables
>;

/**
 * __useCreateDepositMutation__
 *
 * To run a mutation, you first call `useCreateDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepositMutation, { data, loading, error }] = useCreateDepositMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateDepositMutation,
    Types.CreateDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateDepositMutation, Types.CreateDepositMutationVariables>(
    CreateDepositDocument,
    options
  );
}
export type CreateDepositMutationHookResult = ReturnType<typeof useCreateDepositMutation>;
export type CreateDepositMutationResult = Apollo.MutationResult<Types.CreateDepositMutation>;
export type CreateDepositMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateDepositMutation,
  Types.CreateDepositMutationVariables
>;
export const CreateMarketDocument = gql`
  mutation createMarket($input: CreateMarketInput!) {
    createMarket(input: $input) {
      ...MarketFragment
    }
  }
  ${MarketFragmentFragmentDoc}
`;
export type CreateMarketMutationFn = Apollo.MutationFunction<
  Types.CreateMarketMutation,
  Types.CreateMarketMutationVariables
>;

/**
 * __useCreateMarketMutation__
 *
 * To run a mutation, you first call `useCreateMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketMutation, { data, loading, error }] = useCreateMarketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateMarketMutation,
    Types.CreateMarketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateMarketMutation, Types.CreateMarketMutationVariables>(
    CreateMarketDocument,
    options
  );
}
export type CreateMarketMutationHookResult = ReturnType<typeof useCreateMarketMutation>;
export type CreateMarketMutationResult = Apollo.MutationResult<Types.CreateMarketMutation>;
export type CreateMarketMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateMarketMutation,
  Types.CreateMarketMutationVariables
>;
export const CreateMarketRoleDocument = gql`
  mutation createMarketRole($input: CreateMarketRoleInput!) {
    createMarketRole(input: $input) {
      ...MarketRoleFragment
    }
  }
  ${MarketRoleFragmentFragmentDoc}
`;
export type CreateMarketRoleMutationFn = Apollo.MutationFunction<
  Types.CreateMarketRoleMutation,
  Types.CreateMarketRoleMutationVariables
>;

/**
 * __useCreateMarketRoleMutation__
 *
 * To run a mutation, you first call `useCreateMarketRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketRoleMutation, { data, loading, error }] = useCreateMarketRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateMarketRoleMutation,
    Types.CreateMarketRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateMarketRoleMutation,
    Types.CreateMarketRoleMutationVariables
  >(CreateMarketRoleDocument, options);
}
export type CreateMarketRoleMutationHookResult = ReturnType<typeof useCreateMarketRoleMutation>;
export type CreateMarketRoleMutationResult = Apollo.MutationResult<Types.CreateMarketRoleMutation>;
export type CreateMarketRoleMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateMarketRoleMutation,
  Types.CreateMarketRoleMutationVariables
>;
export const DepositFindbyIdDocument = gql`
  query depositFindbyId($id: String!) {
    depositFindbyId(id: $id) {
      ...DepositFragment
    }
  }
  ${DepositFragmentFragmentDoc}
`;

/**
 * __useDepositFindbyIdQuery__
 *
 * To run a query within a React component, call `useDepositFindbyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositFindbyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositFindbyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDepositFindbyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DepositFindbyIdQuery,
    Types.DepositFindbyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DepositFindbyIdQuery, Types.DepositFindbyIdQueryVariables>(
    DepositFindbyIdDocument,
    options
  );
}
export function useDepositFindbyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DepositFindbyIdQuery,
    Types.DepositFindbyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DepositFindbyIdQuery, Types.DepositFindbyIdQueryVariables>(
    DepositFindbyIdDocument,
    options
  );
}
export type DepositFindbyIdQueryHookResult = ReturnType<typeof useDepositFindbyIdQuery>;
export type DepositFindbyIdLazyQueryHookResult = ReturnType<typeof useDepositFindbyIdLazyQuery>;
export type DepositFindbyIdQueryResult = Apollo.QueryResult<
  Types.DepositFindbyIdQuery,
  Types.DepositFindbyIdQueryVariables
>;
export const MarketFindbyIdDocument = gql`
  query marketFindbyId($id: String!) {
    marketFindbyId(id: $id) {
      ...MarketFragment
    }
  }
  ${MarketFragmentFragmentDoc}
`;

/**
 * __useMarketFindbyIdQuery__
 *
 * To run a query within a React component, call `useMarketFindbyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketFindbyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketFindbyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarketFindbyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MarketFindbyIdQuery,
    Types.MarketFindbyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.MarketFindbyIdQuery, Types.MarketFindbyIdQueryVariables>(
    MarketFindbyIdDocument,
    options
  );
}
export function useMarketFindbyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MarketFindbyIdQuery,
    Types.MarketFindbyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.MarketFindbyIdQuery, Types.MarketFindbyIdQueryVariables>(
    MarketFindbyIdDocument,
    options
  );
}
export type MarketFindbyIdQueryHookResult = ReturnType<typeof useMarketFindbyIdQuery>;
export type MarketFindbyIdLazyQueryHookResult = ReturnType<typeof useMarketFindbyIdLazyQuery>;
export type MarketFindbyIdQueryResult = Apollo.QueryResult<
  Types.MarketFindbyIdQuery,
  Types.MarketFindbyIdQueryVariables
>;
export const MarketFindAllForUserDocument = gql`
  query marketFindAllForUser($role: UserRoleMarket) {
    marketFindAllForUser(role: $role) {
      ...MarketFragment
    }
  }
  ${MarketFragmentFragmentDoc}
`;

/**
 * __useMarketFindAllForUserQuery__
 *
 * To run a query within a React component, call `useMarketFindAllForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketFindAllForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketFindAllForUserQuery({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useMarketFindAllForUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.MarketFindAllForUserQuery,
    Types.MarketFindAllForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.MarketFindAllForUserQuery, Types.MarketFindAllForUserQueryVariables>(
    MarketFindAllForUserDocument,
    options
  );
}
export function useMarketFindAllForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MarketFindAllForUserQuery,
    Types.MarketFindAllForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.MarketFindAllForUserQuery,
    Types.MarketFindAllForUserQueryVariables
  >(MarketFindAllForUserDocument, options);
}
export type MarketFindAllForUserQueryHookResult = ReturnType<typeof useMarketFindAllForUserQuery>;
export type MarketFindAllForUserLazyQueryHookResult = ReturnType<
  typeof useMarketFindAllForUserLazyQuery
>;
export type MarketFindAllForUserQueryResult = Apollo.QueryResult<
  Types.MarketFindAllForUserQuery,
  Types.MarketFindAllForUserQueryVariables
>;
export const UpdateMarketDocument = gql`
  mutation updateMarket($input: UpdateMarketInput!) {
    updateMarket(input: $input)
  }
`;
export type UpdateMarketMutationFn = Apollo.MutationFunction<
  Types.UpdateMarketMutation,
  Types.UpdateMarketMutationVariables
>;

/**
 * __useUpdateMarketMutation__
 *
 * To run a mutation, you first call `useUpdateMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketMutation, { data, loading, error }] = useUpdateMarketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMarketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateMarketMutation,
    Types.UpdateMarketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateMarketMutation, Types.UpdateMarketMutationVariables>(
    UpdateMarketDocument,
    options
  );
}
export type UpdateMarketMutationHookResult = ReturnType<typeof useUpdateMarketMutation>;
export type UpdateMarketMutationResult = Apollo.MutationResult<Types.UpdateMarketMutation>;
export type UpdateMarketMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateMarketMutation,
  Types.UpdateMarketMutationVariables
>;
export const GetNotificationsDocument = gql`
  query getNotifications($input: GetNotificationsInput) {
    getNotifications(input: $input) {
      ...GetNotificationsOutputFragment
    }
  }
  ${GetNotificationsOutputFragmentFragmentDoc}
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GetNotificationsQuery,
    Types.GetNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetNotificationsQuery, Types.GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options
  );
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetNotificationsQuery,
    Types.GetNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetNotificationsQuery, Types.GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options
  );
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<
  Types.GetNotificationsQuery,
  Types.GetNotificationsQueryVariables
>;
export const ReadAllNotificationsDocument = gql`
  mutation readAllNotifications {
    readAllNotifications
  }
`;
export type ReadAllNotificationsMutationFn = Apollo.MutationFunction<
  Types.ReadAllNotificationsMutation,
  Types.ReadAllNotificationsMutationVariables
>;

/**
 * __useReadAllNotificationsMutation__
 *
 * To run a mutation, you first call `useReadAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllNotificationsMutation, { data, loading, error }] = useReadAllNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useReadAllNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ReadAllNotificationsMutation,
    Types.ReadAllNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ReadAllNotificationsMutation,
    Types.ReadAllNotificationsMutationVariables
  >(ReadAllNotificationsDocument, options);
}
export type ReadAllNotificationsMutationHookResult = ReturnType<
  typeof useReadAllNotificationsMutation
>;
export type ReadAllNotificationsMutationResult =
  Apollo.MutationResult<Types.ReadAllNotificationsMutation>;
export type ReadAllNotificationsMutationOptions = Apollo.BaseMutationOptions<
  Types.ReadAllNotificationsMutation,
  Types.ReadAllNotificationsMutationVariables
>;
export const ReadNotificationDocument = gql`
  mutation readNotification($notificationId: String!) {
    readNotification(notificationId: $notificationId)
  }
`;
export type ReadNotificationMutationFn = Apollo.MutationFunction<
  Types.ReadNotificationMutation,
  Types.ReadNotificationMutationVariables
>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useReadNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ReadNotificationMutation,
    Types.ReadNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ReadNotificationMutation,
    Types.ReadNotificationMutationVariables
  >(ReadNotificationDocument, options);
}
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<Types.ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<
  Types.ReadNotificationMutation,
  Types.ReadNotificationMutationVariables
>;
export const AvailableSubscriptionsDocument = gql`
  query availableSubscriptions($toolId: String!) {
    availableSubscriptions(toolId: $toolId) {
      ...AvailableSubscriptionFragment
    }
  }
  ${AvailableSubscriptionFragmentFragmentDoc}
`;

/**
 * __useAvailableSubscriptionsQuery__
 *
 * To run a query within a React component, call `useAvailableSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableSubscriptionsQuery({
 *   variables: {
 *      toolId: // value for 'toolId'
 *   },
 * });
 */
export function useAvailableSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AvailableSubscriptionsQuery,
    Types.AvailableSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.AvailableSubscriptionsQuery,
    Types.AvailableSubscriptionsQueryVariables
  >(AvailableSubscriptionsDocument, options);
}
export function useAvailableSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AvailableSubscriptionsQuery,
    Types.AvailableSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.AvailableSubscriptionsQuery,
    Types.AvailableSubscriptionsQueryVariables
  >(AvailableSubscriptionsDocument, options);
}
export type AvailableSubscriptionsQueryHookResult = ReturnType<
  typeof useAvailableSubscriptionsQuery
>;
export type AvailableSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useAvailableSubscriptionsLazyQuery
>;
export type AvailableSubscriptionsQueryResult = Apollo.QueryResult<
  Types.AvailableSubscriptionsQuery,
  Types.AvailableSubscriptionsQueryVariables
>;
export const CreateAvailableSubscriptionDocument = gql`
  mutation createAvailableSubscription($input: CreateAvailiableSubscriptionInput!) {
    createAvailableSubscription(input: $input) {
      ...AvailableSubscriptionFragment
    }
  }
  ${AvailableSubscriptionFragmentFragmentDoc}
`;
export type CreateAvailableSubscriptionMutationFn = Apollo.MutationFunction<
  Types.CreateAvailableSubscriptionMutation,
  Types.CreateAvailableSubscriptionMutationVariables
>;

/**
 * __useCreateAvailableSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateAvailableSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAvailableSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAvailableSubscriptionMutation, { data, loading, error }] = useCreateAvailableSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAvailableSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateAvailableSubscriptionMutation,
    Types.CreateAvailableSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateAvailableSubscriptionMutation,
    Types.CreateAvailableSubscriptionMutationVariables
  >(CreateAvailableSubscriptionDocument, options);
}
export type CreateAvailableSubscriptionMutationHookResult = ReturnType<
  typeof useCreateAvailableSubscriptionMutation
>;
export type CreateAvailableSubscriptionMutationResult =
  Apollo.MutationResult<Types.CreateAvailableSubscriptionMutation>;
export type CreateAvailableSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateAvailableSubscriptionMutation,
  Types.CreateAvailableSubscriptionMutationVariables
>;
export const GetMarketSubscriptionsDocument = gql`
  query getMarketSubscriptions($input: GetMarketSubscriptionsInput!) {
    getMarketSubscriptions(input: $input) {
      ...MarketSubscriptionFragment
    }
  }
  ${MarketSubscriptionFragmentFragmentDoc}
`;

/**
 * __useGetMarketSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetMarketSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketSubscriptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMarketSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetMarketSubscriptionsQuery,
    Types.GetMarketSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetMarketSubscriptionsQuery,
    Types.GetMarketSubscriptionsQueryVariables
  >(GetMarketSubscriptionsDocument, options);
}
export function useGetMarketSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetMarketSubscriptionsQuery,
    Types.GetMarketSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetMarketSubscriptionsQuery,
    Types.GetMarketSubscriptionsQueryVariables
  >(GetMarketSubscriptionsDocument, options);
}
export type GetMarketSubscriptionsQueryHookResult = ReturnType<
  typeof useGetMarketSubscriptionsQuery
>;
export type GetMarketSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetMarketSubscriptionsLazyQuery
>;
export type GetMarketSubscriptionsQueryResult = Apollo.QueryResult<
  Types.GetMarketSubscriptionsQuery,
  Types.GetMarketSubscriptionsQueryVariables
>;
export const CreateMarketSubscriptionDocument = gql`
  mutation createMarketSubscription($input: CreateMarketSubscription!) {
    createMarketSubscription(input: $input) {
      ...MarketSubscriptionFragment
    }
  }
  ${MarketSubscriptionFragmentFragmentDoc}
`;
export type CreateMarketSubscriptionMutationFn = Apollo.MutationFunction<
  Types.CreateMarketSubscriptionMutation,
  Types.CreateMarketSubscriptionMutationVariables
>;

/**
 * __useCreateMarketSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateMarketSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketSubscriptionMutation, { data, loading, error }] = useCreateMarketSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateMarketSubscriptionMutation,
    Types.CreateMarketSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateMarketSubscriptionMutation,
    Types.CreateMarketSubscriptionMutationVariables
  >(CreateMarketSubscriptionDocument, options);
}
export type CreateMarketSubscriptionMutationHookResult = ReturnType<
  typeof useCreateMarketSubscriptionMutation
>;
export type CreateMarketSubscriptionMutationResult =
  Apollo.MutationResult<Types.CreateMarketSubscriptionMutation>;
export type CreateMarketSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateMarketSubscriptionMutation,
  Types.CreateMarketSubscriptionMutationVariables
>;
export const ToolsDocument = gql`
  query tools {
    tools {
      ...ToolFragment
    }
  }
  ${ToolFragmentFragmentDoc}
`;

/**
 * __useToolsQuery__
 *
 * To run a query within a React component, call `useToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useToolsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ToolsQuery, Types.ToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ToolsQuery, Types.ToolsQueryVariables>(ToolsDocument, options);
}
export function useToolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ToolsQuery, Types.ToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ToolsQuery, Types.ToolsQueryVariables>(ToolsDocument, options);
}
export type ToolsQueryHookResult = ReturnType<typeof useToolsQuery>;
export type ToolsLazyQueryHookResult = ReturnType<typeof useToolsLazyQuery>;
export type ToolsQueryResult = Apollo.QueryResult<Types.ToolsQuery, Types.ToolsQueryVariables>;
export const WhoamiDocument = gql`
  query whoami {
    whoami {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useWhoamiQuery__
 *
 * To run a query within a React component, call `useWhoamiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoamiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoamiQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoamiQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.WhoamiQuery, Types.WhoamiQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.WhoamiQuery, Types.WhoamiQueryVariables>(WhoamiDocument, options);
}
export function useWhoamiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.WhoamiQuery, Types.WhoamiQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.WhoamiQuery, Types.WhoamiQueryVariables>(
    WhoamiDocument,
    options
  );
}
export type WhoamiQueryHookResult = ReturnType<typeof useWhoamiQuery>;
export type WhoamiLazyQueryHookResult = ReturnType<typeof useWhoamiLazyQuery>;
export type WhoamiQueryResult = Apollo.QueryResult<Types.WhoamiQuery, Types.WhoamiQueryVariables>;
