import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Market } from '../../apollo/autogenerate/schemas';
import { getCachedMarket, setCachedMarket } from './cachedMarket';

export interface MarketReducerState {
  currentMarket?: Market;
}

const cachedMarket = getCachedMarket();

const initialState: MarketReducerState = {
  currentMarket: cachedMarket,
};

export const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setMarket: (state, action: PayloadAction<Market>) => {
      const market = action.payload;
      setCachedMarket(market);
      state.currentMarket = market;
    },
    resetMarket: (state) => {
      setCachedMarket(undefined);
      state.currentMarket = undefined;
    },
  },
});

export const { setMarket, resetMarket } = marketSlice.actions;
export const marketReducer = marketSlice.reducer;
