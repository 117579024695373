import { ApolloLink } from '@apollo/client';
import { getCachedMarket } from '../../../redux/market/cachedMarket';

export const marketIdLink = new ApolloLink((operation, forward) => {
  const market = getCachedMarket();
  if (market?.id) {
    operation.setContext(({ headers }: { headers: Headers }) => ({
      headers: {
        ...headers,
        'market-id': market.id,
      },
    }));
  }
  return forward(operation);
});
