import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField, RHFCheckbox, FormProvider } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useLocales from '../../../hooks/useLocales';
import { formatPhone } from '../../../utils/formatPhone';

// ----------------------------------------------------------------------

type FormValuesProps = {
  phone: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();
  const { t } = useLocales();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const phoneRegExp = new RegExp(/[^\d+]/);

  const LoginSchema = Yup.object().shape({
    phone: Yup.string()
      .required(t('dashboard.errors.phone.required'))
      .test('valid', t('dashboard.errors.phone.valid'), (value) =>
        value ? !phoneRegExp.test(value) : false
      )
      .test('length', t('dashboard.errors.phone.valid'), (value) =>
        value ? (value[0] === '+' ? value.length === 12 : value.length === 11) : false
      )
      .phone('RU', true, t('dashboard.errors.phone.valid')),
    password: Yup.string().required(t('dashboard.errors.password')),
  });

  const defaultValues = {
    phone: '+7',
    password: '',
    remember: true,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(formatPhone(data.phone), data.password);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="phone" label={t('general.phone')} />

        <RHFTextField
          name="password"
          label={t('general.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label={t('auth.login.remember')} />
        {/*<Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          {t('auth.login.forgot')}
        </Link>*/}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {t('auth.login.login')}
      </LoadingButton>
    </FormProvider>
  );
}
