import { noCase } from 'change-case';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  useGetNotificationsQuery,
  useReadAllNotificationsMutation,
  useReadNotificationMutation,
} from '../apollo/autogenerate/hooks';
import useLocales, { LangFunc } from '../hooks/useLocales';
import { NotificationType, NotificationUi } from '../apollo/autogenerate/schemas';
import createAvatar from '../utils/createAvatar';
import { IconButtonAnimate } from './animate';
import Iconify from './Iconify';
import MenuPopover from './MenuPopover';
import Scrollbar from './Scrollbar';
import { fToNow } from '../utils/formatTime';
import { PATH_DASHBOARD } from '../routes/paths';
import { ru } from 'date-fns/locale';
import { GetNotificationsQuery } from '../apollo/autogenerate/operations';

// ----------------------------------------------------------------------

const refetchInterval = Number(process.env.REACT_APP_NOTIFICATIONS_REFETCH_INTERVAL) ?? 30000;

export const Notifications: React.FC = () => {
  const { data, loading, refetch } = useGetNotificationsQuery({
    fetchPolicy: 'network-only',
    variables: { input: { take: 50 } },
  });

  const totalUnRead =
    data?.getNotifications?.notifications.filter((item) => !item.isRead).length ?? 0;

  useEffect(() => {
    const interval = setInterval(refetch, refetchInterval);
    return () => clearInterval(interval);
  }, [refetch]);

  const [handleMarkAllAsRead] = useReadAllNotificationsMutation({
    onCompleted: () => refetchData(),
  });

  const markAllAsRead = useCallback(() => {
    handleMarkAllAsRead();
  }, [handleMarkAllAsRead]);

  const [open, setOpen] = useState(false);

  const iconButtonRef = useRef<HTMLButtonElement>(null);

  const refetchData = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    markAllAsRead();
  };

  if (loading || !data) return <></>;
  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        ref={iconButtonRef}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>
      {open && (
        <NotificationsPopover
          data={data}
          refetchData={refetchData}
          handleClose={handleClose}
          iconButtonRef={iconButtonRef}
          totalUnRead={totalUnRead}
          markAllAsRead={markAllAsRead}
        />
      )}
    </>
  );
};

// ----------------------------------------------------------------------

interface NotificationsPopoverProps {
  data: GetNotificationsQuery;
  refetchData: VoidFunction;
  handleClose: VoidFunction;
  iconButtonRef: React.RefObject<HTMLButtonElement>;
  totalUnRead: number;
  markAllAsRead: VoidFunction;
}

const NotificationsPopover: FC<NotificationsPopoverProps> = ({
  data,
  refetchData,
  handleClose,
  iconButtonRef,
  totalUnRead,
  markAllAsRead,
}) => {
  const { t } = useLocales();

  const [readOne] = useReadNotificationMutation({ onCompleted: () => refetchData() });
  const handleRead = useCallback(
    (id: string) => readOne({ variables: { notificationId: id } }),
    [readOne]
  );

  const newNotifications = data.getNotifications.notifications.filter((n) => !n.isRead);
  const notificationHistory = data.getNotifications.notifications.filter((n) => n.isRead);

  return (
    <MenuPopover
      open={true}
      anchorEl={iconButtonRef.current}
      onClose={handleClose}
      sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">{t('dashboard.notifications.notifications')}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('dashboard.notifications.newMsg')} {totalUnRead}
          </Typography>
        </Box>

        {totalUnRead > 0 && (
          <Tooltip title={t('dashboard.notifications.markAllAsRead')}>
            <IconButton color="primary" onClick={() => markAllAsRead()}>
              <Iconify icon="eva:done-all-fill" width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar sx={{ height: 446 }}>
        {totalUnRead > 0 && (
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {t('dashboard.notifications.newLabel')}
              </ListSubheader>
            }
          >
            {newNotifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleClose={handleClose}
                handleRead={handleRead}
              />
            ))}
          </List>
        )}

        <List
          disablePadding
          subheader={
            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
              {t('dashboard.notifications.beforeLabel')}
            </ListSubheader>
          }
        >
          {notificationHistory.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              handleClose={handleClose}
              handleRead={handleRead}
            />
          ))}
        </List>
      </Scrollbar>
    </MenuPopover>
  );
};

function NotificationItem({
  notification,
  handleClose,
  handleRead,
}: {
  notification: NotificationUi;
  handleClose: VoidFunction;
  handleRead: (notificationId: string) => void;
}) {
  const { t } = useLocales();
  const { avatar, title } = renderContent(notification, t);
  const nav = useNavigate();

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.isRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => {
        handleRead(notification.id);
        nav(PATH_DASHBOARD.tools.wb.campaign(JSON.parse(notification.params).advertCampaignId));
        handleClose();
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt, ru)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------
const notificationText = (notification: NotificationUi, t: LangFunc) => {
  const data = JSON.parse(notification.params);
  const market = notification.market?.name ?? '';
  if (
    notification.notificationType === NotificationType.AutoDepositSuccessful ||
    notification.notificationType === NotificationType.BidsUpdateSuccessful
  ) {
    return [
      t(`dashboard.notifications.notificationType.${notification.notificationType}`),
      `id ${data.advertWBId}`,
      market,
    ];
  } else if (
    notification.notificationType === NotificationType.AutoDepositFailed ||
    notification.notificationType === NotificationType.BidsUpdateFailed
  ) {
    return [
      t(`dashboard.notifications.notificationType.${notification.notificationType}`),
      `id ${data.advertWBId}`,
      market,
    ];
  } else return ['general info', `${notification.notificationType}`, market];
};

function renderContent(notification: NotificationUi, t: LangFunc) {
  const avatar = notification.notificationType;
  const [head, info, market] = notificationText(notification, t);
  const title = (
    <Typography variant="subtitle2">
      {head}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(info)}
      </Typography>
      <Typography variant="body2">{market}</Typography>
    </Typography>
  );
  return {
    avatar: (
      <Avatar alt={avatar} color={createAvatar(avatar).color}>
        {createAvatar(avatar).name}
      </Avatar>
    ),
    title,
  };
}
