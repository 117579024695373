import { ApolloClient, InMemoryCache, NormalizedCacheObject, ApolloLink } from '@apollo/client';
import { apiLink } from './links/apiLink';
import { authLink } from './links/authLink';
import { errorLink } from './links/errorLink';
import { marketIdLink } from './links/marketIdLink';

export const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  defaultOptions: { query: { fetchPolicy: 'no-cache' } },
  link: ApolloLink.from([errorLink, authLink, marketIdLink, apiLink]),
  cache: new InMemoryCache(),
  resolvers: {},
});
