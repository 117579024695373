// @mui
import { List, Box } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
//
import { NavSectionProps } from '../type';
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import RoleBasedGuard from '../../../guards/RoleBasedGuard';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  const { translate } = useLocales();

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <RoleBasedGuard key={group.subheader} roles={group.roles}>
          <List disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {translate('dashboard.menu.' + group.subheader)}
            </ListSubheaderStyle>

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChildren={!!list.children}
                isCollapse={isCollapse}
              />
            ))}
          </List>
        </RoleBasedGuard>
      ))}
    </Box>
  );
}
