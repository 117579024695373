import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useLocales, { LangFunc } from '../../../hooks/useLocales';
import { Theme } from '@mui/system';
import useResponsive from '../../../hooks/useResponsive';
import { useAppDispatch } from '../../../redux';
import { marketChanged } from '../../../redux/events/eventsSlice';
import { resetMarket } from '../../../redux/market/marketSlice';

// ----------------------------------------------------------------------

const MENU_OPTIONS = (t: LangFunc) => [
  {
    label: t('dashboard.menu.home'),
    linkTo: () => '/',
  },
  // {
  //   label: t('dashboard.menu.edit'),
  //   linkTo: (id?: string) => (id ? PATH_DASHBOARD..edit(id) : PATH_DASHBOARD.general.app),
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { t } = useLocales();
  const { user, logout } = useAuth();
  const dispatch = useAppDispatch();

  const isMountedRef = useIsMountedRef();
  const isMobile = useResponsive('down', 'sm');

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();

      dispatch(resetMarket());
      dispatch(marketChanged());

      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const openedColor = (theme: Theme) => alpha(theme.palette.grey[900], 0.8);

  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        onClick={handleOpen}
        sx={{ cursor: 'pointer' }}
      >
        <IconButtonAnimate
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: openedColor,
              },
            }),
          }}
        >
          <MyAvatar />
        </IconButtonAnimate>
        {!isMobile && (
          <Typography component="span" sx={{ color: (theme) => theme.palette.text.primary }}>
            {user?.firstName}
          </Typography>
        )}
      </Stack>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.phoneNumber}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS(t).map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo()}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {t('dashboard.menu.logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
