import { createSlice } from '@reduxjs/toolkit';

export interface EventsReducerState {
  onMarketChanged: number;
  onUserChanged: number;
  onActiveSubscriptionChanged: number;
}

const initialState: EventsReducerState = {
  onMarketChanged: 0,
  onUserChanged: 0,
  onActiveSubscriptionChanged: 0,
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    marketChanged: (state) => {
      state.onMarketChanged += 1;
    },
    activeSubscriptionChanged: (state) => {
      state.onActiveSubscriptionChanged += 1;
    },
    userChanged: (state) => {
      state.onUserChanged += 1;
    },
  },
});

export const { marketChanged, activeSubscriptionChanged, userChanged } = eventsSlice.actions;
export const eventsReducer = eventsSlice.reducer;
