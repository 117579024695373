import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { advertCampaignsSyncIdReducer } from './advertCampaignsSyncId/advertCampaignsSyncIdSlice';
import { eventsReducer } from './events/eventsSlice';
import { marketReducer } from './market/marketSlice';
import { nicheAnalysisFiltersReducer } from './nicheAnalysisFilters/nicheAnalysisFiltersSlice';

export const store = configureStore({
  reducer: {
    market: marketReducer,
    events: eventsReducer,
    nicheAnalysisFilters: nicheAnalysisFiltersReducer,
    advertCampaignsSyncId: advertCampaignsSyncIdReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
