// @mui
import { List, Stack } from '@mui/material';
import { StyledSubheader } from './styles';
import NavList from './NavList';
import useLocales from '../../../hooks/useLocales';
import { NavSectionProps } from '../type';

// ----------------------------------------------------------------------

export default function NavSectionHorizontal({ navConfig, sx, ...other }: NavSectionProps) {
  const { translate } = useLocales();

  return (
    <Stack sx={sx} {...other}>
      {navConfig.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{translate(group.subheader)}</StyledSubheader>
            )}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
