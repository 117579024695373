// @mui
import { Button, Stack } from '@mui/material';
import useLocales from '../../hooks/useLocales';
import { PATH_AUTH } from '../../routes/paths';
//
import { MenuProps } from './type';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function MenuMobile({ user, isOffset, isHome }: MenuProps) {
  const { t } = useLocales();

  if (user) {
    return (
      <Button variant="contained" rel="noopener" href={PATH_AUTH.login}>
        {t('landing.menu.privateOffice')}
      </Button>
    );
  } else {
    return (
      <Stack spacing={1} direction="row">
        <Button variant="soft" rel="noopener" href={PATH_AUTH.login}>
          {t('landing.menu.login')}
        </Button>
        <Button variant="contained" rel="noopener" href={PATH_AUTH.register}>
          {t('landing.main.bk.try')}
        </Button>
      </Stack>
    );
  }
}

// ----------------------------------------------------------------------
