import { useEffect } from 'react';
import { useAppSelector } from '../redux';
import useAuth from './useAuth';

export const useRefreshOnUserChange = () => {
  const c = useAppSelector((s) => s.events.onUserChanged);
  const { refresh, isAuthenticated } = useAuth();
  useEffect(() => {
    if (c > 0 && isAuthenticated) refresh();
  }, [c, isAuthenticated, refresh]);
};
