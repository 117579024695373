// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { User_Role } from '../../../apollo/autogenerate/schemas';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name: string, iconify?: boolean) => {
  if (iconify) {
    return <Iconify icon={name} />;
  }
  return <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
};

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  feedbacks: getIcon('codicon:feedback', true),
  requests: getIcon('bi:search-heart', true),
  bids: getIcon('dashicons:money-alt', true),
  deposit: getIcon('material-symbols:account-balance-wallet-outline', true),
  market: getIcon('healthicons:market-stall-outline', true),
  tools: getIcon('codicon:tools', true),
  advertising: getIcon('mdi:star-circle', true),
  nicheAnalysis: getIcon('icon-park-outline:market-analysis', true),
  nicheAnalysisDeep: getIcon('icon-park-solid:analysis', true),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'markets',
    roles: [User_Role.Customer],
    items: [
      {
        title: 'marketsList',
        path: PATH_DASHBOARD.market.markets,
        icon: ICONS.market,
        roles: [User_Role.Customer],
      },
    ],
  },

  {
    subheader: 'deposits',
    roles: [User_Role.Customer],
    items: [
      {
        title: 'createDeposit',
        path: PATH_DASHBOARD.deposit.deposit,
        icon: ICONS.deposit,
        roles: [User_Role.Customer],
      },
    ],
  },

  {
    subheader: 'toolsWB',
    roles: [User_Role.Customer],
    items: [
      {
        title: 'nicheAnalysis',
        path: PATH_DASHBOARD.tools.wb.nicheAnalysis,
        icon: ICONS.nicheAnalysis,
        roles: [User_Role.Customer],
      },
      {
        title: 'nicheAnalysisDeep',
        path: PATH_DASHBOARD.tools.wb.nicheAnalysisDeep,
        icon: ICONS.nicheAnalysisDeep,
        roles: [User_Role.Customer],
      },
      {
        title: 'wbFeedbacks',
        path: PATH_DASHBOARD.tools.wb.feedbacksMain,
        icon: ICONS.feedbacks,
        roles: [User_Role.Customer],
      },
      {
        title: 'feedbacksParser',
        path: PATH_DASHBOARD.tools.wb.feedbacksParser,
        icon: ICONS.chat,
        roles: [User_Role.Customer],
      },
      {
        title: 'advertising',
        path: '#',
        icon: ICONS.advertising,
        roles: [User_Role.Customer],
        children: [
          { title: 'wbBidder', path: PATH_DASHBOARD.tools.wb.bidder },
          { title: 'wbPopularRequests', path: PATH_DASHBOARD.tools.wb.popularRequests },
          { title: 'autobidder', path: PATH_DASHBOARD.tools.wb.campaigns },
        ],
      },
      {
        title: 'unitEconomy',
        path: PATH_DASHBOARD.tools.wb.unitEconomy,
        icon: ICONS.banking,
      },
    ],
  },
  // SUPER_ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'superAdmin',
    roles: [User_Role.Superadmin],
    items: [
      // Admins
      {
        title: 'tools',
        path: PATH_DASHBOARD.superAdmin.tools,
        icon: ICONS.tools,
      },
    ],
  },
];

export default navConfig;
