import { useState } from 'react';
import { useInterval } from 'usehooks-ts';
import useLocales, { LangFunc } from '../../hooks/useLocales';

const getLoadingText = (index: number, t: LangFunc) =>
  t(`components.loadingScreen.loadingText${index}`);

export const useLoadingText = () => {
  const [index, setIndex] = useState<number>(1);

  useInterval(() => {
    setIndex((prev) => (prev === 10 ? 1 : prev + 1));
  }, 2000);

  const { t } = useLocales();

  return getLoadingText(index, t);
};
