import { sub } from 'date-fns';
import { NicheAnalysisFiltersState } from './types';

export const UNDEFINED_FILTER = {
  from: undefined,
  to: undefined,
};

export const initialState: NicheAnalysisFiltersState = {
  period: {
    from: sub(new Date(), { months: 1 }),
    to: new Date(),
  },
  filters: {
    meta: {
      id: '',
      marketId: '',
      name: '',
    },
    values: {
      revenueFromAllProduct: { ...UNDEFINED_FILTER },
      numberOfProducts: { ...UNDEFINED_FILTER },
      numberOfSellers: { ...UNDEFINED_FILTER },
      productSaleProbability: { ...UNDEFINED_FILTER },
      sellerWithSalesProbability: { ...UNDEFINED_FILTER },
      numberOfProductsWithRevenueGreaterThanOneMillion: { ...UNDEFINED_FILTER },
      purchaseProductsBudget: { ...UNDEFINED_FILTER },
      selfPurchaseProductBudget: { ...UNDEFINED_FILTER },
      averageRevenuePerOneProductWithSales: { ...UNDEFINED_FILTER },
      averageCommentsAmount: { ...UNDEFINED_FILTER },
      averageMedianCheck: { ...UNDEFINED_FILTER },
      top3SellerAverageRevenue: { ...UNDEFINED_FILTER },
      top10SellerAverageRevenue: { ...UNDEFINED_FILTER },
      top30SellerAverageRevenue: { ...UNDEFINED_FILTER },
      top3SellerAverageComments: { ...UNDEFINED_FILTER },
      top10SellerAverageComments: { ...UNDEFINED_FILTER },
      top30SellerAverageComments: { ...UNDEFINED_FILTER },
    },
  },
  chosenCategories: [],
};
